@mixin overflowScrolling($dir: y) {
  -webkit-overflow-scrolling: touch;

  @if $dir == y {
    overflow-x: hidden;
    overflow-y: auto;
  }

  @if $dir == x {
    overflow-x: auto;
    overflow-y: hidden;
  }

  @if $dir == xy {
    overflow: auto;
  }

  @if $dir == yx {
    overflow: auto;
  }
}
