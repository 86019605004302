.mat-datepicker-toggle-default-icon {
  display: none;
}

.input-wrap > {
  .mat-datepicker-content .mat-calendar-next-button,
  .mat-datepicker-content .mat-calendar-previous-button,
  .mat-datepicker-toggle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
}

.mat-icon-button {
  &.cdk-program-focused {
    background: transparent;

    .mat-button-focus-overlay {
      display: none;
    }
  }
}
