cbr-svg {
  &[bg] {
    top: 0;
  }

  svg {
    display: inline-block;
  }

  .svg-wrap {
    background: $c-grey-light;
    border-radius: 100%;
    padding: 8px;
    display: inline-block;
    box-sizing: content-box;

    &.bg-primary,
    &.bg-reach,
    &.bg-1 {
      background: $c-reach-bg;
    }

    &.bg-radius,
    &.bg-cost,
    &.bg-2 {
      background: $c-radius-bg;
    }

    &.bg-views,
    &.bg-3 {
      background: $c-views-bg;
    }

    &.bg-clicks,
    &.bg-4 {
      background: $c-clicks-bg;
    }

    &.bg-ctr,
    &.bg-5 {
      background: $c-ctr-bg;
    }
  }
}
