.examples {
  flex: 1 0;
  width: 100%;
}

.example-block {
  display: flex;
  margin-bottom: $gt;

  &.block-100 {
    width: 100%;
  }

  cbr-doc-tab {
    width: 100%;
    border-radius: 5px;
    background: #fff;

    &.fade {
      background: #f5f9fc;
    }

    .code {
      .example-wrap {
        width: auto;
        flex: auto;
        margin-bottom: $gt;
      }
    }
  }

  .example-wrap {
    display: flex;
    flex-direction: column;
    margin-right: $gt;

    &.row {
      flex-direction: row;
      margin: 0;

      > * {
        margin-right: $gt;
        margin-bottom: $gt;
      }
    }

    label {
      margin-bottom: 5px;
      color: $c-grey-dark;
    }
  }
}
