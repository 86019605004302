.configure-campaign-wrap {
  padding: 0 $gt;
  display: flex;
  justify-content: center;

  @include breakpoint(-milli) {
    padding: $gt 0 $gt5;
    flex-direction: column-reverse;
  }
}

.configure-campaign-main {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: $b-shadow;
  border-radius: $radius;
  width: 100%;
  max-width: 700px;
}

.configure-campaign-content {
  display: flex;
  flex-direction: column;
  padding: $gt2;
  width: 100%;

  @include breakpoint(-milli) {
    padding: $gt2 $gt $gt;
  }

  h2 {
    margin-bottom: $gt-h;
  }

  > p {
    max-width: 75%;
    color: $c-sub-title;

    @include breakpoint(-milli) {
      max-width: 100%;
    }
  }

  .field {
    width: 100%;
    margin-top: $gt-h;
  }

  .target-list {
    margin-top: $gt2;
  }

  &.center {
    align-items: center;

    h2 {
      text-align: center;
    }

    > p {
      text-align: center;
    }
  }

  &.columns {
    flex-direction: row;
  }
}

.configure-campaign-footer {
  border-top: 1px solid $c-border;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $gt;
  color: $c-grey-dark;
  background: #fff;
  border-radius: 0 0 $radius $radius;

  @include breakpoint(-milli) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
