cbr-datatable {
  &:after {
    content: '';
    flex: 0 0 $gt2;
  }

  table {
    min-width: calc(100vw - #{$gt4});
  }

  .fluid-table-content-wrap {
    @extend .paper;

    padding-bottom: 0;
  }

  cbr-datatable-row:last-child {
    cbr-datatable-cell {
      border-bottom: 0;
    }
  }

  cbr-datatable-cell.limited {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  cbr-datatable-header-cell {
    .th-text {
      display: flex;
      align-items: center;

      .triangle {
        margin-left: $gt-h;
      }
    }
  }
}
