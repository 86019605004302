%base-input {
  width: 100%;
  border-radius: $radius;
  border: 1px solid $c-input-border;
  color: #000;
  height: 40px;
  line-height: 20px;
  box-shadow: none;
  padding: 0 $gt-h;
  font-size: $f-size-p;
  transition: border-color 150ms ease;
  font-family: $f-family-primary;

  @include placeholder {
    color: $c-placeholder;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $c-input-border-hover;
  }

  &[readonly],
  &:disabled {
    opacity: 0.5;
  }

  &.icon {
    padding-right: $gt2;
  }
}

%base-phone {
  display: flex;
  border: 1px solid $c-input-border;
  border-radius: $radius;
  transition: border-color 150ms ease;

  input {
    border: 0;
  }

  cbr-phone-select {
    border-right: 1px solid $c-input-border;
    min-width: 55px;

    ng-select.ng-select {
      .ng-select-container {
        border: 0;
        background: transparent;
      }

      .ng-dropdown-panel {
        width: 350px;
        background: #fff;
        border: 1px solid $c-input-border;
        border-radius: $radius;
        overflow: hidden;
        top: calc(100% + 8px);
      }

      .ng-value {
        display: flex;
        align-items: center;
        justify-content: center;

        cbr-svg {
          display: block;
          position: static;
          height: 10px !important;
          margin-left: 5px;
        }
      }

      .ng-dropdown-panel-items {
        border-width: 1px 0 0 0;
        border-radius: 0;
      }

      .ng-clear-wrapper {
        text-align: center;
        display: inline-block;
        margin-left: 5px;
      }
    }
  }
}

%base-label {
  display: flex;
  border: 1px solid $c-input-border;
  border-radius: $radius;
  transition: border-color 150ms ease;

  &:focus-within {
    border-color: $c-input-border-hover;
  }

  input {
    border: 0;
  }

  &:before {
    content: attr(label);

    display: block;
    border-right: 0;
    border-radius: $radius 0 0 $radius;
    background: $c-grey-light;
    border-right: 1px solid $c-input-border;
    padding: 0 $gt;
    color: $c-grey-dark;
    line-height: 38px;
    white-space: nowrap;
  }
}

%base-label-right {
  display: flex;
  border: 1px solid $c-input-border;
  border-radius: $radius;
  transition: border-color 150ms ease;

  &:focus-within {
    border-color: $c-input-border-hover;
  }

  input {
    border: 0;
  }

  &:after {
    content: attr(label);

    display: block;
    border-right: 0;
    border-radius: $radius 0 0 $radius;
    background: $c-grey-light;
    border-right: 1px solid $c-input-border;
    padding: 0 $gt;
    color: $c-grey-dark;
    line-height: 38px;
    white-space: nowrap;
  }
}

%custom-label {
  display: flex;
  border: 1px solid $c-input-border;
  border-radius: $radius;
  transition: border-color 150ms ease;

  &:focus-within {
    border-color: $c-input-border-hover;
  }

  input {
    border: 0;
  }

  .label {
    display: block;
    border-right: 0;
    border-radius: $radius 0 0 $radius;
    background: $c-grey-light;
    border-right: 1px solid $c-input-border;
    padding: 0 $gt;
    color: $c-grey-dark;
    line-height: 38px;
    white-space: nowrap;
  }
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: $gt;
  color: $c-text-field;

  label {
    margin-bottom: $gt-h;
    font-weight: $f-weight-title;
    user-select: none;
    color: $c-grey-darker;
    position: relative;
    padding-right: $gt;

    .optional {
      margin-left: $gt-q;
    }
  }

  .input-counter {
    color: $c-grey-dark;
    position: absolute;
    font-weight: $f-weight;
    right: 0;
    bottom: 0;
    transition: color 200ms ease;

    &.is-invalid {
      color: $c-warning;
    }
  }

  .input-wrap {
    position: relative;
    flex: 1 0;
    display: flex;

    textarea,
    input {
      @include placeholder {
        color: $c-placeholder;
        opacity: 1;
      }
    }

    @for $i from 1 through 10 {
      &.z-index-#{$i}0 {
        z-index: #{$i}0;
      }
    }

    ng-select {
      width: 100%;
      position: relative;
    }

    cbr-svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      z-index: 1;
    }

    cbr-range-slider {
      width: 100%;

      .range.no-label {
        .range-custom-wrap {
          top: 11px;
        }

        .range-wrap {
          input {
            height: 20px;
            line-height: 20px;
          }
        }
      }
    }

    .ng-option {
      cbr-svg {
        position: static;
        transform: translate(0, 0);
      }
    }

    &[phone] {
      @extend %base-phone;
    }

    &[label] {
      @extend %base-label;
    }

    &.custom-label {
      @extend %custom-label;
    }

    &[label-right] {
      @extend %base-label-right;

      &:after {
        content: attr(label-right);
        border-radius: 0 $radius $radius 0;
        border: 0;
        border-left: 1px solid $c-input-border;
      }
    }

    &.is-loading {
      > cbr-svg {
        display: none;
      }

      &::after {
        content: '';
        animation: spinAround 0.5s infinite linear;
        border: 2px solid #dbdbdb;
        border-color: transparent transparent $c-primary $c-primary !important;
        border-radius: 290486px;
        display: block;
        height: 1em;
        width: 1em;

        position: absolute;
        right: 15px;
        top: calc(50% - (1em / 1.6));
      }
    }
  }

  .input-wrap--overflow-link {
    @extend .input-wrap;

    input {
      padding-right: 60px;
    }

    a {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      padding: 0 $gt;
    }
  }

  textarea {
    @include overflowScrolling;

    width: 100%;
    border-radius: $radius;
    border: 1px solid $c-input-border;
    color: #000;
    box-shadow: none;
    padding: $gt-h;
    font-size: $f-size-p;
    transition: border-color 150ms ease;
    font-family: $f-family-primary;
    line-height: 24px;
    resize: none;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $c-input-border-hover;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  input {
    @extend %base-input;

    &[type='checkbox'] {
      visibility: hidden;
      opacity: 0;
      width: 0;
      height: 0;
      padding: 0;
      margin: 0;
      position: fixed;
      top: -5000px;
    }
  }

  .input-wrap--submit {
    display: flex;
    align-items: center;

    cbr-button {
      margin-left: $gt;
    }
  }
}

.input-checkbox {
  display: flex;
  align-items: center;

  input {
    display: none;
  }

  .label-checkbox {
    font-weight: 400;
    flex: 1 0;

    @include breakpoint(-milli) {
      margin-right: $gt;
    }

    .header {
      font-weight: $f-weight-title;
    }

    .description {
      color: $c-grey-dark;

      @include breakpoint(-milli) {
        margin-top: $gt-h;
      }
    }
  }

  .checkbox-toggle {
    border-radius: $radius;
    position: relative;
    background: $c-grey-light;
    width: 42px;
    height: 24px;
    transition: background 200ms ease;
    cursor: pointer;

    &:after {
      $spacer: 2px;

      content: '';
      position: absolute;
      border-radius: $radius;
      left: 50%;
      transform: translateX(-90%);
      top: $spacer;
      height: calc(100% - #{$spacer * 2});
      width: 50%;
      background: #fff;
      display: block;
      transition: transform 200ms ease;
    }

    &.active {
      background: $c-secondary;

      &:after {
        transform: translateX(-10%);
      }
    }
  }
}

// Reset defaults
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

.form-wrap {
  max-width: 600px;

  fieldset {
    margin-bottom: $gt2;
  }
}

.cbr-input-errors,
cbr-input-errors {
  color: $c-alert-text;
  font-size: $f-size-6;
  margin: 5px 0;

  p {
    margin: 0;
  }
}

cbr-store-details {
  ng-select.ng-select {
    input {
      width: 100%;
      line-height: 24px;
      outline: none;
      padding: 5px;
      font-size: $f-size-p;
      border: none;
      border-bottom: 1px solid $c-input-border;
    }

    .ng-dropdown-panel {
      border: 1px solid $c-input-border;
      border-radius: $radius;
    }

    .ng-dropdown-panel-items {
      border: none;
    }
  }
}
