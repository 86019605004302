.goal-content {
  .goal-selection {
    button {
      &.type-content {
        padding: $gt2 $gt3;

        @include breakpoint(0 900) {
          padding: $gt2;
        }
      }
    }
  }
}
