.cbr-close {
  $delay: 200ms;

  &:after,
  &:before {
    content: '';
    width: 20px;
    height: 2px;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: red;
    border-radius: 20px;
    transform: rotate(-45deg);
    transition: transform 225ms ease $delay, width 275ms ease $delay;
  }

  &:before {
    transform: rotate(45deg);
  }
}
