.article-styles {
  color: $c-sub-title;

  li {
    font-weight: 700;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding-left: $gt2;
      margin-bottom: $gt;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 20px;
        top: 8px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: $c-primary;
      }
    }
  }
}
