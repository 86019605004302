/**
 *
 *	Cobiro variables
 *
 */

/**
 *	Fonts
 */
$f-family-primary: 'Soleil', sans-serif !default;

// Font-sizes
$f-size-1: 32px !default;
$f-size-2: 24px !default;
$f-size-3: 18px !default;
$f-size-4: 16px !default;
$f-size-5: 14px !default;
$f-size-6: 12px !default;
$f-size-p: 14px !default;
$f-size-tiny: 10px !default;

// Weights
$f-weight-light: 300;
$f-weight: 400;
$f-weight-title: 700;
$f-weight-subtitle: 600;

/**
  *	Colors
  */

// Base colors
$c-primary: #0087ef !default;
$c-primary-hover: lighten($c-primary, 10%) !default;

$c-primary-dark: #0d47a1 !default;
$c-primary-dark: lighten($c-primary-dark, 10%) !default;

$c-secondary: #27d07b !default;
$c-secondary-hover: lighten($c-secondary, 10%) !default;
$c-secondary-dark: darken($c-secondary, 20%);

$c-content-bg: #f4f9fc;
$c-warning: #ef0000;

// Entry colors
$c-reach: $c-primary;
$c-reach-bg: #e5f3fe;

// $c-clicks: #fb8c00;
// $c-clicks-bg: #fff3e5;

$c-clicks: #00c5d2;
$c-clicks-bg: #e5f9fa;

$c-ctr: #2cd803;
$c-ctr-bg: #e9fbe5;

$c-radius: #ef0000;
$c-radius-bg: #fee5e5;

$c-views: #9d3bff;
$c-views-bg: #f5ebff;

// Grey colors
$c-grey-darker: #333333 !default;
$c-grey-dark: #92a0af !default;
$c-grey: #cfd5db !default;
$c-grey-light: #e7eaed !default;
$c-grey-lighter: #fafbfb !default;
$c-grey-custom: #eaeef4 !default;
$c-grey-marketing: #e5e9ed !default;
$c-grey-cas: #fafafa !default;
$c-grey-border: #b5c4df !default;

// Input colors
$c-input-border: #cfd5db !default;
$c-input-border-hover: darken($c-input-border, 15%) !default;

// Text colors
$c-text-paragraph: $c-grey-dark !default;
$c-text-inactive: #020202 !default;
$c-text-field: #002d4f !default;
$c-text-subtitle: #002d4f !default;
$c-text: $c-text-paragraph !default;
$c-text-paragraph-dark: $c-grey-darker !default;
$c-sub-title: #4c6c83 !default;
$c-title: $c-grey-darker !default;
$c-placeholder: lighten($c-grey-darker, 30%) !default;

// Notification colors
$c-info-bg: #cce5ff !default;
$c-info-border: #b8daff !default;
$c-info-text: #004085 !default;

$c-success-bg: #d4edda !default;
$c-success-border: #c3e6cb !default;
$c-success-text: #155724 !default;

$c-warning-bg: #fff3cd !default;
$c-warning-border: #ffeeba !default;
$c-warning-text: #856404 !default;

$c-alert-bg: #f8d7da !default;
$c-alert-border: #f5c6cb !default;
$c-alert-text: #721c24 !default;

// Google colors
$c-google-title: #1a0dab;
$c-google-ad: #006621;
$c-google-text: #545454;
$c-google-price: #222222;

// Marketing plan colors
$c-pill-text: #1c3d72;
$c-pill-bg: #cce7fc;

// Disabled
$c-disabled: #e0e7ed !default;

// By color
$c-dark-blue-black: #00124f !default;

/**
  *	Border & Border-radius defaults
  */
$radius: 5px !default;
$radius2: 10px !default;
$c-border: #e5e9ed;
$c-border-focus: #b8c3ce;

/**
  *	Easing
  */
$easing: ease !default;

/**
  *	Gutter
  */
$gt: 20px !default;

// Lower than base gutter
$gt-h: $gt / 2 !default; // Half
$gt-q: $gt / 4 !default; // Quarter

// Higher than base gutter
$gt2: 2 * $gt !default;
$gt3: 3 * $gt !default;
$gt4: 4 * $gt !default;
$gt5: 5 * $gt !default;
$gt6: 6 * $gt !default;

/**
  *  Breakpoints
  */
$bp-giga: 2560px !default;
$bp-mega: 1920px !default;
$bp-kilo: 1366px !default;
$bp-centi: 1024px !default;
$bp-milli: 768px !default;
$bp-nano: 480px !default;
$bp-pico: 320px !default;

/*
 *  Shadow
 */
$b-shadow: 0 3px 20px rgba(#d0e6f4, 0.5);
$b-shadow-hover: 0 3px 25px darken(#e7f2f9, 5%);
