/**
  *	Import here all the components' styles.
  * This will be imported after the common styles
  */
@mixin arrow-buttons-theme() {

  cbr-arrow-buttons {
    min-width: 64px;

    .arrow-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .arrow-icon-container {
        border: 1px solid $c-primary;
        border-radius: 32px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.disabled {
          opacity: 0.6;
          cursor: default;
        }

        &:first-child {
          margin-right: $gt-h;
        }
      }
    }
  }
}

@mixin button-theme() {
  cbr-button {
    display: block;

    .button-label {
      @extend .ribbon !optional;
    }

    button {
      font-family: $f-family-primary;
      padding: $g $g2;
      background: $c-primary;
      color: #fff;
      border: 1px solid $c-primary;
      border-radius: 100px;
      font-weight: $f-weight-subtitle;
      font-size: $f-size-p;
      line-height: 17px;
      position: relative;
      user-select: none;
      transition: background 200ms ease, border-color 200ms ease;
      cursor: pointer;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background: $c-primary-light;
        border-color: $c-primary-light;
      }

      &[disabled] {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
      }

      &.stretch {
        width: 100%;
      }

      &.style-primary {
        .ribbon {
          background-color: white;
          color: $c-primary;
        }
      }

      &.style-secondary {
        background: white;
        border: 1px solid $c-grey-lighter;
        color: $c-primary;

        &:hover {
          background: rgba($c-primary, 0.1);
          border: 1px solid rgba($c-primary, 0.2);
        }

        &.is-loading:after {
          border-color: transparent transparent $c-grey-dark $c-grey-dark !important;
        }

        &.accent-error {
          border-color: $c-radius;
          color: $c-radius;

          &.is-loading:after {
            border-color: transparent transparent $c-radius $c-radius !important;
          }
        }
      }

      &.style-outline {
        background: transparent;
        border: 1px solid $c-primary;
        color: $c-primary;

        &.active {
          background: rgba($c-primary, 0.1);
        }

        &.is-loading:after {
          border-color: transparent transparent $c-primary $c-primary !important;
        }

        &.accent-error {
          border-color: $c-radius;
          color: $c-radius;

          &.is-loading:after {
            border-color: transparent transparent $c-radius $c-radius !important;
          }
        }
      }

      &.style-silent {
        background: $c-grey-light;
        border: 1px solid $c-grey-light;
        color: $c-text-dark;

        &.is-loading:after {
          border-color: transparent transparent $c-grey-dark $c-grey-dark !important;
        }
      }

      &.style-transparent {
        background: transparent;
        border: none;
        color: $c-text-dark;

        &:hover {
          color: $c-primary;
          background: rgba($c-primary, 0.1);
        }

        &.is-loading:after {
          border-color: transparent transparent $c-grey-dark $c-grey-dark !important;
        }

        &.accent-error {
          color: $c-radius;
        }
      }

      &.style-warning {
        background: $c-warning-bg;
        border: 1px solid $c-warning-text;
        color: $c-warning-text;

        &:hover {
          background: lighten($c-warning-bg, 1%);
          border: 1px solid lighten($c-warning-text, 1%);
        }

        &.is-loading:after {
          border-color: transparent transparent $c-warning-text $c-warning-text !important;
        }

        &.accent-error {
          border-color: $c-radius;
          color: $c-radius;

          &.is-loading:after {
            border-color: transparent transparent $c-radius $c-radius !important;
          }
        }
      }

      &.style-alert {
        background: $c-alert-bg;
        border: 1px solid $c-alert-text;
        color: $c-alert-text;

        &:hover {
          background: lighten($c-alert-bg, 1%);
          border: 1px solid lighten($c-alert-text, 1%);
        }

        &.is-loading:after {
          border-color: transparent transparent $c-alert-text $c-alert-text !important;
        }

        &.accent-error {
          border-color: $c-radius;
          color: $c-radius;

          &.is-loading:after {
            border-color: transparent transparent $c-radius $c-radius !important;
          }
        }
      }

      &.size-small {
        padding: 0 $gt;
        height: 32px;
        line-height: 30px; // has 1px borders aswell
        font-size: $f-size-6;

        &.is-loading::after {
          $size: 12px;
          $b-size: 2px;

          border: $b-size solid #dbdbdb;
          height: $size;
          width: $size;
          left: calc(50% - #{$size / 2 + $b-size});
          top: calc(50% - #{$size / 2 + $b-size});
        }
      }

      &.size-large {
        padding: 0 $gt;
        height: 80px;
        line-height: 80px;

        &.is-loading::after {
          $size: 20px;
          $b-size: 2px;

          border: $b-size solid #dbdbdb;
          height: $size;
          width: $size;
          left: calc(50% - #{$size / 2 + $b-size});
          top: calc(50% - #{$size / 2 + $b-size});
        }
      }

      &.size-mobile-large {
        @include breakpoint(-milli) {
          padding: $g2;
          width: 100%;
        }
      }

      &.type-icon {
        padding: 4px 4px;
        min-width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.type-icon-text {
        padding: 0 8px;
        min-width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-weight: 400;
        display: flex;
        align-items: center;

        .btn-content {
          display: grid;
          grid-gap: 5px;
          grid-template-columns: auto auto;
        }
      }

      &.type-squared {
        border-radius: 0 !important;
      }

      &.is-loading {
        color: transparent !important;
        pointer-events: none;

        > * {
          opacity: 0;
        }

        &::after {
          $size: 16px;
          $b-size: 2px;

          content: '';
          animation: spinAround 0.5s infinite linear;
          border: $b-size solid #dbdbdb;
          border-color: transparent transparent #fff #fff !important;
          border-radius: 100%;
          display: block;
          height: $size;
          width: $size;
          position: absolute;
          left: calc(50% - #{$size / 2 + $b-size});
          top: calc(50% - #{$size / 2 + $b-size});
        }
      }

      .btn-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@mixin chip-theme() {
  cbr-chip {
    .chip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      padding-left: $gt-h;
      border-radius: $radius;

      p {
        margin: 0;
        margin-right: $gt-h;
        line-height: $f-size-p;
      }
    }

    .chip-action {
      align-self: stretch;
      width: 24px;
      padding-right: $gt-h;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .item-is-loading {
        width: 11px;
        height: 11px;

        &:after {
          border-color: transparent transparent white white;
        }
      }
    }

    .chip-default {
      background-color: $c-primary;

      p {
        color: white;
      }
    }

    .chip-warn {
      background-color: $c-warning-bg;

      p {
        color: $c-warning-text;
      }
    }

    .chip-error {
      background-color: $c-alert-bg;

      p {
        color: $c-alert-text;
      }
    }

    .chip-success {
      background-color: $c-success-bg;

      p {
        color: $c-success-text;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin counter-theme() {
  cbr-counter {
    display: block;
    height: 80px;
    width: 100%;

    .title {
      font-weight: $f-weight-normal;
    }

    .total {
      display: flex;
      justify-content: flex-end;
      margin-bottom: $gt-h;
    }

    .slider {
      position: relative;
      height: 5px;
      width: 100%;
      div:not(.indicator) {
        position: relative;
        display: inline-block;
        height: 100%;
        float: left;
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          span {
            transform: translateX(0);
          }
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        span {
          position: absolute;
          left: 0;
          top: 15px;
          transform: translateX(-50%);
          opacity: 0.5;
          &.last {
            left: auto;
            right: 0;
            transform: translateX(0);
          }
        }
      }
      .low {
        background-color: #ff1536;
      }
      .medium {
        background-color: #fcdc2b;
      }
      .high {
        background-color: $c-secondary;
      }
      .indicator {
        background-color: $c-text-field;
        height: auto;
        width: 5px;
        position: absolute;
        border-radius: 100px;
        left: 0;
        top: -8px;
        bottom: -8px;
        z-index: 10;
        -webkit-transition: left 0.75s linear;
        -moz-transition: left 0.75s linear;
        transition: left 0.75s linear;
      }
    }
  }

}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin email-theme() {
  cbr-email {
    width: 100%;
    display: block;

    &.no-space {
      .field {
        margin-bottom: 0;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin calendar-theme() {
  cbr-calendar {
    width: 100%;

    .calendar-input {
      width: 100%;
      height: 44px;
      border-radius: $radius;
      border: 1px solid $c-grey;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $gt;
      position: relative;

      &.active,
      &:focus {
        border-color: $c-primary;
        outline: none;

        .calendar-dropdown {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 300ms;
        }
      }
    }

    .calendar-dropdown {
      display: flex;
      flex-direction: column;
      padding: $gt-q;
      background: #fff;
      box-shadow: $b-shadow;
      border-radius: $radius;
      position: absolute;
      top: calc(100% + #{$gt-h});
      left: 0;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s linear 300ms, opacity 300ms;
      user-select: none;
      z-index: 500;
    }

    .calendar-dropdown-section {
      display: flex;
    }

    .calendar-dropdown-month {
      display: flex;
      flex-direction: column;
      width: 220px;
      padding: $gt-q;
    }

    .calendar-dropdown-header {
      flex: 1 0;
      display: flex;
      align-items: center;
      padding: $gt-h 0;
      position: relative;
    }

    .calendar-dropdown-header-text {
      flex: 1 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .calendar-dropdown-arrow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 $gt-q;
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      left: 0;

      &.next {
        left: auto;
        right: 0;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .week-row {
      display: flex;
      width: 100%;
    }

    .date-cell,
    .weekday-cell {
      width: calc(100% / 7);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $gt-q 0;
    }

    .date-cell {
      $cellAnimationTime: 175ms;

      cursor: pointer;
      position: relative;
      color: $c-grey-dark;

      &:after {
        content: attr(date);
        position: relative;
        z-index: 10;
      }

      &:before {
        content: '';
        width: 30px;
        height: 30px;
        border-radius: 100%;
        position: absolute;
        background-color: transparent;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
      }

      &:hover {
        color: #fff;

        &:before {
          background-color: $c-primary-light;
        }
      }

      &.active {
        color: #fff;

        &:before {
          background-color: $c-primary;
          transition: background-color $cellAnimationTime ease,
            border-radius $cellAnimationTime ease;
          will-change: background-color, border-radius;
        }

        &:hover:before {
          background-color: $c-primary-light;
        }

        &.start-date:before {
          border-radius: 100% 0 0 100%;
        }

        &.end-date:before {
          border-radius: 0 100% 100% 0;
        }
      }

      &.before-today {
        cursor: initial;
        color: $c-grey-light;

        &:before {
          display: none;
        }
      }

      &.not-this-month {
        cursor: initial;
        opacity: 0;
      }

      &.between {
        &:after {
          color: #fff;
        }

        &:before {
          background-color: $c-primary;
          border-radius: 0;
        }

        &:hover:before {
          border-radius: 0;
          background-color: $c-primary-light;
        }
      }
    }

    .weekday-cell {
      color: $c-grey;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin checkbox-theme() {
  cbr-checkbox {
    .field {
      flex-direction: row;
      align-items: center;
      margin-bottom: $gt-h;

      label {
        margin: 0 0 0 $gt-h;
        font-weight: $f-weight;
      }
    }

    .checkbox {
      width: 16px;
      height: 16px;
      border: 1px solid $c-grey-light;
      border-radius: 2px;
      background-color: transparent;
      position: relative;

      transition: border-color 200ms ease, background-color 200ms ease;

      &.active {
        border-color: $c-primary;
        background-color: $c-primary;
      }
    }

    .checkmark {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 13px;
      height: 16px;
      margin-top: 2px;

      border: 0;
      fill: none;
      stroke: #fff;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 16px;
      stroke-dashoffset: 16px;
      transition: all 300ms ease;
      background: transparent;
      transition-delay: 100ms;
      transform: translate3d(-50%, -50%, 0) scale(0.8);
    }

    .checkbox.active .checkmark {
      stroke-dashoffset: 0;
    }

    .tick-icon {
      position: absolute;
      width: 0;
      height: 0;
      pointer-events: none;
      user-select: none;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin number-theme() {
  cbr-number {
    width: 100%;
  }

  .number-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .number-button {
      border-radius: $radius;
      border: $c-primary solid 1px;
      width: $gt2;
      height: $gt2;
      color: $c-primary;
      text-align: center;
      font-size: $f-size-1;
      font-weight: $f-weight;
      padding: 0;
      outline: none;
      background: none;
    }

    .plus {
      margin-left: $gt-q;
    }

    .minus {
      margin-right: $gt-q;
    }

    .path-plus,
    .path-minus {
      stroke: $c-primary;
      stroke-width: 2px;
    }

    .number-box {
      flex-grow: 100;
    }
  }

  .text-center {
    text-align: center;
  }
}

@mixin range-slider-theme() {
  cbr-range-slider {
    .range {
      position: relative;
    }

    .range-wrap {
      opacity: 0;
      position: relative;
      z-index: 10;
    }

    .range-custom-wrap {
      position: absolute;
      z-index: 0;
      top: 27px;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .track {
      height: 5px;
      width: 100%;
      border-radius: $radius;
      background: $c-grey-light;
      overflow: hidden;
      z-index: 1;
      position: absolute;
      top: 0;

      .fill {
        height: 100%;
        width: 0%;
        background: $c-primary;
      }
    }

    .thumb {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: #fff;
      border: 2px solid $c-primary;
      position: absolute;
      top: -8px;
      z-index: 5;
      transform: translateX(-50%);
    }

    .thumb-content {
      background: $c-primary;
      width: 32px;
      height: 32px;
      top: -14px;

      .thumb-content-text {
        color: #fff;
        font-size: $f-size-6;
        text-align: center;
        position: relative;
        font-weight: $f-weight-subtitle;
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .label {
      position: relative;
      bottom: 25px;
      font-weight: 600;
      font-size: $f-size-3;
      line-height: 18px;

      &.is-max {
        > span {
          transform: translateX(calc(-100% + 10px));
          text-align: right;
        }
      }

      > span {
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        display: block;
        width: 200px;
        text-align: center;
        pointer-events: none;
        user-select: none;
        font-weight: 800;
      }

      .label-unit {
        font-size: 16px;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin input-errors-theme() {
  cbr-input-errors {
    color: $c-error-text;
    font-size: $f-size-6;
    margin: $gt-q 0;

    span {
      margin: 0;
      display: flex;
      font-size: $f-size-6;

      cbr-svg {
        margin-right: $gt-q;
      }
    }

    .is-danger {
      color: $c-error-text;
    }
  }
}

@mixin input-theme() {
}

@mixin field-theme() {
  cbr-field {
    width: 100%;
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin toggle-theme() {
  cbr-toggle {
    .field {
      flex-direction: row;
      align-items: center;
      margin: 0;

      label {
        margin: 0 0 0 $g2;
        font-weight: $f-weight-light;
        color: $c-text-dark;
        font-size: $f-size-5;
      }
    }

    .control {
      border-radius: 12px;
      position: relative;
      background: $c-grey-light;
      width: 42px;
      height: 24px;
      transition: background 200ms ease;
      cursor: pointer;

      &:after {
        $spacer: 3px;

        content: '';
        position: absolute;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-95%);
        top: $spacer;
        width: 18px;
        height: 18px;
        background: #fff;
        display: block;
        transition: transform 200ms ease;
      }

      &.active {
        background: $c-secondary;

        &:after {
          transform: translateX(-5%);
        }
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin color-picker-theme() {
  cbr-color-picker {
    .color {
      width: $gt2;
      height: $gt2;
      border-radius: $radius;
      margin-right: $gt-h;
      border: 1px solid $c-input-border;

      input {
        opacity: 0;
      }
    }

    .color-text {
      flex: 1 0;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin url-theme() {
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin verification-number-theme() {
  cbr-verification-number {
    .verification-number-container {
      display: flex;
      justify-content: space-between;
    }

    .field {
      width: auto;
      margin: 0;
    }

    input.verification-number-input {
      text-align: center;
      font-size: $f-size-1;
      font-weight: $f-weight-subtitle;
      width: 60px;
      height: 80px;
      padding: 0;
    }
  }
}

@mixin loader-theme() {
  cbr-loader {
    width: 100%;

    .bar-loader {
      width: 100%;
      height: $gt-q;
      border-radius: $radius;
      position: relative;

      background-color: $c-grey;
    }

    .bar {
      width: 100%;
      height: $gt-q;
      border-radius: $radius;
      position: absolute;
    }

    .bar:nth-child(1) {
      background: linear-gradient(to right, $c-primary, $c-primary);
      background-repeat: repeat-y;
      animation: infiniteLoader 2s ease-in-out infinite;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin progress-bar-theme() {
  cbr-progress-bar {
    width: 100%;
    position: relative;

    .progress-bar-container {
      width: 100%;
      height: 7px;
      background-color: #ebebeb;
    }

    .progress-bar {
      position: relative;
      height: 100%;
      background-color: $c-primary;
    }

    .rounded {
      border-radius: $radius-2;
    }

    .progress-bar-icon {
      position: absolute;
      top: -7px;
      right: -7px;
      width: 20px;
      height: 20px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: $c-primary;
    }
  }
}

@mixin svg-theme() {
  cbr-svg {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .svg-bg {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bg-app {
      background: $c-grey-light;
      border-radius: $radius-2;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin dropdown-theme() {
  cbr-dropdown {
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 50000;

    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: #ddd;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin simple-budget-picker-theme() {
  .budget-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .budget-label {
      margin-bottom: $gt-h;
      font-weight: $f-weight-title;
      color: $c-text-field;
      width: 100%;
    }
  }

  .budget-buttons {
    @extend %grid-wrap;

    @include breakpoint(-milli) {
      width: auto;
    }

    > cbr-button {
      width: columns(2, $gt);
      margin-left: $gt;
      margin-bottom: $gt;

      ::ng-deep .btn-content {
        font-size: $f-size-2;
        font-weight: $f-weight-subtitle;
      }

      @include breakpoint(-milli) {
        width: columns(2, $gt);
        margin-left: $gt;
        margin-bottom: $gt;
      }
    }
  }

  .custom-budget {
    width: 100%;
    margin-top: $gt-h;

    @include breakpoint(-milli) {
      min-height: 100px;
    }

    .custom-budget-toggle {
      width: 100%;
      text-align: center;
      color: $c-primary;
      text-decoration: underline;
      transition: color 200ms linear, opacity 200ms linear;
      opacity: 1;
      cursor: pointer;

      @include breakpoint(-milli) {
        margin-top: $gt-h;
      }

      &:hover {
        color: $c-primary-lighter;
      }
      &.hidden {
        display: none;
      }
    }
    .hidden {
      pointer-events: none;
      user-select: none;
      display: none;
    }
  }
}

@mixin disclaimer-theme() {
  cbr-disclaimer {
    cbr-button {
      margin-left: $gt-h;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px $gt;
      margin-bottom: 0;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin sidebar-helper-theme() {
  cbr-sidebar-helper {
    .sidebar-helper {
      width: 340px;
      border-radius: $radius;
      box-shadow: 0 0 5px $c-shadow;
      background-color: white;
    }

    .sidebar-helper-header {
      display: flex;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
      padding: $gt;
      // This needs to be fixed because of the wave shape
      padding-bottom: 32px;
      position: relative;

      &.primary {
        background: transparent
          linear-gradient(
            180deg,
            $c-sidebar-helper-gradient-primary-dark 0%,
            $c-sidebar-helper-gradient-primary-light 100%
          )
          0% 0% no-repeat padding-box;
      }
    }

    .sidebar-helper-header-left {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .sidebar-helper-header-right {
      display: flex;
      flex-grow: 0;
      align-items: center;
      margin-left: $gt-h;
    }

    .sidebar-helper-header-title {
      margin: 0;
      font-size: $f-size-3;
      color: white;
      font-weight: 600;
    }

    .sidebar-helper-header-subtitle {
      margin: 0;
      font-size: $f-size-p;
      color: white;
      font-weight: 300;
    }

    .sidebar-helper-header-image {
      max-width: 56px;
      max-height: 56px;
    }

    .sidebar-helper-header-wave {
      position: absolute;
      bottom: -10px;
      left: 0;
    }

    .sidebar-helper-body {
      padding: $gt;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin nav-sidebar-theme() {
  cbr-nav-sidebar {
    $nav-sidebar-width: 92px;
    $transition-duration: 0.25s;

    .nav-sidebar {
      position: relative;
      width: $nav-sidebar-width;
      display: flex;
      flex-direction: column;
      background-color: white;
      margin-right: 2px;
      height: 100%;
      overflow: hidden;
      transition: width $transition-duration ease-in-out;

      &.expanded {
        width: 190px;
      }
    }

    .nav-sidebar-menu {
      background-color: white;

      .nav-sidebar-item {
        margin-top: $gt;
      }
    }

    .nav-sidebar-item {
      position: relative;
      padding: $gt 0 $gt $gt2 - $gt-q;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      margin-bottom: $gt + $gt-h;

      &:hover:not(.nav-sidebar-menu) {
        cbr-svg svg {
          transition: fill 0.3s ease-in-out;
          // Important is needed since the color of the svg is applied inline and that has highest priority
          fill: $c-primary !important;
        }

        .nav-sidebar-item-title {
          transition: color 0.3s ease-in-out;
          color: $c-primary;
        }
      }

      &.active {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          height: 100%;
          width: 2px;
          border-top-right-radius: $radius-2;
          border-bottom-right-radius: $radius-2;
          background-color: $c-primary;
        }
      }

      cbr-svg {
        height: 24px;
      }
    }

    .nav-sidebar-items {
      flex-grow: 1;
      overflow-y: auto;
    }

    .nav-sidebar-item-title {
      font-size: $f-size-5;
      font-weight: 600;
      color: $c-grey-secondary;
      margin: 0 0 0 16px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      opacity: 0;
      left: -16px;
      transition: opacity $transition-duration ease-in-out, left $transition-duration ease-in-out;

      &.expanded {
        opacity: 1;
        left: 0;
      }

      &.active {
        color: $c-primary;
      }
    }

    .nav-sidebar-footer {
      .nav-sidebar-item {
        margin-bottom: $gt;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin nav-topbar-theme() {
  cbr-nav-topbar {
    .nav-topbar {
      width: 100%;
      box-sizing: border-box;
      height: 72px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 $gt2;
      background-color: white;
    }

    .nav-topbar-left {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }

    .nav-topbar-right {
      display: flex;
      align-items: center;
      flex-grow: 0;
      position: relative;
      cursor: pointer;

      &:hover {
        .nav-topbar-dropdown {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .nav-topbar-dropdown {
      visibility: hidden;
      opacity: 0;
      transition: visibility 200ms linear, opacity 200ms linear;
      position: absolute;
      top: 100%;
      right: 0;
    }

    [slot='topbar-right-title'] {
      display: flex;
      color: $c-subtitle;
      font-size: $f-size-5;
      font-weight: $f-weight-subtitle;
      margin-right: $gt-h;
    }

    [slot='topbar-right-dropdown'] {
      position: absolute;
      top: calc(100% - #{$gt});
      right: 0;
      z-index: 99;
    }

    @include breakpoint(-milli) {
      .nav-topbar {
        padding: $g $g3;
        height: $g6;
      }

      .nav-topbar-right {
        position: absolute;
        right: $g3;
      }

      .nav-topbar-left {
        justify-content: center;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin nav-bottombar-theme() {
  .nav-bottombar {
    display: flex;
    flex-direction: row;
    background-color: white;
  }

  .nav-bottombar-item {
    padding: $g;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    cbr-svg {
      height: 20px;
      margin-bottom: $g-h;
    }

    .nav-bottombar-item-title {
      font-size: $f-size-6;
      font-weight: $f-weight-subtitle;
      color: $c-text-dark;
      opacity: 0.6;
      white-space: nowrap;
      overflow: hidden;

      &.active {
        color: $c-primary;
        opacity: 1;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin gauge-counter-theme() {

  .gauge-meter-container {
    position: relative;
  }

  .gauge-meter-icon {
    position: absolute;
    left: 0;
    bottom: 20px;
  }

  .gauge-pin-icon {
    position: absolute;
    bottom: 20px;
    left: 33%;
    transform-origin: 50% 90%;
    transition-property: all;
    transition-duration: 1s;
  }

  .gauge-meter-numbers {
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;

    p {
      margin: 0;
    }
  }

}

@mixin app-recommendation-list-item-theme() {
  cbr-app-recommendation-list-item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $c-grey-border;

    .app-recommendation-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: $g3 0;
    }

    .app-recommendation-item-content {
      flex: 1;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
        font-size: $f-size-4;
        font-weight: $f-weight-subtitle;
        color: $c-subtitle;
      }

      p, span {
        margin: 0;
        color: $c-text-dark;
        font-weight: $f-weight-light;
      }
    }

    .app-recommendation-list-item-controls {
      display: flex;
    }

    [slot="icon"] {
      margin-right: $g2;
    }

    cbr-button {
      margin-left: $g;
      width: 120px;
    }

    @include breakpoint(-milli) {
      cbr-button {
        width: $g8;
      }

      .app-recommendation-item-content {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0;
          font-size: $f-size-5;
          font-weight: $f-weight-subtitle;
          color: $c-subtitle;
        }
      }

      .app-recommendation-row {
        margin: $g4 0 $g3 0;
      }

      [slot="mobile-description"] {
        display: block;
        color: $c-text-dark;
        font-weight: $f-weight-light;
        margin: 0 0 $g4 0;
      }

      [slot="icon"] {
        margin-right: $g;
        width: $g4;
        height: $g4;
      }
    }
  }
}

@mixin app-card-theme() {
  cbr-app-card {
    .app-container {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      background: #fff;
      border-radius: $radius;
    }

    .app-info {
      padding: $gt4 $gt3;
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    [slot='icon'] {
      margin-bottom: $gt;
      width: 48px;
      height: 48px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: $f-size-1;
      font-weight: $f-weight-subtitle;
      color: $c-text-field;
      margin-top: 0;
      margin-bottom: $gt;
    }

    p {
      font-weight: $f-weight-light;
      color: $c-text-dark;
      font-size: $f-size-4;
      margin-top: 0;
      margin-bottom: $gt2;
    }

    .app-content {
      display: flex;
      flex: 0 1 490px;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;
      position: relative;

      img {
        @include image-cover;
      }
    }

    .app-button {
      width: 200px;
    }

    @include breakpoint(-milli) {
      .app-container {
        flex-direction: column-reverse;
      }

      .app-info {
        padding: $g4 $g3;
      }

      .app-content {
        border-radius: $radius $radius 0 0;
        flex: 0 1 100%;
        min-height: 160px;
      }

      .app-button {
        width: 100%;
      }

      [slot='icon'] {
        margin-bottom: $g2;
        width: 32px;
        height: 32px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: $f-size-2;
        margin-bottom: $g2;
      }

      p {
        font-size: $f-size-p;
        margin-bottom: $g4;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin onboarding-card-theme() {
  cbr-onboarding-card {
    background: $c-white;
    border-radius: $radius;
    display: flex;
    flex-direction: column;
    position: relative;

    .onboarding-card-info {
      padding: $gt3;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
    }

    .onboarding-card-footer {
      display: flex;
      flex-direction: column;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: $f-size-2;
      font-weight: $f-weight-subtitle;
      color: $c-text-field;
      text-align: center;
      margin-top: 0;
      margin-bottom: $gt;
    }

    p {
      font-weight: $f-weight-light;
      color: $c-text-dark;
      font-size: $f-size-5;
      text-align: center;
      margin-top: 0;
      margin-bottom: $gt;
    }

    img {
      object-fit: cover;
      max-height: 200px;
      border-top-left-radius: $radius;
      border-top-right-radius: $radius;
    }

    @include breakpoint(-milli) {
      img {
        max-height: initial;
        max-width: 100%;
      }

      .onboarding-card-info {
        padding: $gt;
      }
    }
  }
}

@mixin datatable-theme() {
  cbr-datatable {
    margin: 0;
    padding: 0 $gt2;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    overflow: auto;
    min-width: 0;
    min-height: 0;

    .fluid-table-content-wrap {
      @include overflowScrolling;
      min-width: max-content;
      padding-bottom: $gt2;
    }

    .fluid-table-content-wrap-inner {
      display: block;
    }

    table {
      margin: 0;
      min-width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      display: table;
    }

    th {
      position: sticky;
      top: 0;
      z-index: 10;
    }
  }
}

@mixin datatable-row-theme() {
  cbr-datatable-row {
    display: table-row;
  }
}

@mixin datatable-cell-theme() {
  cbr-datatable-cell {
    white-space: nowrap;
    padding: $gt-h $gt;
    display: table-cell;
    vertical-align: middle;
    border-bottom: 1px solid $c-grey-light;
  }
}

@mixin datatable-header-theme() {
  cbr-datatable-header {
    display: table-row-group;
  }
}

@mixin datatable-header-cell-theme() {
  cbr-datatable-header-cell {
    position: sticky;
    vertical-align: middle;
    white-space: nowrap;
    text-align: left;
    padding: 0;
    display: table-cell;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid $c-grey;
    background-color: $c-content-bg;

    .th-text {
      padding: $gt-h $gt;
      display: block;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin payment-card-theme() {
  @keyframes moveOutLeft {
    0% {
      max-height: 600px;
      opacity: 1;
    }
    99% {
      opacity: 0;
      transform: translateX(-508px);
    }
    100% {
      max-height: 0px;
    }
  }
  @keyframes moveOutRight {
    0% {
      max-height: 600px;
      opacity: 1;
    }
    99% {
      opacity: 0;
      transform: translateX(508px);
    }
    100% {
      max-height: 0px;
    }
  }

  .form-wrapper {
    border-radius: $radius;
    display: flex;
    background-color: #fff;
    width: 1020px;
    padding: $gt2;

    h2,
    h4 {
      font-weight: $f-weight-normal;
      color: $c-text-field;
      margin: 0;
    }

    p {
      font-weight: $f-weight-light;
      color: $c-text-dark;
      font-size: $f-size-5;
      margin: 0;
    }

    b {
      font-size: $f-size-4;
      font-weight: $f-weight-normal;
      color: $c-text-field;
    }

    .form-container {
      border-radius: $radius 0 0 $radius;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: $gt2;
      width: 590px;

      h2 {
        font-size: $f-size-2;
        margin-bottom: $gt;
      }

      p {
        font-size: $f-size-5;
      }

      .form-content-wrapper {
        display: flex;
        overflow: hidden;

        .billing-info-form {
          margin-bottom: $gt2;
          flex: 1 0 100%;
          overflow: hidden;
          transition: max-height 0.3s ease-out;
          height: auto;
          max-height: 600px;
        }

        .payment-form {
          margin-bottom: $gt2;
          opacity: 0;
          flex: 1 0 100%;
          transition: all 500ms;
          animation: moveOutRight 500ms;
        }
      }
      .next-page {
        height: auto;
        .billing-info-form {
          animation: moveOutLeft 500ms;
          opacity: 0;
          max-height: 0;
        }
        .payment-form {
          transform: translateX(-508px);
          opacity: 1;
        }
      }
    }
    .product-price-container {
      padding: $gt2 0;
      border-top: 1px solid $c-input-border;

      .product-price {
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: $gt-h;
        }
      }
    }

    .total {
      display: flex;
      justify-content: space-between;
      font-weight: $f-weight-subtitle;
    }

    .secure-info-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: $gt;

      img {
        width: 15px;
        height: 15px;
        margin-right: $gt-h;
      }

      p {
        font-size: $f-size-5;
      }
    }

    .form-info-container {
      flex: 0 1;
      border: 1px solid $c-input-border;
      border-radius: $radius;
      background-color: $c-grey-lightest;
      padding: $gt2;
      position: relative;

      .form-info {
        width: 310px;
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        .legal-info {
          margin-bottom: $gt2;
        }
      }

      .product-info-container {
        display: flex;
        padding-bottom: $gt;

        .logo-container {
          display: flex;
          width: 48px;
          height: 48px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          margin-right: $gt;

          img {
            width: 100%;
            border-radius: 8px;
          }
        }
        h4 {
          font-size: $f-size-3;
          font-weight: $f-weight-subtitle;
          margin: 0;
        }
      }
      .included-container {
        margin: $gt3 0 $gt 0;
        h4 {
          margin-bottom: $gt;
          font-weight: $f-weight-subtitle;
        }
        ul {
          padding: 0;
          li {
            display: flex;
            list-style: none;
            margin-bottom: $g;

            cbr-svg {
              margin-right: $gt-h;
            }
          }
        }
      }

      .partner-container {
        margin-top: $gt3;
        h4 {
          margin-bottom: $gt;
          font-weight: $f-weight-subtitle;
        }
        .partner-image-container {
          img {
            height: 40px;
            border: 1px solid #98aab8;
            margin-right: $gt-h;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .no-form {
    padding: 0;
    width: 392px;

    .form-info-container {
      background-color: #fff;

      .form-info {
        height: 500px;
      }
    }

    .product-price-container {
      padding-bottom: 0;
    }

    .partner-container {
      // This is for leaving some space for the position absolute button
      margin-bottom: 150px;
    }

    .payment-button {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      border-top: 1px solid $c-input-border;
      padding: $gt2;
    }
  }

  @include breakpoint(-milli) {
    .form-wrapper {
      width: 100%;
      padding: $g2;
      flex-direction: column-reverse;

      .form-container {
        width: 100%;
        padding: 0;
        margin-top: $g2;
      }

      .form-info-container {
        padding: $g2;

        .form-info {
          width: 100%;
        }
      }
    }

    .no-form {
      padding: 0;
      width: 100%;

      .form-info-container {
        .form-info {
          height: 100%;
        }

        .included-container {
          margin: $g6 0;
        }

        .product-price-container {
          margin-bottom: $g4;
        }

        .payment-button {
          position: inherit;
          width: 100%;
          padding: 0px;
          border-top: none;
        }
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin hero-theme() {
  cbr-hero {
    display: flex;
    padding: $gt2;
    background-color: white;
    align-items: center;

    [slot='thumbnail'] {
      max-width: 240px;
      height: 160px;

      &[small] {
        max-width: 48px;
        height: auto;
      }

      &[medium] {
        max-width: 80px;
        height: auto;
      }

      &[large] {
        max-width: 160px;
        height: auto;
      }
    }

    section {
      flex: 1 1;
      margin-left: $gt2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include overflow-ellipsis-parent;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: $f-size-1;
      font-weight: $f-weight-subtitle;
      color: $c-subtitle;
      margin: 0;
      @include overflow-ellipsis();
    }

    p {
      font-weight: $f-weight-light;
      max-width: 450px;
      line-height: $f-line-height-p;
      max-height: $f-line-height-p * 3;
      margin: $gt-q 0 0 0;
      color: $c-text-dark;
      @include multiline-ellipsis(2);
    }

    @include breakpoint(-milli) {
        padding: $g4 $g3;
        height: auto;

        [slot='thumbnail'] {
          width: auto;
          max-height: 60px;
          border-radius: $radius;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: $f-size-4;
        }

        p {
          margin-top: $g;
        }

        section {
          margin-left: $g2;
        }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin snackbar-item-theme() {
  .snackbar-item {
    border: 1px solid $c-info-border;
    background: $c-info-bg;
    color: $c-info-text;
    margin-bottom: $gt-h;
    border-radius: $radius;
    padding: $gt-h $gt2 $gt-h $gt-h;
    position: relative;

    .cbr-close {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 40px;
      padding: 5px;
      border: 0;
      background-color: rgba(#000, 0);
      transition: background-color 125ms ease;
      cursor: pointer;

      &:hover {
        background-color: rgba(#000, 0.15);
      }

      &:before,
      &:after {
        background-color: $c-info-text;
      }
    }

    &.is-error {
      border: 1px solid $c-alert-border;
      background: $c-alert-bg;
      color: $c-alert-text;

      .cbr-close {
        &:before,
        &:after {
          background: $c-alert-text;
        }
      }
    }

    &.is-warning {
      border: 1px solid $c-warning-border;
      background: $c-warning-bg;
      color: $c-warning-text;

      .cbr-close {
        &:before,
        &:after {
          background: $c-warning-text;
        }
      }
    }

    &.is-success {
      border: 1px solid $c-success-border;
      background: $c-success-bg;
      color: $c-success-text;

      .cbr-close {
        &:before,
        &:after {
          background: $c-success-text;
        }
      }
    }
  }

  .cbr-close {
    $delay: 200ms;

    &:after,
    &:before {
      content: '';
      width: 20px;
      height: 2px;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background: red;
      border-radius: 20px;
      transform: rotate(-45deg);
      transition: transform 225ms ease $delay, width 275ms ease $delay;
    }

    &:before {
      transform: rotate(45deg);
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin badge-theme() {
  cbr-badge {
    position: relative;
    display: block;
    height: 100%;
    width: fit-content;

    [slot="image"] {
      height: 100%;

      img {
        object-fit: cover;
        object-position: 0 0;
      }
    }

    [slot="badge"] {
      background: $c-primary;
      border: 2px solid white;
      padding: 7px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-content: center;
      cursor: pointer;
    }

    .icon-bottom-right {
      position: absolute;
      transform: translate(30%, 30%);
      right: 0;
      bottom: 0;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin user-dropdown-list-item-theme() {
  cbr-user-dropdown-list-item {
    display: flex;
    padding: $g2;
    background-color: #fff;
    width: 100%;
    flex-shrink: 0;
    transition: background-color 200ms ease;

    &.active {
      background-color: rgba($c-primary, 0.05);
    }

    &:hover {
      background-color: rgba($c-primary, 0.1);
      cursor: pointer;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $c-primary;
      }
    }

    [slot='thumbnail'] {
      height: $g5;
      object-fit: cover;
      flex: 0 0 60px;
      background-color: $c-grey-lighter;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .user-dropdown-list-item-content {
      margin-left: $g2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include overflow-ellipsis-parent
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: $f-size-5;
      line-height: 17px;
      font-weight: $f-weight-subtitle;
      color: $c-subtitle;
      margin: 0;
      transition: color 200ms ease;
      @include single-line-overflow-ellipsis;
    }

    p {
      font-size: $f-size-6;
      color: $c-text-dark;
      margin: 2px 0 0;
      line-height: 15px;
      @include single-line-overflow-ellipsis;
    }

    @include breakpoint(-milli) {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $c-subtitle;
        font-size: $f-size-5;
        font-weight: $f-weight-subtitle;
      }

      p {
        font-size: $f-size-6;
        color: $c-text-dark;
        font-weight: $f-weight-light;
      }
    }
  }

  @include breakpoint(-milli) {

  }
}

@mixin user-dropdown-theme() {
  cbr-user-dropdown {
    display: flex;
    flex-direction: column;
    width: 260px;
    border-radius: $radius;
    box-shadow: 0 3px 6px $c-shadow;
    position: relative;
    background-color: white;
    margin-top: 7px; // This is necessary for the arrow given by the :before

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7px 7px 7px;
      border-color: transparent transparent white transparent;
      position: absolute;
      top: -7px;
      right: 35px;
    }

    %base-dropdown-element {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      &:empty {
        display: none;
      }
    }

    .user-dropdown-header {
      @extend %base-dropdown-element;

      padding: $gt;
      border-bottom: 1px solid $c-grey-lighter;
    }

    .user-dropdown-section {
      @extend %base-dropdown-element;

      padding: 0;
      border-bottom: 1px solid $c-grey-lighter;
      max-height: 240px;
      overflow-y: auto;
    }


    .user-dropdown-footer {
      @extend %base-dropdown-element;
    }

    [slot='user-image'] {
      width: 48px;
      height: 48px;
      border-radius: 100%;
      margin-bottom: $gt-h;
    }

    [slot='user-name'] {
      color: $c-subtitle;
      font-size: $f-size-4;
      font-weight: $f-weight-subtitle;
      margin: 0;
      width: 100%;
      text-align: center;
      @include single-line-overflow-ellipsis;
    }

    [slot='user-email'] {
      margin: 0 0 $gt-h 0;
      color: $c-text-dark;
      font-size: $f-size-p;
      font-weight: $f-weight-light;
      text-align: center;
      width: 100%;
      @include single-line-overflow-ellipsis;
    }

    [slot='footer-cta'] {
      width: 100%;
    }

    [slot='close-button'] {
      position: absolute;
      width: $g4;
      height: $g4;
      right: $g2;
      top: $g2;
    }

    [slot='user-image'] {
      background-color: $c-primary;
      display: flex;
      justify-content: center;
      align-items: center;

      .profile-default {
        width: $g6;
        height: $g6;
        background-color: #fff;
      }

      p {
        margin: 0;
        font-size: $f-size-4;
        font-weight: $f-weight-title;
        color: white;
      }
    }

    @include breakpoint(-milli) {
      width: 100vw;
      max-height: 100vh;
      margin: 0;

      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;

      &:before {
        display: none;
      }

      .user-dropdown-header {
        padding: $g4 $g2;
      }

      [slot='user-image'] {
        margin-bottom: $g;
      }

      [slot='user-name'] {
        color: $c-subtitle;
        margin-bottom: $g;
      }

      [slot='user-email'] {
        color: $c-text-dark;
        font-weight: $f-weight-light;
        margin-bottom: $g;
      }

      [slot='footer-cta'] {
        margin-bottom: 0;
      }
    }
  }
}

@mixin nav-topbar-list-item-theme() {
  cbr-nav-topbar-list-item {
    display: flex;
    align-items: center;
    flex-grow: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    [slot='icon'] {
      width: 16px;
      height: 16px;
      margin-right: $gt-h;

      svg {
        fill: $c-grey-secondary;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
      color: $c-grey-secondary;
      font-size: $f-size-p;
      font-weight: $f-weight-subtitle;
      margin: 0;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin tab-navigation-theme() {
  cbr-tab-navigation {
    padding-left: $gt2;
    display: flex;
    flex-direction: row;

    .tab-navigation-item {
      position: relative;
      padding: 0 $gt;
      margin-right: $gt;
      cursor: pointer;
      outline: none;
      box-shadow: none;
      font-weight: $f-weight-subtitle;
      transition: background-color 200ms ease;

      p {
        user-select: none;
        margin-top: $gt-h;
        margin-bottom: $gt-h;
        font-size: $f-size-4;
        color: $c-text-dark;
        line-height: 20px;
        white-space: nowrap;
        transition: color 200ms ease;
      }

      &:after {
        transition: background-color 200ms ease;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: $g-h;
        border-top-right-radius: $radius-2;
        border-top-left-radius: $radius-2;
        background-color: rgba($c-primary, 0);
      }

      &:hover {
        &:after {
          background-color: rgba($c-primary, 0.1);
        }
      }

      &.active {
        p {
          color: $c-primary;
        }

        &:after {
          background-color: rgba($c-primary, 1);
        }
      }
    }


    //For now we decided not to have more then 3 tabs. We need to add scroll if more is required
    @include breakpoint(-milli) {
      padding-left: 0;
      .tab-navigation-item {
        flex: 1;
        margin: 0;
        padding: 0;

        p {
          margin: $g2 0;
          text-align: center;
        }

        &:after {
          width: 90px;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin store-article-list-item-theme() {
  cbr-store-article-list-item {
    display: flex;
    flex-direction: column;
    width: 340px;
    background-color: white;
    padding: $gt;
    padding-bottom: $gt2;
    border-radius: $radius;

    .article-image {
      margin-bottom: $gt2;

      [slot='image'] {
        width: 300px;
        height: 180px;
        border-radius: $radius;
      }
    }

    p {
      margin: 0;
      margin-bottom: $gt-h;
      color: $c-text-dark;
      font-weight: $f-weight-subtitle;
      font-size: $f-size-p;
      text-transform: uppercase;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      color: $c-subtitle;
      font-weight: $f-weight-subtitle;
      font-size: $f-size-2;
    }

    @include breakpoint(-milli) {
      width: auto;
      padding: $g3;

      .article-image {
        flex: 0 1 180px;
        position: relative;
        margin-bottom: $g3;

        [slot='image'] {
          @include image-cover;
        }
      }

      p {
        text-transform: none;
        margin-bottom: $g;
        font-weight: $f-weight-light;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin store-app-list-item-theme() {
  cbr-store-app-list-item {
    $icon-size: $g7;

    display: flex;
    align-items: center;
    background-color: white;
    border-radius: $radius;
    padding: $gt + $gt-h;

    [slot='icon'] {
      width: $icon-size;
      height: $icon-size;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: $f-size-4;
      font-weight: $f-weight-subtitle;
      color: $c-subtitle;
      margin-top: 0;
      margin-bottom: $g-h;
    }

    p {
      margin: 0;
      font-size: $f-size-p;
      font-weight: $f-weight;
      color: rgba($c-subtitle, 0.7);
    }

    section {
      flex-grow: 1;
      margin-left: $gt;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    [slot='action'] {
      width: $g10;
      min-width: $g10;
      height: $g4;
      margin-left: $gt;
    }

    @include breakpoint(-milli) {
      $icon-size: $g6;

      padding: $g3;

      [slot='icon'] {
        width: $icon-size;
        height: $icon-size;
      }

      section {
        margin-left: $g2;
        @include overflow-ellipsis-parent
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: $f-size-5;
        color: $c-text-field;
        font-weight: $f-weight-subtitle;
        margin-bottom: $g;
        @include single-line-overflow-ellipsis;
      }

      p {
        font-size: $f-size-6;
        color: $c-text-dark;
        font-weight: $f-weight-light;
        @include single-line-overflow-ellipsis;
      }

      [slot='action'] {
        margin-left: $g;
        width: $g8;
        min-width: $g8;
        height: $g4;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin carousel() {

  .carousel-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: $b-shadow;
    border-radius: $radius;
    padding: $gt2 0;

    .carousel-header {
      display: flex;
      justify-content: space-between;
      margin: $gt;
    }
  }
  .scrollable-content-wrapper {
    max-width: 100%;
    scroll-behavior: smooth;
    overflow: hidden;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  @include breakpoint(-milli) {
    .carousel-card {
      padding: $g4 0;

      .carousel-header {
        margin: 0 $g2 $g3 $g2;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin google-shopping-preview-card-theme() {
  .product-preview-card {
    display: flex;
    flex-direction: column;
    width: 122px;
    height: 255px;
    border: 1px solid $c-disabled;
    border-radius: $radius-2;
    margin-left: $gt-h;

    .product-preview-image {
      display: flex;
      width: 100%;
      min-height: 118px;
      max-height: 118px;
      border-top-left-radius: $radius-2;
      border-top-right-radius: $radius-2;
    }

    .product-preview-details {
      * {
        font-family: arial,sans-serif;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      margin: 8px;
      font-size: $f-size-5;

      .product-preview-details-top {
        .product-preview-details-title {
          color: $c-google-title;
          font-size: 14px;
          line-height: 20px;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          max-height: 36px;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }

        .product-preview-details-price {
          color: $c-google-price;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          height: 20px;
          letter-spacing: 0.2px;
        }

        .product-preview-details-website {
          margin-top: 2px;
          color: $c-google-grey;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
          line-height: 20px;
          height: 20px;
        }
      }

      .product-preview-details-owner {
        color: $c-google-title;
        font-size: $f-size-5;
      }
    }
  }
}

@mixin box-theme() {
  cbr-box {
    display: flex;
    width: 100%;

    &.responsive {
      @include breakpoint(-milli) {
        flex-direction: column !important;
      }
    }

    &.horizontal > * {
      margin-right: $gt;

      @include breakpoint(-milli) {
        margin-bottom: $gt;
      }
    }

    &.vertical > * {
      margin-bottom: $gt;
    }

    & > *:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
    &.column{
      flex-direction: column;
    }

    &.row{
      flex-direction: row;
    }
  }
}

@mixin spinner-theme() {
  cbr-spinner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@mixin file-picker-theme() {
  .file-upload {
    display: none;
  }

  .invalid-image {
    color: $c-alert-text;
  }
}

@mixin layer-theme() {
  cbr-layer {
    display: block;
    position: relative;

    & > * {
      position: absolute;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin activity-item-theme() {
  cbr-activity-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $c-input-border;
    padding: $gt 0;
  }

  .controls-left {
    display: flex;
    flex: 2 1 auto;
    flex-basis: 200px;
  }

  .controls-right {
    display: flex;
    justify-content: flex-end;
    flex: 1 1 auto;
    flex-basis: 100px;
  }

  .activity-list-item-body-and-status {
    display: flex;
    flex: 1;
  }

  .activity-list-item-body {
    flex: 1;
    @include overflow-ellipsis-parent;

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
      color: $c-subtitle;
      @include single-line-overflow-ellipsis();
    }

    p {
      margin: 0;
      @include single-line-overflow-ellipsis();
    }
  }

  .activity-list-item-action-item {
    width: $g4;
    height: $g4;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: $radius;
    fill: $c-grey-dark;

    &:hover {
      border: 1px solid $c-primary;
      fill: $c-primary;
    }
  }

  .activity-list-item-image {
    margin-right: $gt;
    display: flex;
    align-items: center;

    [slot="icon"] {
      width: $g7;
      height: $g7;
    }
  }

  .status-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview-container {
    margin-right: $gt;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .actions-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    [slot="actions"] {
      width: 110px;
    }
  }

  @include breakpoint(-milli) {
    .activity-list-item-body {
      flex: 0;

      h1, h2, h3, h4, h5, h6 {
        font-size: $f-size-5;
        color: $c-subtitle;
        margin-bottom: $g;
      }

      p {
        display: none;
      }
    }

    .activity-list-item-body-and-status {
      max-width: 130px;
      flex-direction: column;
      align-items: flex-start;
    }

    .activity-list-item-image {
      margin-right: $g2;

      [slot="icon"] {
        width: $g6;
        height: $g6;
      }
    }

    .actions-container {
      [slot="actions"] {
        width: 90px;
      }
    }
  }
}


// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin theme-preview-theme() {
  cbr-theme-preview {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 55%; // For scaleable design grid
    position: relative;

    .bg {
      overflow: hidden;
      border-radius: $radius;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;

      .main-text {
        font-size: $f-size-1;
        text-transform: capitalize;
      }

      .patch-wrap {
        position: absolute;
        display: flex;
        top: $gt;
        right: $gt;
      }

      .color-patch {
        border-radius: $radius;
        height: 25px;
        width: 25px;
        box-shadow: 0 2px 2px rgba(#000, 0.05);
        margin-left: $gt-h;
      }

      &.small {
        .main-text {
          font-size: $f-size-4;
        }

        .color-patch {
          border-radius: 3px;
          height: 15px;
          width: 15px;
        }
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin checkbox-list-item-theme() {
  cbr-checkbox-list-item {
    .checkbox-list-item {
      display: flex;
      align-items: center;
      padding: $g2 $g3;
      border: 1px solid $c-grey-lighter;
      border-radius: $radius;
      cursor: pointer;
      background-color: white;
      color: $c-text-dark;

      &:hover,
      &.active {
        border-color: $c-primary-lighter;
        background-color: $c-primary-lightest;
        color: $c-primary;
      }
    }

    cbr-checkbox {
      pointer-events: none;
    }

    .field {
      margin: 0;
    }

    .checkbox-list-item-content {
      font-size: $f-size-5;
      font-weight: $f-weight-light;
      margin-left: $g2;
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin flow-navigation-footer-theme() {
  cbr-flow-navigation-footer {
    .flow-navigation-footer-container {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $g3 $g6;
      border-bottom-left-radius: $radius;
      border-bottom-right-radius: $radius;
    }

    .flow-navigation-footer-left,
    .flow-navigation-footer-right {
      width: 100%;
      max-width: 160px;

      button {
        width: 100%;
      }
    }

    .flow-navigation-footer-center {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        font-size: $f-size-5;
        color: $c-text-dark;
      }
    }

    @include breakpoint(-milli) {
      .flow-navigation-footer-left,
      .flow-navigation-footer-right {
        max-width: 120px;
      }
    }
  }
}

// Remember to include this in the "_all-components.theme.scss" to apply themes to the component.
@mixin summary-theme() {
  cbr-summary {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: $g3 $g2;
    border: 1px solid $c-disabled;
    border-radius: $radius;

    .left {
      display: flex;
      flex-direction: column;
    }

    .right {
      display: flex;
      align-items: center;
      margin-left: $g-h;
    }

    p {
      color: $c-text-dark;
      font-weight: $f-weight-light;
      margin-top: 0;
      margin-bottom: 0;
    }

    b {
      margin-top: $g-h;
      margin-bottom: 0;
      color: $c-subtitle;
    }

    @include breakpoint(-milli) {
      padding: $g2;
    }
  }
}

/* // Remember to include this in the "_all-components.theme.scss" to apply themes to the component. */
@mixin insight-box-theme() {
  .insight-box {
    padding: $g4;
    background-color: white;
    border: 1px solid $c-grey-border;
    color: $c-text-dark;
    border-radius: $radius;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
  }

  .text-container {
    margin-top: $g4;
  }
}


@mixin themes() {
  @include arrow-buttons-theme();
  @include button-theme();
  @include chip-theme();
  @include counter-theme();
  @include dropdown-theme();
  @include gauge-counter-theme();
  @include input-errors-theme();
  @include input-theme();
  @include calendar-theme();
  @include checkbox-theme();
  @include loader-theme();
  @include email-theme();
  @include number-theme();
  @include progress-bar-theme();
  @include simple-budget-picker-theme();
  @include svg-theme();
  @include range-slider-theme();
  @include disclaimer-theme();
  @include sidebar-helper-theme();
  @include nav-sidebar-theme();
  @include nav-bottombar-theme();
  @include nav-topbar-theme();
  @include url-theme();
  @include verification-number-theme();
  @include app-recommendation-list-item-theme();
  @include app-card-theme();
  @include onboarding-card-theme();
  @include datatable-theme();
  @include datatable-row-theme();
  @include datatable-cell-theme();
  @include datatable-header-theme();
  @include datatable-header-cell-theme();
  @include payment-card-theme();
  @include hero-theme();
  @include snackbar-item-theme();
  @include badge-theme();
  @include user-dropdown-list-item-theme();
  @include user-dropdown-theme();
  @include nav-topbar-list-item-theme();
  @include tab-navigation-theme();
  @include store-article-list-item-theme();
  @include store-app-list-item-theme();
  @include carousel();
  @include google-shopping-preview-card-theme();
  @include box-theme();
  @include field-theme();
  @include spinner-theme();
  @include file-picker-theme();
  @include layer-theme();
  @include activity-item-theme();
  @include toggle-theme();
  @include color-picker-theme();
  @include theme-preview-theme();
  @include checkbox-list-item-theme();
  @include flow-navigation-footer-theme();
  @include summary-theme();
  @include insight-box-theme();
}

/**
  * Import here all the styles that are not in mixins or other tools.
  */
@keyframes infiniteLoader {
  from {
    background-size: 20%;
    background-position: -25% 0;
  }
  50% {
    background-size: 80%;
  }
  to {
    background-size: 20%;
    background-position: 150% 0;
  }
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@mixin overflowScrolling($dir: y) {
  -webkit-overflow-scrolling: touch;

  @if $dir == y {
    overflow-x: hidden;
    overflow-y: auto;
  }

  @if $dir == x {
    overflow-x: auto;
    overflow-y: hidden;
  }

  @if $dir == xy {
    overflow: auto;
  }

  @if $dir == yx {
    overflow: auto;
  }
}


/**
  *	Import here all the mixins that contain base styling for compoent in the library
  */
@mixin defaults-theme {
  .item-is-loading {
    width: 40px;
    height: 40px;
    position: relative;
    color: transparent !important;
    pointer-events: none;

    > * {
      display: none;
    }

    &::after {
      $thickness: 2px;

      content: '';
      animation: spinAround 0.5s infinite linear;
      border: $thickness solid;
      border-color: transparent transparent $c-grey-dark $c-grey-dark;
      border-radius: 290486px;
      display: block;
      height: calc(100% - #{$thickness * 2});
      width: calc(100% - #{$thickness * 2});

      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

@function to-string($value) {
  @return inspect($value);
}

@mixin breakpoint($point) {
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
  $breakpoint-map: (
    giga: (
      $bp-giga,
    ),
    mega: (
      $bp-mega,
    ),
    kilo: (
      $bp-kilo,
    ),
    centi: (
      $bp-centi,
    ),
    milli: (
      $bp-milli,
    ),
    nano: (
      $bp-nano,
    ),
    pico: (
      $bp-pico,
    ),
  );
  @if length($point) > 1 {
    $point1: nth($point, 1);
    $point2: nth($point, 2);

    @if type-of($point1) == 'number' and unitless($point1) {
      $point1: nth($point, 1) + 0px;
    }

    @if type-of($point2) == 'number' and unitless($point2) {
      $point2: nth($point, 2) + 0px;
    }

    @if type-of($point1) == 'number' and type-of($point2) == 'number' {
      @media only screen and (min-width: $point1) and (max-width: $point2) {
        @content;
      }
    } @else if type-of($point1) == 'number' {
      @media only screen and (min-width: $point1) and (max-width: map-get($breakpoint-map, $point2)) {
        @content;
      }
    } @else if type-of($point2) == 'number' {
      @media only screen and (min-width: map-get($breakpoint-map, $point1)) and (max-width: $point2) {
        @content;
      }
    } @else {
      @media only screen and (min-width: map-get($breakpoint-map, $point1)) and (max-width: map-get($breakpoint-map, $point2)) {
        @content;
      }
    }
  } @else {
    @if $point == giga or $point == -giga {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-giga) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-giga) {
          @content;
        }
      }
    } @else if $point == mega or $point == -mega {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-mega) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-mega) {
          @content;
        }
      }
    } @else if $point == kilo or $point == -kilo {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-kilo) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-kilo) {
          @content;
        }
      }
    } @else if $point == centi or $point == -centi {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-centi) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-centi) {
          @content;
        }
      }
    } @else if $point == milli or $point == -milli {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-milli) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-milli) {
          @content;
        }
      }
    } @else if $point == nano or $point == -nano {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-nano) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-nano) {
          @content;
        }
      }
    } @else if $point == pico or $point == -pico {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-pico) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-pico) {
          @content;
        }
      }
    } @else if $point == iphoneX {
      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        @content;
      }
    } @else if $point == iphoneX-portrait {
      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
        @content;
      }
    } @else if $point == iphoneX-landscape {
      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
        @content;
      }
    } @else if $point == print {
      @media print {
        @content;
      }
    } @else if type-of($point) == 'number' {
      @media only screen and (min-width: $point) {
        @content;
      }
    } @else if str-index($point, max) == 1 or str-index($point, min) == 1 {
      @media only screen and (#{$point}) {
        @content;
      }
    }
  }
}

@mixin grid() {
  %grid-wrap {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + #{$gt});
    margin-left: -$gt;
  }
}

@function columns($cols, $spacer: $gt, $spans: 1) {
  @if $cols == 1 {
    @return 100%;
  } @else {
    @return calc(((100% / #{$cols}) * #{$spans}) - #{$spacer});
  }
}

@function simpleCols($cols, $spacer: $gt) {
  @if $cols == 1 {
    @return 100%;
  } @else {
    @return calc(100% / 3 - #{($gt * ($cols - 1)) / 3});
  }
}

@mixin base-input {
  %base-input {
    width: 100%;
    border-radius: $radius;
    border: 1px solid $c-input-border;
    color: $c-input-text;
    height: 40px;
    line-height: 20px;
    box-shadow: none;
    padding: 0 $gt;
    font-size: $f-size-p;
    transition: border-color 150ms ease;
    font-family: $f-family-primary;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: $c-input-border-hover;
    }

    &[readonly],
    &:disabled {
      opacity: 0.5;
    }

    &.icon {
      padding-right: $gt2;
    }

    &.is-invalid {
      border: 1px solid $c-error-border;
    }
  }
}

@mixin base-label-custom {
  %base-label-custom {
    display: flex;
    border: 1px solid $c-input-border;
    border-radius: $radius;
    transition: border-color 150ms ease;

    &:focus-within {
      border-color: $c-input-border-hover;
    }

    input {
      border: 0;
    }

    .label {
      display: block;
      border-right: 0;
      border-radius: $radius 0 0 $radius;
      background: $c-grey-light;
      border-right: 1px solid $c-input-border;
      padding: 0 $gt;
      color: $c-grey-dark;
      line-height: 38px;
      white-space: nowrap;
    }
  }
}

@mixin base-label-right {
  %base-label-right {
    display: flex;
    border: 1px solid $c-input-border;
    border-radius: $radius;
    transition: border-color 150ms ease;

    &:focus-within {
      border-color: $c-input-border-hover;
    }

    input {
      border: 0;
    }

    &:after {
      content: attr(label);

      display: block;
      border-right: 0;
      border-radius: $radius 0 0 $radius;
      background: $c-grey-light;
      border-right: 1px solid $c-input-border;
      padding: 0 $gt;
      color: $c-grey-dark;
      line-height: 38px;
      white-space: nowrap;
    }
  }
}

@mixin base-label {
  %base-label {
    display: flex;
    border: 1px solid $c-input-border;
    border-radius: $radius;
    transition: border-color 150ms ease;

    &:focus-within {
      border-color: $c-input-border-hover;
    }

    input {
      border: 0;
    }

    &:before {
      content: attr(label);

      display: block;
      border-right: 0;
      border-radius: $radius 0 0 $radius;
      background: $c-grey-light;
      border-right: 1px solid $c-input-border;
      padding: 0 $gt;
      color: $c-grey-dark;
      line-height: 38px;
      white-space: nowrap;
    }
  }
}

@mixin base-phone {
  %base-phone {
    display: flex;
    border: 1px solid $c-input-border;
    border-radius: $radius;
    transition: border-color 150ms ease;

    input {
      border: 0;
    }

    cbr-phone-select {
      border-right: 1px solid $c-input-border;
      min-width: 55px;

      ng-select.ng-select {
        .ng-select-container {
          border: 0;
          background: transparent;
        }

        .ng-dropdown-panel {
          width: 350px;
          background: #fff;
          border: 1px solid $c-input-border;
          border-radius: $radius;
          overflow: hidden;
          top: calc(100% + 8px);
        }

        .ng-value {
          display: flex;
          align-items: center;
          justify-content: center;

          cbr-svg {
            display: block;
            position: static;
            height: 10px !important;
            margin-left: 5px;
          }
        }

        .ng-dropdown-panel-items {
          border-width: 1px 0 0 0;
          border-radius: 0;
        }

        .ng-clear-wrapper {
          text-align: center;
          display: inline-block;
          margin-left: 5px;
        }
      }
    }
  }
}


@mixin form-fields {
  @include base-input;
  @include base-label-custom;
  @include base-label-right;
  @include base-label;
  @include base-phone;

  .field {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: $gt;
    color: $c-grey-dark;

    label {
      margin-bottom: $gt-h;
      font-weight: $f-weight-title;
      user-select: none;
      color: $c-grey-darker;
      position: relative;
      padding-right: $gt;

      .optional {
        margin-left: $gt-q;
      }
    }

    .input-counter {
      color: $c-grey-dark;
      position: absolute;
      font-weight: $f-weight;
      right: 0;
      bottom: 0;
      transition: color 200ms ease;

      &.is-invalid {
        color: $c-error-text;
      }
    }

    .input-wrap {
      position: relative;
      flex: 1 0;
      display: flex;

      textarea,
      input {
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
          color: $c-placeholder;
          opacity: 1;
        }
      }

      textarea:focus,
      input:focus {
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder,
        &::placeholder {
          color: transparent;
        }
      }

      @for $i from 1 through 10 {
        &.z-index-#{$i}0 {
          z-index: #{$i}0;
        }
      }

      ng-select {
        width: 100%;
        position: relative;
      }

      cbr-svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }

      .left {
        left: 15px;
      }
      .right {
        right: 15px;
      }

      cbr-range-slider {
        width: 100%;

        .range.no-label {
          .range-custom-wrap {
            top: 11px;
          }

          .range-wrap {
            input {
              height: 20px;
              line-height: 20px;
            }
          }
        }
      }

      .ng-option {
        cbr-svg {
          position: static;
          transform: translate(0, 0);
        }
      }

      &[phone] {
        @extend %base-phone;
      }

      &[label] {
        @extend %base-label;
      }

      &.custom-label {
        @extend %base-label-custom;
      }

      &[label-right] {
        @extend %base-label-right;

        &:after {
          content: attr(label-right);
          border-radius: 0 $radius $radius 0;
          border: 0;
          border-left: 1px solid $c-input-border;
        }
      }

      &.icon-left {
        input {
          padding-left: 40px;
        }
      }

      &.is-loading {
        > cbr-svg {
          display: none;
        }

        &::after {
          content: '';
          animation: spinAround 0.5s infinite linear;
          border: 2px solid #dbdbdb;
          border-color: transparent transparent $c-primary $c-primary !important;
          border-radius: 290486px;
          display: block;
          height: 1em;
          width: 1em;

          position: absolute;
          right: 15px;
          top: calc(50% - (1em / 1.6));
        }
      }
    }

    .input-wrap--overflow-link {
      @extend .input-wrap;

      input {
        padding-right: 60px;
      }

      a {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 0 $gt;
      }
    }

    textarea {
      @include overflowScrolling;

      width: 100%;
      border-radius: $radius;
      border: 1px solid $c-input-border;
      color: #000;
      box-shadow: none;
      padding: $gt-h;
      font-size: $f-size-p;
      transition: border-color 150ms ease;
      font-family: $f-family-primary;
      line-height: 24px;
      resize: none;

      &:focus {
        outline: none;
        box-shadow: none;
        border-color: $c-input-border-hover;
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    input {
      @extend %base-input;

      &[type='checkbox'] {
        visibility: hidden;
        opacity: 0;
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        position: fixed;
        top: -5000px;
      }
    }

    textarea:focus::placeholder,
    input:focus::placeholder {
      color: transparent;
    }

    .input-wrap--submit {
      display: flex;
      align-items: center;

      cbr-button {
        margin-left: $gt;
      }
    }
  }

  .input-checkbox {
    display: flex;
    align-items: center;

    input {
      display: none;
    }

    .label-checkbox {
      font-weight: 400;
      flex: 1 0;

      .header {
        font-weight: $f-weight-title;
      }

      .description {
        color: $c-grey-dark;
      }
    }

    .checkbox-toggle {
      border-radius: 12px;
      position: relative;
      background: $c-grey-light;
      width: 42px;
      height: 24px;
      transition: background 200ms ease;
      cursor: pointer;

      &:after {
        $spacer: 3px;

        content: '';
        position: absolute;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-95%);
        top: $spacer;
        width: 18px;
        height: 18px;
        background: #fff;
        display: block;
        transition: transform 200ms ease;
      }

      &.active {
        background: $c-secondary;

        &:after {
          transform: translateX(-5%);
        }
      }
    }
  }

  // Reset defaults
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }

  .form-wrap {
    max-width: 600px;

    fieldset {
      margin-bottom: $gt2;
    }
  }

  .cbr-input-errors,
  cbr-input-errors {
    color: $c-alert-text;
    font-size: $f-size-6;
    margin: 5px 0;

    p {
      margin: 0;
    }
  }

  cbr-store-details {
    ng-select.ng-select {
      input {
        width: 100%;
        line-height: 24px;
        outline: none;
        padding: 5px;
        font-size: $f-size-p;
        border: none;
        border-bottom: 1px solid $c-input-border;
      }

      .ng-dropdown-panel {
        border: 1px solid $c-input-border;
        border-radius: $radius;
      }

      .ng-dropdown-panel-items {
        border: none;
      }
    }
  }
}

@mixin font {
  body {
    font-family: $f-family-primary;
  }
}

@mixin ribbon {
  .ribbon {
    background: $c-primary;
    color: #fff;
    border-radius: $radius/2 $radius/2 0 $radius/2;

    &:after {
      border-left: 4px solid $c-primary-dark;
    }
  }

  .ribbon {
    position: absolute;
    top: 5px;
    right: -4px;
    padding: 5px;
    line-height: 10px;
    font-size: 11px;

    &:after {
      content: '';
      border-bottom: 4px solid transparent;
      position: absolute;
      top: 20px;
      right: 0;
    }
  }
}

@mixin disclaimer {
  .disclaimer {
    border-radius: $radius;
    padding: $gt;
    margin-bottom: $gt;

    &-warning {
      @extend .disclaimer;
      background-color: $c-warning-bg;
      color: $c-warning-text;
      border: 1px solid $c-warning-border;
    }

    &-alert {
      @extend .disclaimer;
      background-color: $c-alert-bg;
      color: $c-alert-text;
      border: 1px solid $c-alert-border;
    }

    &-info {
      @extend .disclaimer;
      border-radius: $radius;
      padding: $gt;
      margin-bottom: $gt;
      background: $c-info-bg;
      color: $c-info-text;
      border: 1px solid $c-info-border;
    }
  }
}

@mixin image-cover() {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  right: 0;
  top: 0;
  bottom: 0;
}

//This is only solution to truncate child text in flexbox
@mixin overflow-ellipsis-parent {
  align-self: stretch;
  min-width: 0;
}

@mixin overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin single-line-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin multiline-ellipsis($lines) {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


@mixin base-styles() {
  @include defaults-theme();
  @include font();
  @include form-fields();
  @include ribbon();
  @include breakpoint(point);
  @include grid();
  @include disclaimer();
}

@mixin cbr-primitives-apply-theme() {
  @include base-styles();
  @include themes();
}
