/* The container */
.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: $f-size-5;
  user-select: none;
  width: 12px;
  height: 12px;
}

/* Hide the browser's default checkbox */
.container input,
.container .no-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  border: 1px solid $c-border;
  border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark,
.container .checked ~ .checkmark {
  background-color: $c-primary;
  border: 1px solid $c-primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after,
.container .checked ~ .checkmark:after {
  display: block;
  background-color: #fff;
  mask: url('/assets/svg-icons/icon-checkbox-checkmark.svg') no-repeat 50% 50%;
  mask-size: cover;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  height: 7px;
  width: 8px;
  top: 2px;
  left: 1px;
}
