.content-loading {
  overflow: hidden;
  position: relative;
  $standard-height: 14px;

  label {
    &.input-checkbox {
      display: none;
    }
  }

  img {
    background: $c-grey-light;
  }

  cbr-svg {
    display: none;
  }

  cbr-google-maps {
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 100%;
      background: $c-grey-light;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  cbr-button,
  cbr-arrow-buttons {
    position: relative;
    background: transparent;
    border: transparent;
    color: transparent;

    &:before {
      content: '';
      display: block;
      width: 100%;
      background: $c-grey-light;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      z-index: 1000;
    }

    .ribbon {
      display: none;
    }
  }

  h4 {
    color: transparent;
    position: relative;
    height: $f-size-4;

    &:before {
      content: '';
      display: block;
      height: $f-size-4;
      width: 60%;
      background: $c-grey-light;
      position: absolute;
      top: 0;
    }
  }

  h2,
  h3 {
    color: transparent;
    height: $f-size-2;

    &:before {
      content: '';
      display: block;
      height: $f-size-2;
      width: 60%;
      background: $c-grey-light;
      top: 0;
    }
  }

  .text,
  label,
  a,
  p {
    $standard-height: 14px;
    color: transparent !important;
    height: 42px;
    position: relative;
    border: none !important;

    &:before {
      content: '';
      display: block;
      height: $standard-height;
      width: 100%;
      background: $c-grey-light;
      top: 0;
      margin: 2px 0 !important;
    }

    &:after {
      content: '';
      display: block;
      height: $standard-height;
      width: 80%;
      background: $c-grey-light;
      top: 18px;
      margin: 2px 0 !important;
      position: absolute;
    }

    &.single {
      height: $standard-height;
      margin: 2px 0 !important;

      &:after {
        display: none;
      }
    }
  }

  span {
    $standard-height: 14px;
    color: transparent !important;
    height: 42px;
    position: relative;

    &:before {
      content: '';
      display: block;
      height: $standard-height;
      width: 100%;
      background: $c-grey-light;
      top: 0;
      margin: 2px 0 !important;
    }
  }

  label,
  .text {
    height: $standard-height;
    width: 100px;

    &:after {
      display: none;
    }

    .ad-field-label-char-counter {
      color: transparent !important;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 50000;
    width: 500%;
    margin-left: -250%;
    animation: contentLoadingKeyframes 800ms linear infinite;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 46%,
        rgba(255, 255, 255, 0.35) 50%,
        rgba(255, 255, 255, 0) 54%
      )
      50% 50%;
  }

  input,
  textarea,
  ng-select {
    background-color: $c-grey-light;
    color: transparent !important;
    // Hack to remove the placeholder
    font-size: 0;
  }

  cbr-input,
  cbr-textarea {
    .input-counter {
      display: none;
    }
  }

  ng-select {
    .ng-value,
    .ng-placeholder {
      color: transparent !important;
    }

    .ng-arrow-wrapper {
      display: none;
    }
  }

  .chips {
    .chip {
      min-width: 100px;
      min-height: 24px;
      background-color: $c-grey-light !important;
    }
  }

  .arrows {
    display: none !important;
  }

  .image-container {
    &.upload {
      border-color: $c-grey-light !important;
    }

    img {
      background-color: $c-grey-light;
      border-color: $c-grey-light !important;
    }

    .tick-container {
      display: none;
    }
  }

  .target-list {
    .target-list-item-map {
      height: 140px;
      background-color: $c-grey-light;
    }
  }

  cbr-budget-picker {
    .custom-budget {
      .toggle {
        background-color: $c-grey-light;
        color: transparent !important;
      }
    }
  }

  cbr-flow-info-card,
  cbr-activity-edit-header,
  cbr-billing {
    img {
      background-color: $c-grey-light !important;
      // Hide image
      object-position: -999999999px;
    }
  }

  cbr-arrow-buttons {
    display: none;
  }

  cbr-ad-status {
    div {
      background: transparent !important;
      border: transparent !important;
      color: transparent !important;
    }

    &:before {
      content: '';
      display: block;
      height: 24px;
      background: $c-grey-light;
      border-radius: 24px;
    }
  }

  cbr-counter {
    position: relative;
    background: transparent;
    border: transparent;
    color: transparent;

    div {
      visibility: hidden;
    }

    &:before {
      content: '';
      display: block;
      height: $f-size-2;
      width: 100%;
      background: $c-grey-light;
      position: absolute;
      top: 0;
    }
  }

  ng-select.ng-select .ng-select-container {
    border: none;
  }

  .field input {
    border: none;
  }
}
