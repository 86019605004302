cbr-google-maps {
  width: 100%;
  height: 100%;
  display: block;

  agm-map {
    height: 100% !important;
    display: block;
  }
}

.maps-container {
  width: 100%;
  height: 500px;
}
