.progress-completed {
  background: $c-primary;
}

.preboarding-progress {
  position: relative;
  padding-bottom: 7px;
  display: flex;
  flex-direction: column;

  cbr-progress-bar {
    bottom: 0;
    height: 5px;
  }
}
