@mixin flowWrap($dir: column) {
  display: flex;
  flex: 1 0;
  flex-direction: $dir;
  height: 100%;
  overflow: hidden;
}

@mixin flow($dir: column, $as: '%') {
  @include overflowScrolling;

  display: flex;
  flex: 1 0;
  flex-direction: $dir;

  @if $as == '%' {
    height: 100%;
  } @else {
    min-height: 100vh;
  }
}
