/**
  *	Colors
  */
$c-primary: #0087ef !default;
$c-primary-light: #0090ff !default;
$c-primary-lighter: #d2e8fc !default; // E.g.: Used as border for hovered items
$c-primary-lightest: #f4f9fe !default; // E.g.: Used as background for hovered items
$c-primary-dark: #0d47a1 !default;
$c-primary-dark: lighten($c-primary-dark, 10%) !default;

$c-secondary: #27d07b !default;

// Grey colors
$c-grey-darker: #333333 !default;
$c-grey-dark: #92a0af !default;
$c-grey: #cfd5db !default;
$c-grey-light: #e7eaed !default;
$c-grey-secondary: #b6c3cd !default;
$c-grey-border: #dbe2ed !default;
$c-grey-lighter: #e3e3e3 !default;
$c-grey-lightest: #fcfcfc !default;

// Input colors
$c-input-border: #e5e9ed !default;
$c-input-border-hover: darken($c-input-border, 15%) !default;

// Entry colors
$c-radius: #ef0000;

// Text colors
$c-text-paragraph: $c-grey-dark !default;
$c-text: $c-text-paragraph !default;
$c-text-dark: #4c6c83 !default;
$c-subtitle: #002d4f !default;
$c-text-field: #002d4f !default;

// Notification colors
$c-info-bg: #cce5ff !default;
$c-info-border: #b8daff !default;
$c-info-text: #004085 !default;

$c-alert-bg: #f8d7da !default;
$c-alert-border: #f5c6cb !default;
$c-alert-text: #721c24 !default;

$c-success-bg: #d4edda !default;
$c-success-border: #c3e6cb !default;
$c-success-text: #155724 !default;

$c-warning-bg: #ffe0b2 !default;
$c-warning-border: #ffe0b2 !default;
$c-warning-text: #e6511c !default;

$c-error-border: #af0305 !default;
$c-error-text: #af0305 !default;

// Input colors
$c-input-border: #e5e9ed !default;
$c-input-border-hover: darken($c-input-border, 15%) !default;
$c-input-text: #4c6c83 !default;
$c-placeholder: opacify($c-input-text, 0.5) !default;

// Shadow colors
$c-shadow: #0000001a;

// Background colors
$c-white: #ffffff;
$c-content-bg: #f4f9fc;

// Sidebar helper colors
$c-sidebar-helper-gradient-primary-dark: #004bd5;
$c-sidebar-helper-gradient-primary-light: #62c9ff;

// Disabled
$c-disabled: #e0e7ed !default;

/**
  *	Border & Border-radius defaults
  */
$radius: 5px !default;
$radius-2: $radius * 2;
$radius-20: $radius * 20;

/**
  * Fonts
  */
@import url(https://use.typekit.net/rpt6ped.css);
$f-family-primary: 'Soleil', sans-serif !default;

// Font sizes
$f-size-1: 32px !default;
$f-size-2: 24px !default;
$f-size-3: 18px !default;
$f-size-4: 16px !default;
$f-size-5: 14px !default;
$f-size-6: 12px !default;
$f-size-p: 14px !default;

/**
  * Font weight
  */
$f-weight-normal: normal;
$f-weight-title: 700;
$f-weight-subtitle: 600;
$f-weight: 400;
$f-weight-light: 300;

/**
  * Font line height
  */
$f-line-height-p: 20px;

/**
  *	Gutter
  */
$gt: 20px !default; // Default

// Lower than base gutter
$gt-h: $gt / 2 !default; // Small
$gt-q: $gt / 4 !default; // Xsmall

// Higher than base gutter
$gt2: 2 * $gt !default; // Large
$gt3: 3 * $gt !default; // X-large
$gt4: 4 * $gt !default;
$gt5: 5 * $gt !default;
$gt6: 6 * $gt !default;

// New gutter system
$g: 8px !default;
// Lower than base gutter
$g-q: $g / 4 !default; // 2px
$g-h: $g / 2 !default; // 4px
// Higher than base gutter
$g2: 2 * $g !default; // 16px
$g3: 3 * $g !default; // 24px
$g4: 4 * $g !default; // 32px
$g5: 5 * $g !default; // 40px
$g6: 6 * $g !default; // 48px
$g7: 7 * $g !default; // 56px
$g8: 8 * $g !default; // 64px
$g9: 9 * $g !default; // 72px
$g10: 10 * $g !default; // 80px
$g11: 11 * $g !default; // 88px
$g12: 12 * $g !default; // 96px
$g13: 13 * $g !default; // 104px
$g14: 14 * $g !default; // 112px
$g15: 15 * $g !default; // 120px

// Breakpoints
$bp-giga: 2560px !default;
$bp-mega: 1920px !default;
$bp-kilo: 1366px !default;
$bp-centi: 1024px !default;
$bp-milli: 768px !default;
$bp-nano: 480px !default;
$bp-pico: 320px !default;

/*
 *  Shadow
 */
$b-shadow: 0 3px 20px rgba(#d0e6f4, 0.5);
$b-shadow-hover: 0 3px 25px darken(#e7f2f9, 5%);

// Google colors
$c-google-title: #1a0dab;
$c-google-ad: #006621;
$c-google-grey: #222;
$c-google-text: #545454;
$c-google-price: #222222;
