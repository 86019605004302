@import '~@angular/material/theming';

// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
@include mat-core();

// define a real custom palette (using http://mcg.mbitson.com)
$md-cobiro: (
  50: #e3effa,
  100: #bad6f2,
  200: #8cbbe9,
  300: #5e9fe0,
  400: #3c8bd9,
  500: #1976d2,
  600: #166ecd,
  700: #1263c7,
  800: #0e59c1,
  900: #0846b6,
  A100: #e0eaff,
  A200: #adc6ff,
  A400: #7aa3ff,
  A700: #6191ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-cobiro-secondary: (
  50: #e5f9ef,
  100: #bef1d7,
  200: #93e8bd,
  300: #68dea3,
  400: #47d78f,
  500: #27d07b,
  600: #23cb73,
  700: #1dc468,
  800: #17be5e,
  900: #0eb34b,
  A100: #e0ffea,
  A200: #adffc8,
  A400: #7affa6,
  A700: #61ff95,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// mandatory stuff for theming
$bv-palette-primary: mat-palette($md-cobiro);
$bv-palette-accent: mat-palette($md-cobiro-secondary);

// include the custom theme components into a theme object
$bv-theme: mat-light-theme($bv-palette-primary, $bv-palette-accent);

// include the custom theme object into the angular material theme
@include angular-material-theme($bv-theme);

body .mat-dialog-container {
  display: block;
  padding: 0;
  border-radius: $radius;
  box-sizing: border-box;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  overflow-x: hidden;

  &:focus {
    outline: none;
  }
}

.dialog-dropdown-container .mat-dialog-container {
  overflow: visible;
  .mat-dialog-content {
    overflow: visible;
  }
}
