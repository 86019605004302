body {
  .apexcharts-toolbar {
    right: 20px;
  }

  .apexcharts-legend.position-bottom {
    .apexcharts-legend-series {
      margin: 10px 10px 0 !important;
    }
  }

  .apexcharts-area-series .apexcharts-area {
    fill-opacity: 0.5;
  }
}
