.stat-grid {
  @extend %grid-wrap;

  @include breakpoint(-milli) {
    width: 100%;
    margin-left: 0;
  }
}

.stat-item {
  @extend .paper;

  width: columns(3);
  margin-left: $gt;
  margin-bottom: $gt;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: $gt;

  @include breakpoint(-milli) {
    width: 100%;
    margin-left: 0;
  }

  &.cols-4 {
    width: columns(4);

    @include breakpoint(-milli) {
      width: 100%;
      margin-left: 0;
    }
  }

  &.span-all {
    width: 100%;
  }

  &.no-pad {
    padding: 0;
  }

  h1 {
    margin-top: $gt-h;
    margin-bottom: $gt-q;
    font-weight: 800;
    text-align: center;
    line-height: 32px;
  }

  p {
    margin-bottom: 0;
  }
}

.stat-item--content-left {
  @extend .stat-item;

  flex-direction: column;
  align-items: flex-start;
}
