* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

app-root {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: $f-family-primary;
  font-size: $f-size-p;
  line-height: normal;
  font-weight: $f-weight;
  color: $c-text;

  input[type='text'],
  textarea {
    font-family: $f-family-primary;
  }

  button {
    font-family: $f-family-primary;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flow-wrap {
  @include flow(row, true);
}

.page-wrap {
  padding: $gt2;
  min-height: 100%;
  position: relative;

  @include breakpoint(-centi) {
    padding: $gt;

    > h1 {
      display: none;
    }
  }

  h1 {
    margin: 0 0 $gt;
  }
}

.default-wrapper {
  max-width: 1100px;
  margin: 0 auto;
}
