/* You can add global styles to this file, and also import other style files */
@charset "utf-8";

// Import theming setup for primitives
@import '~@cobiro/primitives/theming/all-components.theme';

// Import and apply default theme to primitives
@import '~@cobiro/primitives/theming/themes/default';
@include cbr-primitives-apply-theme();

// Global helpers
@import './styles/helpers';

// Global layout
@import './styles/base/layout';
@import './styles/base/defaults';

// Override material
@import './styles/base/cdk-overlay';
@import './styles/base/override-primitives';

// Global element styling
@import './styles/base/articles';
@import './styles/base/budget';
@import './styles/base/buttons';
@import './styles/base/cbr-close';
@import './styles/base/cbr-google-maps';
@import './styles/base/cbr-svg';
@import './styles/base/charts';
@import './styles/base/custom-checkmark';
@import './styles/base/custom-dialog';
@import './styles/base/code';
@import './styles/base/configure-campaign';
@import './styles/base/content-loading';
@import './styles/base/custom-checkmark';
@import './styles/base/custom-modal';
@import './styles/base/datepicker';
@import './styles/base/disclaimer';
@import './styles/base/example';
@import './styles/base/flags';
@import './styles/base/form-fields';
@import './styles/base/line-break';
@import './styles/base/progress';
@import './styles/base/paper';
@import './styles/base/range-date-picker';
@import './styles/base/ribbon';
@import './styles/base/snackbar';
@import './styles/base/stat-boxes';
@import './styles/base/tabs';
@import './styles/base/target-list';
@import './styles/base/tooltip';
@import './styles/base/table';
@import './styles/base/triangle';

// Custom global component styling
@import './styles/custom-component/goal-selection';

// Global cobiro styling of third party module
@import './styles/base/ng-select';

// Global checkboxes
@import './styles/base/checkbox';

.example-block cbr-doc-tab .code > div {
  flex: 1 0;
  width: 100%;
}
