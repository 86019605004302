.search-palette-dialog {
  .mat-dialog-container {
    background: transparent;
  }

  cbr-select {
    width: 100%;

    .field {
      //background: transparent;

      ng-select.ng-select .ng-select-container {
        height: 80px;
        line-height: 80px;
        padding: 0 $gt;
        border: 0;
      }

      // Overwrite lib styling
      .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
        padding: 0 $gt-h;
        width: 100%;
        font-size: $f-size-3;
        color: $c-grey;
      }

      .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
        height: 100%;
      }

      .ng-input {
        display: flex;

        input {
          border: 0;
          padding: 0 $gt;
          height: 100%;
          line-height: 100%;
          font-size: $f-size-3;
          box-sizing: border-box;
        }
      }

      .ng-placeholder {
        opacity: 0.5;
        color: $c-sub-title;
        font-size: $f-size-3;
        font-weight: 400;
      }

      .ng-dropdown-panel {
        position: relative;
      }

      ng-select.ng-select .ng-dropdown-panel-items {
        border: 0;
        padding: 0;

        .ng-option {
          padding: 0 $gt;
          border-top: 1px solid $c-grey-light;
          font-size: $f-size-4;
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }
}
