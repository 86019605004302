//@import '~@angular/cdk/overlay-prebuilt.css';

.cdk-global-overlay-wrapper {
  @include overflowScrolling;

  pointer-events: initial;
  padding: 0 $gt;
  display: block;
}

.cdk-overlay-container {
  z-index: 100000;
}

.cdk-overlay-dark-backdrop {
  background: rgba(#000, 0.7);
}

.cdk-overlay-pane {
  padding: $gt4 0;
  max-height: initial;
  margin: 0 auto;

  &.mat-datepicker-popup {
    padding: 0;
    width: 320px !important;
  }

  @include breakpoint(-milli) {
    padding: $gt 0;
  }
}
