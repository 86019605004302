.disclaimer {
  border-radius: $radius;
  padding: $gt;
  margin-bottom: $gt;

  &-warning {
    @extend .disclaimer;
    background-color: $c-warning-bg;
    color: $c-warning-text;
    border: 1px solid $c-warning-border;
  }

  &-info {
    @extend .disclaimer;
    border-radius: $radius;
    padding: $gt;
    margin-bottom: $gt;
    background: $c-info-bg;
    color: $c-info-text;
    border: 1px solid $c-info-border;
  }
}
