@import url(https://use.typekit.net/rpt6ped.css);

@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/**
  *	Import here all the components' styles.
  * This will be imported after the common styles
  */
/* // Remember to include this in the "_all-components.theme.scss" to apply themes to the component. */
/**
  * Import here all the styles that are not in mixins or other tools.
  */
@keyframes infiniteLoader {
  from {
    background-size: 20%;
    background-position: -25% 0;
  }
  50% {
    background-size: 80%;
  }
  to {
    background-size: 20%;
    background-position: 150% 0;
  }
}
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
/**
  *	Import here all the mixins that contain base styling for compoent in the library
  */
/**
  *	Colors
  */
/**
  *	Border & Border-radius defaults
  */
/**
  * Fonts
  */
/**
  * Font weight
  */
/**
  * Font line height
  */
/**
  *	Gutter
  */
/*
 *  Shadow
 */
.item-is-loading {
  width: 40px;
  height: 40px;
  position: relative;
  color: transparent !important;
  pointer-events: none;
}
.item-is-loading > * {
  display: none;
}
.item-is-loading::after {
  content: "";
  animation: spinAround 0.5s infinite linear;
  border: 2px solid;
  border-color: transparent transparent #92a0af #92a0af;
  border-radius: 290486px;
  display: block;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  position: absolute;
  left: 0;
  top: 0;
}
body {
  font-family: "Soleil", sans-serif;
}
ng-select.ng-select .ng-select-container, .field input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e5e9ed;
  color: #4c6c83;
  height: 40px;
  line-height: 20px;
  box-shadow: none;
  padding: 0 20px;
  font-size: 14px;
  transition: border-color 150ms ease;
  font-family: "Soleil", sans-serif;
}
ng-select.ng-select .ng-select-container:focus, .field input:focus {
  outline: none;
  box-shadow: none;
  border-color: #b8c3ce;
}
ng-select.ng-select [readonly].ng-select-container, .field input[readonly], ng-select.ng-select .ng-select-container:disabled, .field input:disabled {
  opacity: 0.5;
}
ng-select.ng-select .icon.ng-select-container, .field input.icon {
  padding-right: 40px;
}
ng-select.ng-select .is-invalid.ng-select-container, .field input.is-invalid {
  border: 1px solid #af0305;
}
.field .input-wrap.custom-label, .field .custom-label.input-wrap--overflow-link {
  display: flex;
  border: 1px solid #e5e9ed;
  border-radius: 5px;
  transition: border-color 150ms ease;
}
.field .input-wrap.custom-label:focus-within, .field .custom-label.input-wrap--overflow-link:focus-within {
  border-color: #b8c3ce;
}
.field .input-wrap.custom-label input, .field .custom-label.input-wrap--overflow-link input {
  border: 0;
}
.field .input-wrap.custom-label .label, .field .custom-label.input-wrap--overflow-link .label {
  display: block;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  background: #e7eaed;
  border-right: 1px solid #e5e9ed;
  padding: 0 20px;
  color: #92a0af;
  line-height: 38px;
  white-space: nowrap;
}
.field .input-wrap[label-right], .field [label-right].input-wrap--overflow-link {
  display: flex;
  border: 1px solid #e5e9ed;
  border-radius: 5px;
  transition: border-color 150ms ease;
}
.field .input-wrap[label-right]:focus-within, .field [label-right].input-wrap--overflow-link:focus-within {
  border-color: #b8c3ce;
}
.field .input-wrap[label-right] input, .field [label-right].input-wrap--overflow-link input {
  border: 0;
}
.field .input-wrap[label-right]:after, .field [label-right].input-wrap--overflow-link:after {
  content: attr(label);
  display: block;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  background: #e7eaed;
  border-right: 1px solid #e5e9ed;
  padding: 0 20px;
  color: #92a0af;
  line-height: 38px;
  white-space: nowrap;
}
.field .input-wrap[label], .field [label].input-wrap--overflow-link {
  display: flex;
  border: 1px solid #e5e9ed;
  border-radius: 5px;
  transition: border-color 150ms ease;
}
.field .input-wrap[label]:focus-within, .field [label].input-wrap--overflow-link:focus-within {
  border-color: #b8c3ce;
}
.field .input-wrap[label] input, .field [label].input-wrap--overflow-link input {
  border: 0;
}
.field .input-wrap[label]:before, .field [label].input-wrap--overflow-link:before {
  content: attr(label);
  display: block;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  background: #e7eaed;
  border-right: 1px solid #e5e9ed;
  padding: 0 20px;
  color: #92a0af;
  line-height: 38px;
  white-space: nowrap;
}
.field .input-wrap[phone], .field [phone].input-wrap--overflow-link {
  display: flex;
  border: 1px solid #e5e9ed;
  border-radius: 5px;
  transition: border-color 150ms ease;
}
.field .input-wrap[phone] input, .field [phone].input-wrap--overflow-link input {
  border: 0;
}
.field .input-wrap[phone] cbr-phone-select, .field [phone].input-wrap--overflow-link cbr-phone-select {
  border-right: 1px solid #e5e9ed;
  min-width: 55px;
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-select-container, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-select-container {
  border: 0;
  background: transparent;
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-dropdown-panel, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-dropdown-panel {
  width: 350px;
  background: #fff;
  border: 1px solid #e5e9ed;
  border-radius: 5px;
  overflow: hidden;
  top: calc(100% + 8px);
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-value, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-value {
  display: flex;
  align-items: center;
  justify-content: center;
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-value cbr-svg, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-value cbr-svg {
  display: block;
  position: static;
  height: 10px !important;
  margin-left: 5px;
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-dropdown-panel-items, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-dropdown-panel-items {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-clear-wrapper, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-clear-wrapper {
  text-align: center;
  display: inline-block;
  margin-left: 5px;
}
.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: #92a0af;
}
.field label {
  margin-bottom: 10px;
  font-weight: 700;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  color: #333333;
  position: relative;
  padding-right: 20px;
}
.field label .optional {
  margin-left: 5px;
}
.field .input-counter {
  color: #92a0af;
  position: absolute;
  font-weight: 400;
  right: 0;
  bottom: 0;
  transition: color 200ms ease;
}
.field .input-counter.is-invalid {
  color: #af0305;
}
.field .input-wrap, .field .input-wrap--overflow-link {
  position: relative;
  flex: 1 0;
  display: flex;
}
.field .input-wrap textarea:focus::-webkit-input-placeholder, .field .input-wrap--overflow-link textarea:focus::-webkit-input-placeholder, .field .input-wrap textarea:focus::-moz-placeholder, .field .input-wrap--overflow-link textarea:focus::-moz-placeholder, .field .input-wrap textarea:focus:-ms-input-placeholder, .field .input-wrap--overflow-link textarea:focus:-ms-input-placeholder, .field .input-wrap textarea:focus:-moz-placeholder, .field .input-wrap--overflow-link textarea:focus:-moz-placeholder, .field .input-wrap textarea:focus::placeholder, .field .input-wrap--overflow-link textarea:focus::placeholder,
.field .input-wrap input:focus::-webkit-input-placeholder,
.field .input-wrap--overflow-link input:focus::-webkit-input-placeholder,
.field .input-wrap input:focus::-moz-placeholder,
.field .input-wrap--overflow-link input:focus::-moz-placeholder,
.field .input-wrap input:focus:-ms-input-placeholder,
.field .input-wrap--overflow-link input:focus:-ms-input-placeholder,
.field .input-wrap input:focus:-moz-placeholder,
.field .input-wrap--overflow-link input:focus:-moz-placeholder,
.field .input-wrap input:focus::placeholder,
.field .input-wrap--overflow-link input:focus::placeholder {
  color: transparent;
}
.field .input-wrap.z-index-10, .field .z-index-10.input-wrap--overflow-link {
  z-index: 10;
}
.field .input-wrap.z-index-20, .field .z-index-20.input-wrap--overflow-link {
  z-index: 20;
}
.field .input-wrap.z-index-30, .field .z-index-30.input-wrap--overflow-link {
  z-index: 30;
}
.field .input-wrap.z-index-40, .field .z-index-40.input-wrap--overflow-link {
  z-index: 40;
}
.field .input-wrap.z-index-50, .field .z-index-50.input-wrap--overflow-link {
  z-index: 50;
}
.field .input-wrap.z-index-60, .field .z-index-60.input-wrap--overflow-link {
  z-index: 60;
}
.field .input-wrap.z-index-70, .field .z-index-70.input-wrap--overflow-link {
  z-index: 70;
}
.field .input-wrap.z-index-80, .field .z-index-80.input-wrap--overflow-link {
  z-index: 80;
}
.field .input-wrap.z-index-90, .field .z-index-90.input-wrap--overflow-link {
  z-index: 90;
}
.field .input-wrap.z-index-100, .field .z-index-100.input-wrap--overflow-link {
  z-index: 100;
}
.field .input-wrap ng-select, .field .input-wrap--overflow-link ng-select {
  width: 100%;
  position: relative;
}
.field .input-wrap cbr-svg, .field .input-wrap--overflow-link cbr-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.field .input-wrap .left, .field .input-wrap--overflow-link .left {
  left: 15px;
}
.field .input-wrap .right, .field .input-wrap--overflow-link .right {
  right: 15px;
}
.field .input-wrap cbr-range-slider, .field .input-wrap--overflow-link cbr-range-slider {
  width: 100%;
}
.field .input-wrap cbr-range-slider .range.no-label .range-custom-wrap, .field .input-wrap--overflow-link cbr-range-slider .range.no-label .range-custom-wrap {
  top: 11px;
}
.field .input-wrap cbr-range-slider .range.no-label .range-wrap input, .field .input-wrap--overflow-link cbr-range-slider .range.no-label .range-wrap input {
  height: 20px;
  line-height: 20px;
}
.field .input-wrap .ng-option cbr-svg, .field .input-wrap--overflow-link .ng-option cbr-svg {
  position: static;
  transform: translate(0, 0);
}
.field .input-wrap[label-right]:after, .field [label-right].input-wrap--overflow-link:after {
  content: attr(label-right);
  border-radius: 0 5px 5px 0;
  border: 0;
  border-left: 1px solid #e5e9ed;
}
.field .input-wrap.icon-left input, .field .icon-left.input-wrap--overflow-link input {
  padding-left: 40px;
}
.field .input-wrap.is-loading > cbr-svg, .field .is-loading.input-wrap--overflow-link > cbr-svg {
  display: none;
}
.field .input-wrap.is-loading::after, .field .is-loading.input-wrap--overflow-link::after {
  content: "";
  animation: spinAround 0.5s infinite linear;
  border: 2px solid #dbdbdb;
  border-color: transparent transparent #0087ef #0087ef !important;
  border-radius: 290486px;
  display: block;
  height: 1em;
  width: 1em;
  position: absolute;
  right: 15px;
  top: calc(50% - (1em / 1.6));
}
.field .input-wrap--overflow-link input {
  padding-right: 60px;
}
.field .input-wrap--overflow-link a {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.field textarea {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e5e9ed;
  color: #000;
  box-shadow: none;
  padding: 10px;
  font-size: 14px;
  transition: border-color 150ms ease;
  font-family: "Soleil", sans-serif;
  line-height: 24px;
  resize: none;
}
.field textarea:focus {
  outline: none;
  box-shadow: none;
  border-color: #b8c3ce;
}
.field textarea:disabled {
  opacity: 0.5;
}
.field input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  position: fixed;
  top: -5000px;
}
.field textarea:focus::-moz-placeholder, .field input:focus::-moz-placeholder {
  color: transparent;
}
.field textarea:focus::placeholder,
.field input:focus::placeholder {
  color: transparent;
}
.field .input-wrap--submit {
  display: flex;
  align-items: center;
}
.field .input-wrap--submit cbr-button {
  margin-left: 20px;
}
.input-checkbox {
  display: flex;
  align-items: center;
}
.input-checkbox input {
  display: none;
}
.input-checkbox .label-checkbox {
  font-weight: 400;
  flex: 1 0;
}
.input-checkbox .label-checkbox .header {
  font-weight: 700;
}
.input-checkbox .label-checkbox .description {
  color: #92a0af;
}
.input-checkbox .checkbox-toggle {
  border-radius: 12px;
  position: relative;
  background: #e7eaed;
  width: 42px;
  height: 24px;
  transition: background 200ms ease;
  cursor: pointer;
}
.input-checkbox .checkbox-toggle:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-95%);
  top: 3px;
  width: 18px;
  height: 18px;
  background: #fff;
  display: block;
  transition: transform 200ms ease;
}
.input-checkbox .checkbox-toggle.active {
  background: #27d07b;
}
.input-checkbox .checkbox-toggle.active:after {
  transform: translateX(-5%);
}
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}
.form-wrap {
  max-width: 600px;
}
.form-wrap fieldset {
  margin-bottom: 40px;
}
.cbr-input-errors,
cbr-input-errors {
  color: #721c24;
  font-size: 12px;
  margin: 5px 0;
}
.cbr-input-errors p,
cbr-input-errors p {
  margin: 0;
}
cbr-store-details ng-select.ng-select input {
  width: 100%;
  line-height: 24px;
  outline: none;
  padding: 5px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #e5e9ed;
}
cbr-store-details ng-select.ng-select .ng-dropdown-panel {
  border: 1px solid #e5e9ed;
  border-radius: 5px;
}
cbr-store-details ng-select.ng-select .ng-dropdown-panel-items {
  border: none;
}
.ribbon, cbr-button .button-label {
  background: #0087ef;
  color: #fff;
  border-radius: 2.5px 2.5px 0 2.5px;
}
.ribbon:after, cbr-button .button-label:after {
  border-left: 4px solid #0d47a1;
}
.ribbon, cbr-button .button-label {
  position: absolute;
  top: 5px;
  right: -4px;
  padding: 5px;
  line-height: 10px;
  font-size: 11px;
}
.ribbon:after, cbr-button .button-label:after {
  content: "";
  border-bottom: 4px solid transparent;
  position: absolute;
  top: 20px;
  right: 0;
}
/**
 * $bp-giga:  2500px;
 * $bp-mega:  1900px;
 * $bp-kilo:  1360px;
 * $bp-centi: 1024px;
 * $bp-milli:  768px;
 * $bp-nano:   480px;
*/
.stat-grid, .budget-buttons {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-left: -20px;
}
.disclaimer, .disclaimer-info, .disclaimer-alert, .disclaimer-warning {
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}
.disclaimer-warning {
  background-color: #ffe0b2;
  color: #e6511c;
  border: 1px solid #ffe0b2;
}
.disclaimer-alert {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
.disclaimer-info {
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  background: #cce5ff;
  color: #004085;
  border: 1px solid #b8daff;
}
cbr-arrow-buttons {
  min-width: 64px;
}
cbr-arrow-buttons .arrow-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
cbr-arrow-buttons .arrow-buttons-container .arrow-icon-container {
  border: 1px solid #0087ef;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
cbr-arrow-buttons .arrow-buttons-container .arrow-icon-container.disabled {
  opacity: 0.6;
  cursor: default;
}
cbr-arrow-buttons .arrow-buttons-container .arrow-icon-container:first-child {
  margin-right: 10px;
}
cbr-button {
  display: block;
}
cbr-button button {
  font-family: "Soleil", sans-serif;
  padding: 8px 16px;
  background: #0087ef;
  color: #fff;
  border: 1px solid #0087ef;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: background 200ms ease, border-color 200ms ease;
  cursor: pointer;
}
cbr-button button:focus {
  outline: none;
  box-shadow: none;
}
cbr-button button:hover {
  background: #0090ff;
  border-color: #0090ff;
}
cbr-button button[disabled] {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
cbr-button button.stretch {
  width: 100%;
}
cbr-button button.style-primary .ribbon, cbr-button button.style-primary .button-label {
  background-color: white;
  color: #0087ef;
}
cbr-button button.style-secondary {
  background: white;
  border: 1px solid #e3e3e3;
  color: #0087ef;
}
cbr-button button.style-secondary:hover {
  background: rgba(0, 135, 239, 0.1);
  border: 1px solid rgba(0, 135, 239, 0.2);
}
cbr-button button.style-secondary.is-loading:after {
  border-color: transparent transparent #92a0af #92a0af !important;
}
cbr-button button.style-secondary.accent-error {
  border-color: #ef0000;
  color: #ef0000;
}
cbr-button button.style-secondary.accent-error.is-loading:after {
  border-color: transparent transparent #ef0000 #ef0000 !important;
}
cbr-button button.style-outline {
  background: transparent;
  border: 1px solid #0087ef;
  color: #0087ef;
}
cbr-button button.style-outline.active {
  background: rgba(0, 135, 239, 0.1);
}
cbr-button button.style-outline.is-loading:after {
  border-color: transparent transparent #0087ef #0087ef !important;
}
cbr-button button.style-outline.accent-error {
  border-color: #ef0000;
  color: #ef0000;
}
cbr-button button.style-outline.accent-error.is-loading:after {
  border-color: transparent transparent #ef0000 #ef0000 !important;
}
cbr-button button.style-silent {
  background: #e7eaed;
  border: 1px solid #e7eaed;
  color: #4c6c83;
}
cbr-button button.style-silent.is-loading:after {
  border-color: transparent transparent #92a0af #92a0af !important;
}
cbr-button button.style-transparent {
  background: transparent;
  border: none;
  color: #4c6c83;
}
cbr-button button.style-transparent:hover {
  color: #0087ef;
  background: rgba(0, 135, 239, 0.1);
}
cbr-button button.style-transparent.is-loading:after {
  border-color: transparent transparent #92a0af #92a0af !important;
}
cbr-button button.style-transparent.accent-error {
  color: #ef0000;
}
cbr-button button.style-warning {
  background: #ffe0b2;
  border: 1px solid #e6511c;
  color: #e6511c;
}
cbr-button button.style-warning:hover {
  background: #ffe2b7;
  border: 1px solid #e75521;
}
cbr-button button.style-warning.is-loading:after {
  border-color: transparent transparent #e6511c #e6511c !important;
}
cbr-button button.style-warning.accent-error {
  border-color: #ef0000;
  color: #ef0000;
}
cbr-button button.style-warning.accent-error.is-loading:after {
  border-color: transparent transparent #ef0000 #ef0000 !important;
}
cbr-button button.style-alert {
  background: #f8d7da;
  border: 1px solid #721c24;
  color: #721c24;
}
cbr-button button.style-alert:hover {
  background: #f9dbde;
  border: 1px solid #761d25;
}
cbr-button button.style-alert.is-loading:after {
  border-color: transparent transparent #721c24 #721c24 !important;
}
cbr-button button.style-alert.accent-error {
  border-color: #ef0000;
  color: #ef0000;
}
cbr-button button.style-alert.accent-error.is-loading:after {
  border-color: transparent transparent #ef0000 #ef0000 !important;
}
cbr-button button.size-small {
  padding: 0 20px;
  height: 32px;
  line-height: 30px;
  font-size: 12px;
}
cbr-button button.size-small.is-loading::after {
  border: 2px solid #dbdbdb;
  height: 12px;
  width: 12px;
  left: calc(50% - 8px);
  top: calc(50% - 8px);
}
cbr-button button.size-large {
  padding: 0 20px;
  height: 80px;
  line-height: 80px;
}
cbr-button button.size-large.is-loading::after {
  border: 2px solid #dbdbdb;
  height: 20px;
  width: 20px;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
}
cbr-button button.size-mobile-large {
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
@media only screen and (max-width: 768px) {
  cbr-button button.size-mobile-large {
    padding: 16px;
    width: 100%;
  }
}
cbr-button button.type-icon {
  padding: 4px 4px;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
cbr-button button.type-icon-text {
  padding: 0 8px;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
}
cbr-button button.type-icon-text .btn-content {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto;
}
cbr-button button.type-squared {
  border-radius: 0 !important;
}
cbr-button button.is-loading {
  color: transparent !important;
  pointer-events: none;
}
cbr-button button.is-loading > * {
  opacity: 0;
}
cbr-button button.is-loading::after {
  content: "";
  animation: spinAround 0.5s infinite linear;
  border: 2px solid #dbdbdb;
  border-color: transparent transparent #fff #fff !important;
  border-radius: 100%;
  display: block;
  height: 16px;
  width: 16px;
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 10px);
}
cbr-button button .btn-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
cbr-chip .chip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding-left: 10px;
  border-radius: 5px;
}
cbr-chip .chip p {
  margin: 0;
  margin-right: 10px;
  line-height: 14px;
}
cbr-chip .chip-action {
  align-self: stretch;
  width: 24px;
  padding-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
cbr-chip .chip-action .item-is-loading {
  width: 11px;
  height: 11px;
}
cbr-chip .chip-action .item-is-loading:after {
  border-color: transparent transparent white white;
}
cbr-chip .chip-default {
  background-color: #0087ef;
}
cbr-chip .chip-default p {
  color: white;
}
cbr-chip .chip-warn {
  background-color: #ffe0b2;
}
cbr-chip .chip-warn p {
  color: #e6511c;
}
cbr-chip .chip-error {
  background-color: #f8d7da;
}
cbr-chip .chip-error p {
  color: #721c24;
}
cbr-chip .chip-success {
  background-color: #d4edda;
}
cbr-chip .chip-success p {
  color: #155724;
}
cbr-counter {
  display: block;
  height: 80px;
  width: 100%;
}
cbr-counter .title {
  font-weight: normal;
}
cbr-counter .total {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
cbr-counter .slider {
  position: relative;
  height: 5px;
  width: 100%;
}
cbr-counter .slider div:not(.indicator) {
  position: relative;
  display: inline-block;
  height: 100%;
  float: left;
}
cbr-counter .slider div:not(.indicator):first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
cbr-counter .slider div:not(.indicator):first-child span {
  transform: translateX(0);
}
cbr-counter .slider div:not(.indicator):last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
cbr-counter .slider div:not(.indicator) span {
  position: absolute;
  left: 0;
  top: 15px;
  transform: translateX(-50%);
  opacity: 0.5;
}
cbr-counter .slider div:not(.indicator) span.last {
  left: auto;
  right: 0;
  transform: translateX(0);
}
cbr-counter .slider .low {
  background-color: #ff1536;
}
cbr-counter .slider .medium {
  background-color: #fcdc2b;
}
cbr-counter .slider .high {
  background-color: #27d07b;
}
cbr-counter .slider .indicator {
  background-color: #002d4f;
  height: auto;
  width: 5px;
  position: absolute;
  border-radius: 100px;
  left: 0;
  top: -8px;
  bottom: -8px;
  z-index: 10;
  transition: left 0.75s linear;
}
cbr-dropdown {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 50000;
}
cbr-dropdown a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
cbr-dropdown a:hover {
  background-color: #ddd;
}
.gauge-meter-container {
  position: relative;
}
.gauge-meter-icon {
  position: absolute;
  left: 0;
  bottom: 20px;
}
.gauge-pin-icon {
  position: absolute;
  bottom: 20px;
  left: 33%;
  transform-origin: 50% 90%;
  transition-property: all;
  transition-duration: 1s;
}
.gauge-meter-numbers {
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.gauge-meter-numbers p {
  margin: 0;
}
cbr-input-errors {
  color: #af0305;
  font-size: 12px;
  margin: 5px 0;
}
cbr-input-errors span {
  margin: 0;
  display: flex;
  font-size: 12px;
}
cbr-input-errors span cbr-svg {
  margin-right: 5px;
}
cbr-input-errors .is-danger {
  color: #af0305;
}
cbr-calendar {
  width: 100%;
}
cbr-calendar .calendar-input {
  width: 100%;
  height: 44px;
  border-radius: 5px;
  border: 1px solid #cfd5db;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: relative;
}
cbr-calendar .calendar-input.active, cbr-calendar .calendar-input:focus {
  border-color: #0087ef;
  outline: none;
}
cbr-calendar .calendar-input.active .calendar-dropdown, cbr-calendar .calendar-input:focus .calendar-dropdown {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}
cbr-calendar .calendar-dropdown {
  display: flex;
  flex-direction: column;
  padding: 5px;
  background: #fff;
  box-shadow: 0 3px 20px rgba(208, 230, 244, 0.5);
  border-radius: 5px;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  z-index: 500;
}
cbr-calendar .calendar-dropdown-section {
  display: flex;
}
cbr-calendar .calendar-dropdown-month {
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: 5px;
}
cbr-calendar .calendar-dropdown-header {
  flex: 1 0;
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
}
cbr-calendar .calendar-dropdown-header-text {
  flex: 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
cbr-calendar .calendar-dropdown-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
cbr-calendar .calendar-dropdown-arrow.next {
  left: auto;
  right: 0;
}
cbr-calendar .calendar-dropdown-arrow:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
cbr-calendar .week-row {
  display: flex;
  width: 100%;
}
cbr-calendar .date-cell,
cbr-calendar .weekday-cell {
  width: calc(100% / 7);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}
cbr-calendar .date-cell {
  cursor: pointer;
  position: relative;
  color: #92a0af;
}
cbr-calendar .date-cell:after {
  content: attr(date);
  position: relative;
  z-index: 10;
}
cbr-calendar .date-cell:before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 100%;
  position: absolute;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
cbr-calendar .date-cell:hover {
  color: #fff;
}
cbr-calendar .date-cell:hover:before {
  background-color: #0090ff;
}
cbr-calendar .date-cell.active {
  color: #fff;
}
cbr-calendar .date-cell.active:before {
  background-color: #0087ef;
  transition: background-color 175ms ease, border-radius 175ms ease;
  will-change: background-color, border-radius;
}
cbr-calendar .date-cell.active:hover:before {
  background-color: #0090ff;
}
cbr-calendar .date-cell.active.start-date:before {
  border-radius: 100% 0 0 100%;
}
cbr-calendar .date-cell.active.end-date:before {
  border-radius: 0 100% 100% 0;
}
cbr-calendar .date-cell.before-today {
  cursor: initial;
  color: #e7eaed;
}
cbr-calendar .date-cell.before-today:before {
  display: none;
}
cbr-calendar .date-cell.not-this-month {
  cursor: initial;
  opacity: 0;
}
cbr-calendar .date-cell.between:after {
  color: #fff;
}
cbr-calendar .date-cell.between:before {
  background-color: #0087ef;
  border-radius: 0;
}
cbr-calendar .date-cell.between:hover:before {
  border-radius: 0;
  background-color: #0090ff;
}
cbr-calendar .weekday-cell {
  color: #cfd5db;
}
cbr-checkbox .field {
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
cbr-checkbox .field label {
  margin: 0 0 0 10px;
  font-weight: 400;
}
cbr-checkbox .checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #e7eaed;
  border-radius: 2px;
  background-color: transparent;
  position: relative;
  transition: border-color 200ms ease, background-color 200ms ease;
}
cbr-checkbox .checkbox.active {
  border-color: #0087ef;
  background-color: #0087ef;
}
cbr-checkbox .checkmark {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 13px;
  height: 16px;
  margin-top: 2px;
  border: 0;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 300ms ease;
  background: transparent;
  transition-delay: 100ms;
  transform: translate3d(-50%, -50%, 0) scale(0.8);
}
cbr-checkbox .checkbox.active .checkmark {
  stroke-dashoffset: 0;
}
cbr-checkbox .tick-icon {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
cbr-loader {
  width: 100%;
}
cbr-loader .bar-loader {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  position: relative;
  background-color: #cfd5db;
}
cbr-loader .bar {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  position: absolute;
}
cbr-loader .bar:nth-child(1) {
  background: linear-gradient(to right, #0087ef, #0087ef);
  background-repeat: repeat-y;
  animation: infiniteLoader 2s ease-in-out infinite;
}
cbr-email {
  width: 100%;
  display: block;
}
cbr-email.no-space .field {
  margin-bottom: 0;
}
cbr-number {
  width: 100%;
}
.number-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.number-container .number-button {
  border-radius: 5px;
  border: #0087ef solid 1px;
  width: 40px;
  height: 40px;
  color: #0087ef;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  padding: 0;
  outline: none;
  background: none;
}
.number-container .plus {
  margin-left: 5px;
}
.number-container .minus {
  margin-right: 5px;
}
.number-container .path-plus,
.number-container .path-minus {
  stroke: #0087ef;
  stroke-width: 2px;
}
.number-container .number-box {
  flex-grow: 100;
}
.text-center {
  text-align: center;
}
cbr-progress-bar {
  width: 100%;
  position: relative;
}
cbr-progress-bar .progress-bar-container {
  width: 100%;
  height: 7px;
  background-color: #ebebeb;
}
cbr-progress-bar .progress-bar {
  position: relative;
  height: 100%;
  background-color: #0087ef;
}
cbr-progress-bar .rounded {
  border-radius: 10px;
}
cbr-progress-bar .progress-bar-icon {
  position: absolute;
  top: -7px;
  right: -7px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #0087ef;
}
.budget-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.budget-content .budget-label {
  margin-bottom: 10px;
  font-weight: 700;
  color: #002d4f;
  width: 100%;
}
.budget-buttons {
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
@media only screen and (max-width: 768px) {
  .budget-buttons {
    width: auto;
  }
}
.budget-buttons > cbr-button {
  width: calc(((100% / 2) * 1) - 20px);
  margin-left: 20px;
  margin-bottom: 20px;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
.budget-buttons > cbr-button ::ng-deep .btn-content {
  font-size: 24px;
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  .budget-buttons > cbr-button {
    width: calc(((100% / 2) * 1) - 20px);
    margin-left: 20px;
    margin-bottom: 20px;
  }
}
.custom-budget {
  width: 100%;
  margin-top: 10px;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
@media only screen and (max-width: 768px) {
  .custom-budget {
    min-height: 100px;
  }
}
.custom-budget .custom-budget-toggle {
  width: 100%;
  text-align: center;
  color: #0087ef;
  text-decoration: underline;
  transition: color 200ms linear, opacity 200ms linear;
  opacity: 1;
  cursor: pointer;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
@media only screen and (max-width: 768px) {
  .custom-budget .custom-budget-toggle {
    margin-top: 10px;
  }
}
.custom-budget .custom-budget-toggle:hover {
  color: #d2e8fc;
}
.custom-budget .custom-budget-toggle.hidden {
  display: none;
}
.custom-budget .hidden {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: none;
}
cbr-svg {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
cbr-svg .svg-bg {
  display: flex;
  justify-content: center;
  align-items: center;
}
cbr-svg .bg-app {
  background: #e7eaed;
  border-radius: 10px;
}
cbr-range-slider .range {
  position: relative;
}
cbr-range-slider .range-wrap {
  opacity: 0;
  position: relative;
  z-index: 10;
}
cbr-range-slider .range-custom-wrap {
  position: absolute;
  z-index: 0;
  top: 27px;
  left: 0;
  bottom: 0;
  right: 0;
}
cbr-range-slider .track {
  height: 5px;
  width: 100%;
  border-radius: 5px;
  background: #e7eaed;
  overflow: hidden;
  z-index: 1;
  position: absolute;
  top: 0;
}
cbr-range-slider .track .fill {
  height: 100%;
  width: 0%;
  background: #0087ef;
}
cbr-range-slider .thumb {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #fff;
  border: 2px solid #0087ef;
  position: absolute;
  top: -8px;
  z-index: 5;
  transform: translateX(-50%);
}
cbr-range-slider .thumb-content {
  background: #0087ef;
  width: 32px;
  height: 32px;
  top: -14px;
}
cbr-range-slider .thumb-content .thumb-content-text {
  color: #fff;
  font-size: 12px;
  text-align: center;
  position: relative;
  font-weight: 600;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}
cbr-range-slider .label {
  position: relative;
  bottom: 25px;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
}
cbr-range-slider .label.is-max > span {
  transform: translateX(calc(-100% + 10px));
  text-align: right;
}
cbr-range-slider .label > span {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  display: block;
  width: 200px;
  text-align: center;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font-weight: 800;
}
cbr-range-slider .label .label-unit {
  font-size: 16px;
}
cbr-disclaimer cbr-button {
  margin-left: 10px;
}
cbr-disclaimer .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  margin-bottom: 0;
}
cbr-sidebar-helper .sidebar-helper {
  width: 340px;
  border-radius: 5px;
  box-shadow: 0 0 5px #0000001a;
  background-color: white;
}
cbr-sidebar-helper .sidebar-helper-header {
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 20px;
  padding-bottom: 32px;
  position: relative;
}
cbr-sidebar-helper .sidebar-helper-header.primary {
  background: transparent linear-gradient(180deg, #004bd5 0%, #62c9ff 100%) 0% 0% no-repeat padding-box;
}
cbr-sidebar-helper .sidebar-helper-header-left {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
cbr-sidebar-helper .sidebar-helper-header-right {
  display: flex;
  flex-grow: 0;
  align-items: center;
  margin-left: 10px;
}
cbr-sidebar-helper .sidebar-helper-header-title {
  margin: 0;
  font-size: 18px;
  color: white;
  font-weight: 600;
}
cbr-sidebar-helper .sidebar-helper-header-subtitle {
  margin: 0;
  font-size: 14px;
  color: white;
  font-weight: 300;
}
cbr-sidebar-helper .sidebar-helper-header-image {
  max-width: 56px;
  max-height: 56px;
}
cbr-sidebar-helper .sidebar-helper-header-wave {
  position: absolute;
  bottom: -10px;
  left: 0;
}
cbr-sidebar-helper .sidebar-helper-body {
  padding: 20px;
}
cbr-nav-sidebar .nav-sidebar {
  position: relative;
  width: 92px;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-right: 2px;
  height: 100%;
  overflow: hidden;
  transition: width 0.25s ease-in-out;
}
cbr-nav-sidebar .nav-sidebar.expanded {
  width: 190px;
}
cbr-nav-sidebar .nav-sidebar-menu {
  background-color: white;
}
cbr-nav-sidebar .nav-sidebar-menu .nav-sidebar-item {
  margin-top: 20px;
}
cbr-nav-sidebar .nav-sidebar-item {
  position: relative;
  padding: 20px 0 20px 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 30px;
}
cbr-nav-sidebar .nav-sidebar-item:hover:not(.nav-sidebar-menu) cbr-svg svg {
  transition: fill 0.3s ease-in-out;
  fill: #0087ef !important;
}
cbr-nav-sidebar .nav-sidebar-item:hover:not(.nav-sidebar-menu) .nav-sidebar-item-title {
  transition: color 0.3s ease-in-out;
  color: #0087ef;
}
cbr-nav-sidebar .nav-sidebar-item.active:before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 2px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #0087ef;
}
cbr-nav-sidebar .nav-sidebar-item cbr-svg {
  height: 24px;
}
cbr-nav-sidebar .nav-sidebar-items {
  flex-grow: 1;
  overflow-y: auto;
}
cbr-nav-sidebar .nav-sidebar-item-title {
  font-size: 14px;
  font-weight: 600;
  color: #b6c3cd;
  margin: 0 0 0 16px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  left: -16px;
  transition: opacity 0.25s ease-in-out, left 0.25s ease-in-out;
}
cbr-nav-sidebar .nav-sidebar-item-title.expanded {
  opacity: 1;
  left: 0;
}
cbr-nav-sidebar .nav-sidebar-item-title.active {
  color: #0087ef;
}
cbr-nav-sidebar .nav-sidebar-footer .nav-sidebar-item {
  margin-bottom: 20px;
}
.nav-bottombar {
  display: flex;
  flex-direction: row;
  background-color: white;
}
.nav-bottombar-item {
  padding: 8px;
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.nav-bottombar-item cbr-svg {
  height: 20px;
  margin-bottom: 4px;
}
.nav-bottombar-item .nav-bottombar-item-title {
  font-size: 12px;
  font-weight: 600;
  color: #4c6c83;
  opacity: 0.6;
  white-space: nowrap;
  overflow: hidden;
}
.nav-bottombar-item .nav-bottombar-item-title.active {
  color: #0087ef;
  opacity: 1;
}
cbr-nav-topbar {
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-nav-topbar .nav-topbar {
  width: 100%;
  box-sizing: border-box;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  background-color: white;
}
cbr-nav-topbar .nav-topbar-left {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
cbr-nav-topbar .nav-topbar-right {
  display: flex;
  align-items: center;
  flex-grow: 0;
  position: relative;
  cursor: pointer;
}
cbr-nav-topbar .nav-topbar-right:hover .nav-topbar-dropdown {
  opacity: 1;
  visibility: visible;
}
cbr-nav-topbar .nav-topbar-dropdown {
  visibility: hidden;
  opacity: 0;
  transition: visibility 200ms linear, opacity 200ms linear;
  position: absolute;
  top: 100%;
  right: 0;
}
cbr-nav-topbar [slot=topbar-right-title] {
  display: flex;
  color: #002d4f;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}
cbr-nav-topbar [slot=topbar-right-dropdown] {
  position: absolute;
  top: calc(100% - 20px);
  right: 0;
  z-index: 99;
}
@media only screen and (max-width: 768px) {
  cbr-nav-topbar .nav-topbar {
    padding: 8px 24px;
    height: 48px;
  }
  cbr-nav-topbar .nav-topbar-right {
    position: absolute;
    right: 24px;
  }
  cbr-nav-topbar .nav-topbar-left {
    justify-content: center;
  }
}
cbr-verification-number .verification-number-container {
  display: flex;
  justify-content: space-between;
}
cbr-verification-number .field {
  width: auto;
  margin: 0;
}
cbr-verification-number input.verification-number-input {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  width: 60px;
  height: 80px;
  padding: 0;
}
cbr-app-recommendation-list-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dbe2ed;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-app-recommendation-list-item .app-recommendation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
}
cbr-app-recommendation-list-item .app-recommendation-item-content {
  flex: 1;
}
cbr-app-recommendation-list-item .app-recommendation-item-content h1,
cbr-app-recommendation-list-item .app-recommendation-item-content h2,
cbr-app-recommendation-list-item .app-recommendation-item-content h3,
cbr-app-recommendation-list-item .app-recommendation-item-content h4,
cbr-app-recommendation-list-item .app-recommendation-item-content h5,
cbr-app-recommendation-list-item .app-recommendation-item-content h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #002d4f;
}
cbr-app-recommendation-list-item .app-recommendation-item-content p, cbr-app-recommendation-list-item .app-recommendation-item-content span {
  margin: 0;
  color: #4c6c83;
  font-weight: 300;
}
cbr-app-recommendation-list-item .app-recommendation-list-item-controls {
  display: flex;
}
cbr-app-recommendation-list-item [slot=icon] {
  margin-right: 16px;
}
cbr-app-recommendation-list-item cbr-button {
  margin-left: 8px;
  width: 120px;
}
@media only screen and (max-width: 768px) {
  cbr-app-recommendation-list-item cbr-button {
    width: 64px;
  }
  cbr-app-recommendation-list-item .app-recommendation-item-content h1,
cbr-app-recommendation-list-item .app-recommendation-item-content h2,
cbr-app-recommendation-list-item .app-recommendation-item-content h3,
cbr-app-recommendation-list-item .app-recommendation-item-content h4,
cbr-app-recommendation-list-item .app-recommendation-item-content h5,
cbr-app-recommendation-list-item .app-recommendation-item-content h6 {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #002d4f;
  }
  cbr-app-recommendation-list-item .app-recommendation-row {
    margin: 32px 0 24px 0;
  }
  cbr-app-recommendation-list-item [slot=mobile-description] {
    display: block;
    color: #4c6c83;
    font-weight: 300;
    margin: 0 0 32px 0;
  }
  cbr-app-recommendation-list-item [slot=icon] {
    margin-right: 8px;
    width: 32px;
    height: 32px;
  }
}
cbr-app-card {
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-app-card .app-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background: #fff;
  border-radius: 5px;
}
cbr-app-card .app-info {
  padding: 80px 60px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
cbr-app-card [slot=icon] {
  margin-bottom: 20px;
  width: 48px;
  height: 48px;
}
cbr-app-card h1,
cbr-app-card h2,
cbr-app-card h3,
cbr-app-card h4,
cbr-app-card h5,
cbr-app-card h6 {
  font-size: 32px;
  font-weight: 600;
  color: #002d4f;
  margin-top: 0;
  margin-bottom: 20px;
}
cbr-app-card p {
  font-weight: 300;
  color: #4c6c83;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 40px;
}
cbr-app-card .app-content {
  display: flex;
  flex: 0 1 490px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
}
cbr-app-card .app-content img {
  position: absolute;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  right: 0;
  top: 0;
  bottom: 0;
}
cbr-app-card .app-button {
  width: 200px;
}
@media only screen and (max-width: 768px) {
  cbr-app-card .app-container {
    flex-direction: column-reverse;
  }
  cbr-app-card .app-info {
    padding: 32px 24px;
  }
  cbr-app-card .app-content {
    border-radius: 5px 5px 0 0;
    flex: 0 1 100%;
    min-height: 160px;
  }
  cbr-app-card .app-button {
    width: 100%;
  }
  cbr-app-card [slot=icon] {
    margin-bottom: 16px;
    width: 32px;
    height: 32px;
  }
  cbr-app-card h1,
cbr-app-card h2,
cbr-app-card h3,
cbr-app-card h4,
cbr-app-card h5,
cbr-app-card h6 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  cbr-app-card p {
    font-size: 14px;
    margin-bottom: 32px;
  }
}
cbr-onboarding-card {
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-onboarding-card .onboarding-card-info {
  padding: 60px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}
cbr-onboarding-card .onboarding-card-footer {
  display: flex;
  flex-direction: column;
}
cbr-onboarding-card h1,
cbr-onboarding-card h2,
cbr-onboarding-card h3,
cbr-onboarding-card h4,
cbr-onboarding-card h5,
cbr-onboarding-card h6 {
  font-size: 24px;
  font-weight: 600;
  color: #002d4f;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}
cbr-onboarding-card p {
  font-weight: 300;
  color: #4c6c83;
  font-size: 14px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}
cbr-onboarding-card img {
  -o-object-fit: cover;
     object-fit: cover;
  max-height: 200px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
@media only screen and (max-width: 768px) {
  cbr-onboarding-card img {
    max-height: initial;
    max-width: 100%;
  }
  cbr-onboarding-card .onboarding-card-info {
    padding: 20px;
  }
}
cbr-datatable {
  margin: 0;
  padding: 0 40px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow: auto;
  min-width: 0;
  min-height: 0;
}
cbr-datatable .fluid-table-content-wrap {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: -moz-max-content;
  min-width: max-content;
  padding-bottom: 40px;
}
cbr-datatable .fluid-table-content-wrap-inner {
  display: block;
}
cbr-datatable table {
  margin: 0;
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  display: table;
}
cbr-datatable th {
  position: sticky;
  top: 0;
  z-index: 10;
}
cbr-datatable-row {
  display: table-row;
}
cbr-datatable-cell {
  white-space: nowrap;
  padding: 10px 20px;
  display: table-cell;
  vertical-align: middle;
  border-bottom: 1px solid #e7eaed;
}
cbr-datatable-header {
  display: table-row-group;
}
cbr-datatable-header-cell {
  position: sticky;
  vertical-align: middle;
  white-space: nowrap;
  text-align: left;
  padding: 0;
  display: table-cell;
  top: 0;
  z-index: 10;
  border-bottom: 1px solid #cfd5db;
  background-color: #f4f9fc;
}
cbr-datatable-header-cell .th-text {
  padding: 10px 20px;
  display: block;
}
@keyframes moveOutLeft {
  0% {
    max-height: 600px;
    opacity: 1;
  }
  99% {
    opacity: 0;
    transform: translateX(-508px);
  }
  100% {
    max-height: 0px;
  }
}
@keyframes moveOutRight {
  0% {
    max-height: 600px;
    opacity: 1;
  }
  99% {
    opacity: 0;
    transform: translateX(508px);
  }
  100% {
    max-height: 0px;
  }
}
.form-wrapper {
  border-radius: 5px;
  display: flex;
  background-color: #fff;
  width: 1020px;
  padding: 40px;
}
.form-wrapper h2,
.form-wrapper h4 {
  font-weight: normal;
  color: #002d4f;
  margin: 0;
}
.form-wrapper p {
  font-weight: 300;
  color: #4c6c83;
  font-size: 14px;
  margin: 0;
}
.form-wrapper b {
  font-size: 16px;
  font-weight: normal;
  color: #002d4f;
}
.form-wrapper .form-container {
  border-radius: 5px 0 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 40px;
  width: 590px;
}
.form-wrapper .form-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
.form-wrapper .form-container p {
  font-size: 14px;
}
.form-wrapper .form-container .form-content-wrapper {
  display: flex;
  overflow: hidden;
}
.form-wrapper .form-container .form-content-wrapper .billing-info-form {
  margin-bottom: 40px;
  flex: 1 0 100%;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  height: auto;
  max-height: 600px;
}
.form-wrapper .form-container .form-content-wrapper .payment-form {
  margin-bottom: 40px;
  opacity: 0;
  flex: 1 0 100%;
  transition: all 500ms;
  animation: moveOutRight 500ms;
}
.form-wrapper .form-container .next-page {
  height: auto;
}
.form-wrapper .form-container .next-page .billing-info-form {
  animation: moveOutLeft 500ms;
  opacity: 0;
  max-height: 0;
}
.form-wrapper .form-container .next-page .payment-form {
  transform: translateX(-508px);
  opacity: 1;
}
.form-wrapper .product-price-container {
  padding: 40px 0;
  border-top: 1px solid #e5e9ed;
}
.form-wrapper .product-price-container .product-price {
  display: flex;
  justify-content: space-between;
}
.form-wrapper .product-price-container .product-price:not(:last-child) {
  margin-bottom: 10px;
}
.form-wrapper .total {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}
.form-wrapper .secure-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.form-wrapper .secure-info-container img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.form-wrapper .secure-info-container p {
  font-size: 14px;
}
.form-wrapper .form-info-container {
  flex: 0 1;
  border: 1px solid #e5e9ed;
  border-radius: 5px;
  background-color: #fcfcfc;
  padding: 40px;
  position: relative;
}
.form-wrapper .form-info-container .form-info {
  width: 310px;
  overflow: scroll;
}
.form-wrapper .form-info-container .form-info::-webkit-scrollbar {
  display: none;
}
.form-wrapper .form-info-container .form-info .legal-info {
  margin-bottom: 40px;
}
.form-wrapper .form-info-container .product-info-container {
  display: flex;
  padding-bottom: 20px;
}
.form-wrapper .form-info-container .product-info-container .logo-container {
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 20px;
}
.form-wrapper .form-info-container .product-info-container .logo-container img {
  width: 100%;
  border-radius: 8px;
}
.form-wrapper .form-info-container .product-info-container h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.form-wrapper .form-info-container .included-container {
  margin: 60px 0 20px 0;
}
.form-wrapper .form-info-container .included-container h4 {
  margin-bottom: 20px;
  font-weight: 600;
}
.form-wrapper .form-info-container .included-container ul {
  padding: 0;
}
.form-wrapper .form-info-container .included-container ul li {
  display: flex;
  list-style: none;
  margin-bottom: 8px;
}
.form-wrapper .form-info-container .included-container ul li cbr-svg {
  margin-right: 10px;
}
.form-wrapper .form-info-container .partner-container {
  margin-top: 60px;
}
.form-wrapper .form-info-container .partner-container h4 {
  margin-bottom: 20px;
  font-weight: 600;
}
.form-wrapper .form-info-container .partner-container .partner-image-container img {
  height: 40px;
  border: 1px solid #98aab8;
  margin-right: 10px;
  border-radius: 5px;
}
.no-form {
  padding: 0;
  width: 392px;
}
.no-form .form-info-container {
  background-color: #fff;
}
.no-form .form-info-container .form-info {
  height: 500px;
}
.no-form .product-price-container {
  padding-bottom: 0;
}
.no-form .partner-container {
  margin-bottom: 150px;
}
.no-form .payment-button {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-top: 1px solid #e5e9ed;
  padding: 40px;
}
/**
 * $bp-giga:  2500px;
 * $bp-mega:  1900px;
 * $bp-kilo:  1360px;
 * $bp-centi: 1024px;
 * $bp-milli:  768px;
 * $bp-nano:   480px;
*/
@media only screen and (max-width: 768px) {
  .form-wrapper {
    width: 100%;
    padding: 16px;
    flex-direction: column-reverse;
  }
  .form-wrapper .form-container {
    width: 100%;
    padding: 0;
    margin-top: 16px;
  }
  .form-wrapper .form-info-container {
    padding: 16px;
  }
  .form-wrapper .form-info-container .form-info {
    width: 100%;
  }

  .no-form {
    padding: 0;
    width: 100%;
  }
  .no-form .form-info-container .form-info {
    height: 100%;
  }
  .no-form .form-info-container .included-container {
    margin: 48px 0;
  }
  .no-form .form-info-container .product-price-container {
    margin-bottom: 32px;
  }
  .no-form .form-info-container .payment-button {
    position: inherit;
    width: 100%;
    padding: 0px;
    border-top: none;
  }
}
cbr-hero {
  display: flex;
  padding: 40px;
  background-color: white;
  align-items: center;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-hero [slot=thumbnail] {
  max-width: 240px;
  height: 160px;
}
cbr-hero [slot=thumbnail][small] {
  max-width: 48px;
  height: auto;
}
cbr-hero [slot=thumbnail][medium] {
  max-width: 80px;
  height: auto;
}
cbr-hero [slot=thumbnail][large] {
  max-width: 160px;
  height: auto;
}
cbr-hero section {
  flex: 1 1;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  min-width: 0;
}
cbr-hero h1,
cbr-hero h2,
cbr-hero h3,
cbr-hero h4,
cbr-hero h5,
cbr-hero h6 {
  font-size: 32px;
  font-weight: 600;
  color: #002d4f;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
cbr-hero p {
  font-weight: 300;
  max-width: 450px;
  line-height: 20px;
  max-height: 60px;
  margin: 5px 0 0 0;
  color: #4c6c83;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 768px) {
  cbr-hero {
    padding: 32px 24px;
    height: auto;
  }
  cbr-hero [slot=thumbnail] {
    width: auto;
    max-height: 60px;
    border-radius: 5px;
  }
  cbr-hero h1,
cbr-hero h2,
cbr-hero h3,
cbr-hero h4,
cbr-hero h5,
cbr-hero h6 {
    font-size: 16px;
  }
  cbr-hero p {
    margin-top: 8px;
  }
  cbr-hero section {
    margin-left: 16px;
  }
}
.snackbar-item {
  border: 1px solid #b8daff;
  background: #cce5ff;
  color: #004085;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px 40px 10px 10px;
  position: relative;
}
.snackbar-item .cbr-close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  padding: 5px;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 125ms ease;
  cursor: pointer;
}
.snackbar-item .cbr-close:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
.snackbar-item .cbr-close:before, .snackbar-item .cbr-close:after {
  background-color: #004085;
}
.snackbar-item.is-error {
  border: 1px solid #f5c6cb;
  background: #f8d7da;
  color: #721c24;
}
.snackbar-item.is-error .cbr-close:before, .snackbar-item.is-error .cbr-close:after {
  background: #721c24;
}
.snackbar-item.is-warning {
  border: 1px solid #ffe0b2;
  background: #ffe0b2;
  color: #e6511c;
}
.snackbar-item.is-warning .cbr-close:before, .snackbar-item.is-warning .cbr-close:after {
  background: #e6511c;
}
.snackbar-item.is-success {
  border: 1px solid #c3e6cb;
  background: #d4edda;
  color: #155724;
}
.snackbar-item.is-success .cbr-close:before, .snackbar-item.is-success .cbr-close:after {
  background: #155724;
}
.cbr-close:after, .cbr-close:before {
  content: "";
  width: 20px;
  height: 2px;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: red;
  border-radius: 20px;
  transform: rotate(-45deg);
  transition: transform 225ms ease 200ms, width 275ms ease 200ms;
}
.cbr-close:before {
  transform: rotate(45deg);
}
cbr-badge {
  position: relative;
  display: block;
  height: 100%;
  width: -moz-fit-content;
  width: fit-content;
}
cbr-badge [slot=image] {
  height: 100%;
}
cbr-badge [slot=image] img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 0 0;
     object-position: 0 0;
}
cbr-badge [slot=badge] {
  background: #0087ef;
  border: 2px solid white;
  padding: 7px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}
cbr-badge .icon-bottom-right {
  position: absolute;
  transform: translate(30%, 30%);
  right: 0;
  bottom: 0;
}
cbr-user-dropdown-list-item {
  display: flex;
  padding: 16px;
  background-color: #fff;
  width: 100%;
  flex-shrink: 0;
  transition: background-color 200ms ease;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-user-dropdown-list-item.active {
  background-color: rgba(0, 135, 239, 0.05);
}
cbr-user-dropdown-list-item:hover {
  background-color: rgba(0, 135, 239, 0.1);
  cursor: pointer;
}
cbr-user-dropdown-list-item:hover h1,
cbr-user-dropdown-list-item:hover h2,
cbr-user-dropdown-list-item:hover h3,
cbr-user-dropdown-list-item:hover h4,
cbr-user-dropdown-list-item:hover h5,
cbr-user-dropdown-list-item:hover h6 {
  color: #0087ef;
}
cbr-user-dropdown-list-item [slot=thumbnail] {
  height: 40px;
  -o-object-fit: cover;
     object-fit: cover;
  flex: 0 0 60px;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
}
cbr-user-dropdown-list-item .user-dropdown-list-item-content {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  min-width: 0;
}
cbr-user-dropdown-list-item h1,
cbr-user-dropdown-list-item h2,
cbr-user-dropdown-list-item h3,
cbr-user-dropdown-list-item h4,
cbr-user-dropdown-list-item h5,
cbr-user-dropdown-list-item h6 {
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  color: #002d4f;
  margin: 0;
  transition: color 200ms ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
cbr-user-dropdown-list-item p {
  font-size: 12px;
  color: #4c6c83;
  margin: 2px 0 0;
  line-height: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 768px) {
  cbr-user-dropdown-list-item h1,
cbr-user-dropdown-list-item h2,
cbr-user-dropdown-list-item h3,
cbr-user-dropdown-list-item h4,
cbr-user-dropdown-list-item h5,
cbr-user-dropdown-list-item h6 {
    color: #002d4f;
    font-size: 14px;
    font-weight: 600;
  }
  cbr-user-dropdown-list-item p {
    font-size: 12px;
    color: #4c6c83;
    font-weight: 300;
  }
}
/**
 * $bp-giga:  2500px;
 * $bp-mega:  1900px;
 * $bp-kilo:  1360px;
 * $bp-centi: 1024px;
 * $bp-milli:  768px;
 * $bp-nano:   480px;
*/
cbr-user-dropdown {
  display: flex;
  flex-direction: column;
  width: 260px;
  border-radius: 5px;
  box-shadow: 0 3px 6px #0000001a;
  position: relative;
  background-color: white;
  margin-top: 7px;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-user-dropdown:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent white transparent;
  position: absolute;
  top: -7px;
  right: 35px;
}
cbr-user-dropdown .user-dropdown-footer, cbr-user-dropdown .user-dropdown-section, cbr-user-dropdown .user-dropdown-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
cbr-user-dropdown .user-dropdown-footer:empty, cbr-user-dropdown .user-dropdown-section:empty, cbr-user-dropdown .user-dropdown-header:empty {
  display: none;
}
cbr-user-dropdown .user-dropdown-header {
  padding: 20px;
  border-bottom: 1px solid #e3e3e3;
}
cbr-user-dropdown .user-dropdown-section {
  padding: 0;
  border-bottom: 1px solid #e3e3e3;
  max-height: 240px;
  overflow-y: auto;
}
cbr-user-dropdown [slot=user-image] {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  margin-bottom: 10px;
}
cbr-user-dropdown [slot=user-name] {
  color: #002d4f;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
cbr-user-dropdown [slot=user-email] {
  margin: 0 0 10px 0;
  color: #4c6c83;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
cbr-user-dropdown [slot=footer-cta] {
  width: 100%;
}
cbr-user-dropdown [slot=close-button] {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 16px;
  top: 16px;
}
cbr-user-dropdown [slot=user-image] {
  background-color: #0087ef;
  display: flex;
  justify-content: center;
  align-items: center;
}
cbr-user-dropdown [slot=user-image] .profile-default {
  width: 48px;
  height: 48px;
  background-color: #fff;
}
cbr-user-dropdown [slot=user-image] p {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: white;
}
@media only screen and (max-width: 768px) {
  cbr-user-dropdown {
    width: 100vw;
    max-height: 100vh;
    margin: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
  cbr-user-dropdown:before {
    display: none;
  }
  cbr-user-dropdown .user-dropdown-header {
    padding: 32px 16px;
  }
  cbr-user-dropdown [slot=user-image] {
    margin-bottom: 8px;
  }
  cbr-user-dropdown [slot=user-name] {
    color: #002d4f;
    margin-bottom: 8px;
  }
  cbr-user-dropdown [slot=user-email] {
    color: #4c6c83;
    font-weight: 300;
    margin-bottom: 8px;
  }
  cbr-user-dropdown [slot=footer-cta] {
    margin-bottom: 0;
  }
}
cbr-nav-topbar-list-item {
  display: flex;
  align-items: center;
  flex-grow: 0;
  cursor: pointer;
}
cbr-nav-topbar-list-item:focus {
  outline: none;
}
cbr-nav-topbar-list-item [slot=icon] {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
cbr-nav-topbar-list-item [slot=icon] svg {
  fill: #b6c3cd;
}
cbr-nav-topbar-list-item h1,
cbr-nav-topbar-list-item h2,
cbr-nav-topbar-list-item h3,
cbr-nav-topbar-list-item h4,
cbr-nav-topbar-list-item h5,
cbr-nav-topbar-list-item h6,
cbr-nav-topbar-list-item p,
cbr-nav-topbar-list-item a {
  color: #b6c3cd;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
cbr-tab-navigation {
  padding-left: 40px;
  display: flex;
  flex-direction: row;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-tab-navigation .tab-navigation-item {
  position: relative;
  padding: 0 20px;
  margin-right: 20px;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  font-weight: 600;
  transition: background-color 200ms ease;
}
cbr-tab-navigation .tab-navigation-item p {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #4c6c83;
  line-height: 20px;
  white-space: nowrap;
  transition: color 200ms ease;
}
cbr-tab-navigation .tab-navigation-item:after {
  transition: background-color 200ms ease;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: rgba(0, 135, 239, 0);
}
cbr-tab-navigation .tab-navigation-item:hover:after {
  background-color: rgba(0, 135, 239, 0.1);
}
cbr-tab-navigation .tab-navigation-item.active p {
  color: #0087ef;
}
cbr-tab-navigation .tab-navigation-item.active:after {
  background-color: #0087ef;
}
@media only screen and (max-width: 768px) {
  cbr-tab-navigation {
    padding-left: 0;
  }
  cbr-tab-navigation .tab-navigation-item {
    flex: 1;
    margin: 0;
    padding: 0;
  }
  cbr-tab-navigation .tab-navigation-item p {
    margin: 16px 0;
    text-align: center;
  }
  cbr-tab-navigation .tab-navigation-item:after {
    width: 90px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
cbr-store-article-list-item {
  display: flex;
  flex-direction: column;
  width: 340px;
  background-color: white;
  padding: 20px;
  padding-bottom: 40px;
  border-radius: 5px;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-store-article-list-item .article-image {
  margin-bottom: 40px;
}
cbr-store-article-list-item .article-image [slot=image] {
  width: 300px;
  height: 180px;
  border-radius: 5px;
}
cbr-store-article-list-item p {
  margin: 0;
  margin-bottom: 10px;
  color: #4c6c83;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}
cbr-store-article-list-item h1,
cbr-store-article-list-item h2,
cbr-store-article-list-item h3,
cbr-store-article-list-item h4,
cbr-store-article-list-item h5,
cbr-store-article-list-item h6 {
  margin: 0;
  color: #002d4f;
  font-weight: 600;
  font-size: 24px;
}
@media only screen and (max-width: 768px) {
  cbr-store-article-list-item {
    width: auto;
    padding: 24px;
  }
  cbr-store-article-list-item .article-image {
    flex: 0 1 180px;
    position: relative;
    margin-bottom: 24px;
  }
  cbr-store-article-list-item .article-image [slot=image] {
    position: absolute;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    right: 0;
    top: 0;
    bottom: 0;
  }
  cbr-store-article-list-item p {
    text-transform: none;
    margin-bottom: 8px;
    font-weight: 300;
  }
}
cbr-store-app-list-item {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding: 30px;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-store-app-list-item [slot=icon] {
  width: 56px;
  height: 56px;
}
cbr-store-app-list-item h1,
cbr-store-app-list-item h2,
cbr-store-app-list-item h3,
cbr-store-app-list-item h4,
cbr-store-app-list-item h5,
cbr-store-app-list-item h6 {
  font-size: 16px;
  font-weight: 600;
  color: #002d4f;
  margin-top: 0;
  margin-bottom: 4px;
}
cbr-store-app-list-item p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 45, 79, 0.7);
}
cbr-store-app-list-item section {
  flex-grow: 1;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
cbr-store-app-list-item [slot=action] {
  width: 80px;
  min-width: 80px;
  height: 32px;
  margin-left: 20px;
}
@media only screen and (max-width: 768px) {
  cbr-store-app-list-item {
    padding: 24px;
  }
  cbr-store-app-list-item [slot=icon] {
    width: 48px;
    height: 48px;
  }
  cbr-store-app-list-item section {
    margin-left: 16px;
    align-self: stretch;
    min-width: 0;
  }
  cbr-store-app-list-item h1,
cbr-store-app-list-item h2,
cbr-store-app-list-item h3,
cbr-store-app-list-item h4,
cbr-store-app-list-item h5,
cbr-store-app-list-item h6 {
    font-size: 14px;
    color: #002d4f;
    font-weight: 600;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  cbr-store-app-list-item p {
    font-size: 12px;
    color: #4c6c83;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  cbr-store-app-list-item [slot=action] {
    margin-left: 8px;
    width: 64px;
    min-width: 64px;
    height: 32px;
  }
}
.carousel-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 3px 20px rgba(208, 230, 244, 0.5);
  border-radius: 5px;
  padding: 40px 0;
}
.carousel-card .carousel-header {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}
.scrollable-content-wrapper {
  max-width: 100%;
  scroll-behavior: smooth;
  overflow: hidden;
  -ms-overflow-style: none;
}
.scrollable-content-wrapper::-webkit-scrollbar {
  width: 0 !important;
}
/**
 * $bp-giga:  2500px;
 * $bp-mega:  1900px;
 * $bp-kilo:  1360px;
 * $bp-centi: 1024px;
 * $bp-milli:  768px;
 * $bp-nano:   480px;
*/
@media only screen and (max-width: 768px) {
  .carousel-card {
    padding: 32px 0;
  }
  .carousel-card .carousel-header {
    margin: 0 16px 24px 16px;
  }
}
.product-preview-card {
  display: flex;
  flex-direction: column;
  width: 122px;
  height: 255px;
  border: 1px solid #e0e7ed;
  border-radius: 10px;
  margin-left: 10px;
}
.product-preview-card .product-preview-image {
  display: flex;
  width: 100%;
  min-height: 118px;
  max-height: 118px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.product-preview-card .product-preview-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin: 8px;
  font-size: 14px;
}
.product-preview-card .product-preview-details * {
  font-family: arial, sans-serif;
}
.product-preview-card .product-preview-details .product-preview-details-top .product-preview-details-title {
  color: #1a0dab;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 36px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.product-preview-card .product-preview-details .product-preview-details-top .product-preview-details-price {
  color: #222222;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
  letter-spacing: 0.2px;
}
.product-preview-card .product-preview-details .product-preview-details-top .product-preview-details-website {
  margin-top: 2px;
  color: #222;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
}
.product-preview-card .product-preview-details .product-preview-details-owner {
  color: #1a0dab;
  font-size: 14px;
}
cbr-box {
  display: flex;
  width: 100%;
}
cbr-box.responsive {
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
@media only screen and (max-width: 768px) {
  cbr-box.responsive {
    flex-direction: column !important;
  }
}
cbr-box.horizontal > * {
  margin-right: 20px;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
@media only screen and (max-width: 768px) {
  cbr-box.horizontal > * {
    margin-bottom: 20px;
  }
}
cbr-box.vertical > * {
  margin-bottom: 20px;
}
cbr-box > *:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
cbr-box.column {
  flex-direction: column;
}
cbr-box.row {
  flex-direction: row;
}
cbr-field {
  width: 100%;
}
cbr-spinner {
  width: 100%;
  display: flex;
  justify-content: center;
}
.file-upload {
  display: none;
}
.invalid-image {
  color: #721c24;
}
cbr-layer {
  display: block;
  position: relative;
}
cbr-layer > * {
  position: absolute;
}
cbr-activity-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e9ed;
  padding: 20px 0;
}
.controls-left {
  display: flex;
  flex: 2 1 auto;
  flex-basis: 200px;
}
.controls-right {
  display: flex;
  justify-content: flex-end;
  flex: 1 1 auto;
  flex-basis: 100px;
}
.activity-list-item-body-and-status {
  display: flex;
  flex: 1;
}
.activity-list-item-body {
  flex: 1;
  align-self: stretch;
  min-width: 0;
}
.activity-list-item-body h1, .activity-list-item-body h2, .activity-list-item-body h3, .activity-list-item-body h4, .activity-list-item-body h5, .activity-list-item-body h6 {
  margin: 0;
  color: #002d4f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity-list-item-body p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity-list-item-action-item {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  fill: #92a0af;
}
.activity-list-item-action-item:hover {
  border: 1px solid #0087ef;
  fill: #0087ef;
}
.activity-list-item-image {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.activity-list-item-image [slot=icon] {
  width: 56px;
  height: 56px;
}
.status-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-container {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actions-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.actions-container [slot=actions] {
  width: 110px;
}
/**
 * $bp-giga:  2500px;
 * $bp-mega:  1900px;
 * $bp-kilo:  1360px;
 * $bp-centi: 1024px;
 * $bp-milli:  768px;
 * $bp-nano:   480px;
*/
@media only screen and (max-width: 768px) {
  .activity-list-item-body {
    flex: 0;
  }
  .activity-list-item-body h1, .activity-list-item-body h2, .activity-list-item-body h3, .activity-list-item-body h4, .activity-list-item-body h5, .activity-list-item-body h6 {
    font-size: 14px;
    color: #002d4f;
    margin-bottom: 8px;
  }
  .activity-list-item-body p {
    display: none;
  }

  .activity-list-item-body-and-status {
    max-width: 130px;
    flex-direction: column;
    align-items: flex-start;
  }

  .activity-list-item-image {
    margin-right: 16px;
  }
  .activity-list-item-image [slot=icon] {
    width: 48px;
    height: 48px;
  }

  .actions-container [slot=actions] {
    width: 90px;
  }
}
cbr-toggle .field {
  flex-direction: row;
  align-items: center;
  margin: 0;
}
cbr-toggle .field label {
  margin: 0 0 0 16px;
  font-weight: 300;
  color: #4c6c83;
  font-size: 14px;
}
cbr-toggle .control {
  border-radius: 12px;
  position: relative;
  background: #e7eaed;
  width: 42px;
  height: 24px;
  transition: background 200ms ease;
  cursor: pointer;
}
cbr-toggle .control:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-95%);
  top: 3px;
  width: 18px;
  height: 18px;
  background: #fff;
  display: block;
  transition: transform 200ms ease;
}
cbr-toggle .control.active {
  background: #27d07b;
}
cbr-toggle .control.active:after {
  transform: translateX(-5%);
}
cbr-color-picker .color {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 10px;
  border: 1px solid #e5e9ed;
}
cbr-color-picker .color input {
  opacity: 0;
}
cbr-color-picker .color-text {
  flex: 1 0;
}
cbr-theme-preview {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 55%;
  position: relative;
}
cbr-theme-preview .bg {
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
cbr-theme-preview .bg .main-text {
  font-size: 32px;
  text-transform: capitalize;
}
cbr-theme-preview .bg .patch-wrap {
  position: absolute;
  display: flex;
  top: 20px;
  right: 20px;
}
cbr-theme-preview .bg .color-patch {
  border-radius: 5px;
  height: 25px;
  width: 25px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  margin-left: 10px;
}
cbr-theme-preview .bg.small .main-text {
  font-size: 16px;
}
cbr-theme-preview .bg.small .color-patch {
  border-radius: 3px;
  height: 15px;
  width: 15px;
}
cbr-checkbox-list-item .checkbox-list-item {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  color: #4c6c83;
}
cbr-checkbox-list-item .checkbox-list-item:hover, cbr-checkbox-list-item .checkbox-list-item.active {
  border-color: #d2e8fc;
  background-color: #f4f9fe;
  color: #0087ef;
}
cbr-checkbox-list-item cbr-checkbox {
  pointer-events: none;
}
cbr-checkbox-list-item .field {
  margin: 0;
}
cbr-checkbox-list-item .checkbox-list-item-content {
  font-size: 14px;
  font-weight: 300;
  margin-left: 16px;
}
cbr-flow-navigation-footer {
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-flow-navigation-footer .flow-navigation-footer-container {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 48px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
cbr-flow-navigation-footer .flow-navigation-footer-left,
cbr-flow-navigation-footer .flow-navigation-footer-right {
  width: 100%;
  max-width: 160px;
}
cbr-flow-navigation-footer .flow-navigation-footer-left button,
cbr-flow-navigation-footer .flow-navigation-footer-right button {
  width: 100%;
}
cbr-flow-navigation-footer .flow-navigation-footer-center h1,
cbr-flow-navigation-footer .flow-navigation-footer-center h2,
cbr-flow-navigation-footer .flow-navigation-footer-center h3,
cbr-flow-navigation-footer .flow-navigation-footer-center h4,
cbr-flow-navigation-footer .flow-navigation-footer-center h5,
cbr-flow-navigation-footer .flow-navigation-footer-center h6,
cbr-flow-navigation-footer .flow-navigation-footer-center p,
cbr-flow-navigation-footer .flow-navigation-footer-center a {
  font-size: 14px;
  color: #4c6c83;
}
@media only screen and (max-width: 768px) {
  cbr-flow-navigation-footer .flow-navigation-footer-left,
cbr-flow-navigation-footer .flow-navigation-footer-right {
    max-width: 120px;
  }
}
cbr-summary {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 24px 16px;
  border: 1px solid #e0e7ed;
  border-radius: 5px;
  /**
   * $bp-giga:  2500px;
   * $bp-mega:  1900px;
   * $bp-kilo:  1360px;
   * $bp-centi: 1024px;
   * $bp-milli:  768px;
   * $bp-nano:   480px;
  */
}
cbr-summary .left {
  display: flex;
  flex-direction: column;
}
cbr-summary .right {
  display: flex;
  align-items: center;
  margin-left: 4px;
}
cbr-summary p {
  color: #4c6c83;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
}
cbr-summary b {
  margin-top: 4px;
  margin-bottom: 0;
  color: #002d4f;
}
@media only screen and (max-width: 768px) {
  cbr-summary {
    padding: 16px;
  }
}
.insight-box {
  padding: 32px;
  background-color: white;
  border: 1px solid #dbe2ed;
  color: #4c6c83;
  border-radius: 5px;
}
.title-container {
  display: flex;
  justify-content: space-between;
}
.text-container {
  margin-top: 32px;
}
/**
 *
 *	Cobiro variables
 *
 */
/**
 *	Fonts
 */
/**
  *	Colors
  */
/**
  *	Border & Border-radius defaults
  */
/**
  *	Easing
  */
/**
  *	Gutter
  */
/**
  *  Breakpoints
  */
/*
 *  Shadow
 */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}
.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.mat-card-header .mat-card-title {
  font-size: 20px;
}
.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}
.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.mat-cell, .mat-footer-cell {
  font-size: 14px;
}
.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-calendar-body {
  font-size: 13px;
}
.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.mat-form-field-label {
  top: 1.34375em;
}
.mat-form-field-underline {
  bottom: 1.34375em;
}
.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
input.mat-input-element {
  margin-top: -0.0625em;
}
.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-select-trigger {
  height: 1.125em;
}
.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.mat-step-sub-label-error {
  font-weight: normal;
}
.mat-step-label-error {
  font-size: 14px;
}
.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}
.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
textarea.cdk-textarea-autosize {
  resize: none;
}
textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
.mat-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator {
  position: relative;
}
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1976d2;
}
.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #27d07b;
}
.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}
.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #1976d2;
}
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #27d07b;
}
.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}
.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.mat-badge-content {
  color: white;
  background: #1976d2;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}
.mat-badge-accent .mat-badge-content {
  background: #27d07b;
  color: black;
}
.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.mat-badge {
  position: relative;
}
.mat-badge-hidden .mat-badge-content {
  display: none;
}
.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}
.mat-badge-content.mat-badge-active {
  transform: none;
}
.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #1976d2;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #27d07b;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #1976d2;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #27d07b;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.mat-button-focus-overlay {
  background: black;
}
.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: black;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #1976d2;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #27d07b;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox-checkmark {
  fill: #fafafa;
}
.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #1976d2;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #27d07b;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox .mat-ripple-element {
  background-color: black;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #1976d2;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #27d07b;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #1976d2;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #27d07b;
  color: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-table {
  background: white;
}
.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}
mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.mat-calendar-body-in-range::before {
  background: rgba(25, 118, 210, 0.2);
}
.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(25, 118, 210, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(25, 118, 210, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-calendar-body-selected {
  background-color: #1976d2;
  color: white;
}
.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(25, 118, 210, 0.4);
}
.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(39, 208, 123, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(39, 208, 123, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(39, 208, 123, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #27d07b;
  color: black;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(39, 208, 123, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-datepicker-toggle-active {
  color: #1976d2;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #27d07b;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #1976d2;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #27d07b;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.mat-focused .mat-form-field-required-marker {
  color: #27d07b;
}
.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1976d2;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #27d07b;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #1976d2;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #27d07b;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.mat-error {
  color: #f44336;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #1976d2;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #27d07b;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.mat-icon.mat-primary {
  color: #1976d2;
}
.mat-icon.mat-accent {
  color: #27d07b;
}
.mat-icon.mat-warn {
  color: #f44336;
}
.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.mat-input-element {
  caret-color: #1976d2;
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-form-field.mat-accent .mat-input-element {
  caret-color: #27d07b;
}
.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-item-disabled {
  background-color: #eeeeee;
}
.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-paginator {
  background: white;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}
.mat-progress-bar-background {
  fill: #bad6f2;
}
.mat-progress-bar-buffer {
  background-color: #bad6f2;
}
.mat-progress-bar-fill::after {
  background-color: #1976d2;
}
.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #bef1d7;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #bef1d7;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #27d07b;
}
.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #1976d2;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #27d07b;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1976d2;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #1976d2;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #27d07b;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #27d07b;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}
.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #1976d2;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #27d07b;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #27d07b;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(39, 208, 123, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #27d07b;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #1976d2;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(25, 118, 210, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #1976d2;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #1976d2;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(25, 118, 210, 0.2);
}
.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #27d07b;
}
.mat-accent .mat-slider-thumb-label-text {
  color: black;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(39, 208, 123, 0.2);
}
.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #1976d2;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}
.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}
.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.mat-sort-header-arrow {
  color: #757575;
}
.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(186, 214, 242, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #1976d2;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(190, 241, 215, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #27d07b;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: black;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(186, 214, 242, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #1976d2;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(190, 241, 215, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #27d07b;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: black;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: black;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #1976d2;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #27d07b;
  color: black;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}
.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.mat-tree {
  background: white;
}
.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}
.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-simple-snackbar-action {
  color: #27d07b;
}
body .mat-dialog-container {
  display: block;
  padding: 0;
  border-radius: 5px;
  box-sizing: border-box;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  overflow-x: hidden;
}
body .mat-dialog-container:focus {
  outline: none;
}
.dialog-dropdown-container .mat-dialog-container {
  overflow: visible;
}
.dialog-dropdown-container .mat-dialog-container .mat-dialog-content {
  overflow: visible;
}
@keyframes contentLoadingKeyframes {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes infiniteLoader {
  from {
    left: 0;
    width: 0;
  }
  50% {
    right: 0;
    width: 100%;
  }
  to {
    right: 0;
    width: 0;
  }
}
@keyframes dot-pulse {
  from {
    transform: scale(0.2);
  }
  to {
    transform: scale(1);
  }
}
/**
 * $bp-mega:  2500px;
 * $bp-mega:  1900px;
 * $bp-kilo:  1360px;
 * $bp-centi: 1024px;
 * $bp-milli:  768px;
 * $bp-nano:   480px;
  */
/**
   *  First section controls media quries that contains from-to values
   *  if numbers are parsed unitless it will default to px's, if you need to use em's just parse the unit in the end
   *
   *  Example:
    @include breakpoint (0 nano) {
      // @media only screen and (min-width: 0px) and (max-width: 480px) {}
    }
    @include breakpoint (nano milli) {
      // @media only screen and (min-width: 480px) and (max-width: 768px) {}
    }
    @include breakpoint (1024 1360) {
      // @media only screen and (min-width: 1024px) and (max-width: 1360px) {}
    }
    @include breakpoint (milli 1024) {
      // @media only screen and (min-width: 768px) and (max-width: 1024px) {}
    }

   *  You can use this very simple and you can turn from min to max width
   *  only by adding dash infront of your breakpoint keyword
   *
   *  Example:
    @include breakpoint (milli) {
      // @media only screen and (min-width: 768px) {}
    }
    @include breakpoint (-nano) {
      // @media only screen and (max-width: 480px) {}
    }

   *  There is also support for writing strings, and printable breakpoint
   *
   *  Example:
    @include breakpoint ('max-width: 700px') {
      // @media only screen and (max-width: 700px) {}
    }
    @include breakpoint (print) {
      // @media print {}
    }
   */
/*
 ÷ Widget colors
 */
/*
 ÷ Green
 */
/*
 ÷ Yellow
 */
/*
 ÷ Red
 */
/*
 ÷ Grey
 */
.stat-grid, .budget-buttons {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-left: -20px;
}
:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-semi-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}
:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-semi-selected,
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-semi-selected,
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-semi-selected {
  background-color: #1976d2;
  color: white;
}
.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range) {
  border-radius: 100% 0 0 100%;
  background-color: #bad6f2;
}
.mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
  border-radius: 0 100% 100% 0;
  background-color: #bad6f2;
}
.mat-calendar-body > tr .mat-calendar-cell-semi-selected ~ .mat-calendar-cell-semi-selected {
  border-radius: 0;
}
.mat-calendar-cell-semi-selected {
  background-color: #bad6f2;
}
.capitalize {
  text-transform: capitalize;
}
* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
app-root {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: "Soleil", sans-serif;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  color: #92a0af;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body input[type=text],
body textarea {
  font-family: "Soleil", sans-serif;
}
body button {
  font-family: "Soleil", sans-serif;
}
.flow-wrap {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex: 1 0;
  flex-direction: row;
  min-height: 100vh;
}
.page-wrap {
  padding: 40px;
  min-height: 100%;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .page-wrap {
    padding: 20px;
  }
  .page-wrap > h1 {
    display: none;
  }
}
.page-wrap h1 {
  margin: 0 0 20px;
}
.default-wrapper {
  max-width: 1100px;
  margin: 0 auto;
}
h1 {
  font-size: 32px;
  margin-top: 16px;
  margin-bottom: 8px;
}
h2 {
  font-size: 24px;
  margin-top: 12px;
  margin-bottom: 6px;
}
h3 {
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 8px;
}
h4 {
  font-size: 14px;
  margin-top: 9.3333333333px;
  margin-bottom: 4.6666666667px;
}
h1,
h2,
h3,
h4 {
  color: #333333;
  font-weight: 600;
  line-height: normal;
  margin-top: 0;
}
.link,
a {
  color: #0087ef;
  transition: color 125ms ease;
  text-decoration: underline;
  cursor: pointer;
}
.link:hover,
a:hover {
  color: #239fff;
}
.link:focus,
a:focus {
  outline: none;
  color: #239fff;
}
.link.button,
a.button {
  padding-top: 8px;
  padding-bottom: 8px;
}
p {
  color: #92a0af;
  margin-top: 0;
}
.img-responsive {
  display: block;
  width: auto;
  max-width: 100%;
}
.item-is-loading {
  width: 40px;
  height: 40px;
  position: relative;
  color: transparent !important;
  pointer-events: none;
}
.item-is-loading > * {
  display: none;
}
.item-is-loading::after {
  content: "";
  animation: spinAround 0.5s infinite linear;
  border: 2px solid;
  border-color: transparent transparent #92a0af #92a0af;
  border-radius: 290486px;
  display: block;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  position: absolute;
  left: 0;
  top: 0;
}
.cdk-global-overlay-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: initial;
  padding: 0 20px;
  display: block;
}
.cdk-overlay-container {
  z-index: 100000;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}
.cdk-overlay-pane {
  padding: 80px 0;
  max-height: initial;
  margin: 0 auto;
}
.cdk-overlay-pane.mat-datepicker-popup {
  padding: 0;
  width: 320px !important;
}
@media only screen and (max-width: 768px) {
  .cdk-overlay-pane {
    padding: 20px 0;
  }
}
cbr-datatable:after {
  content: "";
  flex: 0 0 40px;
}
cbr-datatable table {
  min-width: calc(100vw - 80px);
}
cbr-datatable .fluid-table-content-wrap {
  padding-bottom: 0;
}
cbr-datatable cbr-datatable-row:last-child cbr-datatable-cell {
  border-bottom: 0;
}
cbr-datatable cbr-datatable-cell.limited {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
cbr-datatable cbr-datatable-header-cell .th-text {
  display: flex;
  align-items: center;
}
cbr-datatable cbr-datatable-header-cell .th-text .triangle {
  margin-left: 10px;
}
.article-styles {
  color: #4c6c83;
}
.article-styles li {
  font-weight: 700;
}
.article-styles ul {
  list-style: none;
  padding: 0;
}
.article-styles ul li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
}
.article-styles ul li:before {
  content: "";
  display: block;
  position: absolute;
  left: 20px;
  top: 8px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #0087ef;
}
cbr-budget cbr-button button.button.style-outline.type-medium.stretch {
  padding: 0;
  line-height: 17px;
}
.cbr-close:after, .cbr-close:before {
  content: "";
  width: 20px;
  height: 2px;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: red;
  border-radius: 20px;
  transform: rotate(-45deg);
  transition: transform 225ms ease 200ms, width 275ms ease 200ms;
}
.cbr-close:before {
  transform: rotate(45deg);
}
cbr-google-maps {
  width: 100%;
  height: 100%;
  display: block;
}
cbr-google-maps agm-map {
  height: 100% !important;
  display: block;
}
.maps-container {
  width: 100%;
  height: 500px;
}
cbr-svg[bg] {
  top: 0;
}
cbr-svg svg {
  display: inline-block;
}
cbr-svg .svg-wrap {
  background: #e7eaed;
  border-radius: 100%;
  padding: 8px;
  display: inline-block;
  box-sizing: content-box;
}
cbr-svg .svg-wrap.bg-primary, cbr-svg .svg-wrap.bg-reach, cbr-svg .svg-wrap.bg-1 {
  background: #e5f3fe;
}
cbr-svg .svg-wrap.bg-radius, cbr-svg .svg-wrap.bg-cost, cbr-svg .svg-wrap.bg-2 {
  background: #fee5e5;
}
cbr-svg .svg-wrap.bg-views, cbr-svg .svg-wrap.bg-3 {
  background: #f5ebff;
}
cbr-svg .svg-wrap.bg-clicks, cbr-svg .svg-wrap.bg-4 {
  background: #e5f9fa;
}
cbr-svg .svg-wrap.bg-ctr, cbr-svg .svg-wrap.bg-5 {
  background: #e9fbe5;
}
body .apexcharts-toolbar {
  right: 20px;
}
body .apexcharts-legend.position-bottom .apexcharts-legend-series {
  margin: 10px 10px 0 !important;
}
body .apexcharts-area-series .apexcharts-area {
  fill-opacity: 0.5;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 400px #27d07b;
  }
}
.custom-checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0 0 0 #27d07b;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.custom-checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #27d07b;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.custom-checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.search-palette-dialog .mat-dialog-container {
  background: transparent;
}
.search-palette-dialog cbr-select {
  width: 100%;
}
.search-palette-dialog cbr-select .field ng-select.ng-select .ng-select-container {
  height: 80px;
  line-height: 80px;
  padding: 0 20px;
  border: 0;
}
.search-palette-dialog cbr-select .field .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  padding: 0 10px;
  width: 100%;
  font-size: 18px;
  color: #cfd5db;
}
.search-palette-dialog cbr-select .field .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  height: 100%;
}
.search-palette-dialog cbr-select .field .ng-input {
  display: flex;
}
.search-palette-dialog cbr-select .field .ng-input input {
  border: 0;
  padding: 0 20px;
  height: 100%;
  line-height: 100%;
  font-size: 18px;
  box-sizing: border-box;
}
.search-palette-dialog cbr-select .field .ng-placeholder {
  opacity: 0.5;
  color: #4c6c83;
  font-size: 18px;
  font-weight: 400;
}
.search-palette-dialog cbr-select .field .ng-dropdown-panel {
  position: relative;
}
.search-palette-dialog cbr-select .field ng-select.ng-select .ng-dropdown-panel-items {
  border: 0;
  padding: 0;
}
.search-palette-dialog cbr-select .field ng-select.ng-select .ng-dropdown-panel-items .ng-option {
  padding: 0 20px;
  border-top: 1px solid #e7eaed;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
}
.code {
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
}
.code.center {
  align-items: center;
  justify-content: center;
}
.code .field {
  flex: 1 0;
}
/*
 * Visual Studio 2015 code style
 */
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #1e1e1e;
  color: #dcdcdc;
}
.hljs-keyword,
.hljs-literal,
.hljs-symbol,
.hljs-name {
  color: #569cd6;
}
.hljs-link {
  color: #569cd6;
  text-decoration: underline;
}
.hljs-built_in,
.hljs-type {
  color: #4ec9b0;
}
.hljs-number,
.hljs-class {
  color: #b8d7a3;
}
.hljs-string,
.hljs-meta-string {
  color: #d69d85;
}
.hljs-regexp,
.hljs-template-tag {
  color: #9a5334;
}
.hljs-subst,
.hljs-function,
.hljs-title,
.hljs-params,
.hljs-formula {
  color: #dcdcdc;
}
.hljs-comment,
.hljs-quote {
  color: #57a64a;
  font-style: italic;
}
.hljs-doctag {
  color: #608b4e;
}
.hljs-meta,
.hljs-meta-keyword,
.hljs-tag {
  color: #9b9b9b;
}
.hljs-variable,
.hljs-template-variable {
  color: #bd63c5;
}
.hljs-attr,
.hljs-attribute,
.hljs-builtin-name {
  color: #9cdcfe;
}
.hljs-section {
  color: gold;
}
.hljs-emphasis {
  font-style: italic;
}
.hljs-strong {
  font-weight: bold;
}
/*.hljs-code {
  font-family:'Monospace';
}*/
.hljs-bullet,
.hljs-selector-tag,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: #d7ba7d;
}
.hljs-addition {
  background-color: #144212;
  display: inline-block;
  width: 100%;
}
.hljs-deletion {
  background-color: #600;
  display: inline-block;
  width: 100%;
}
.configure-campaign-wrap {
  padding: 0 20px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .configure-campaign-wrap {
    padding: 20px 0 100px;
    flex-direction: column-reverse;
  }
}
.configure-campaign-main {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 3px 20px rgba(208, 230, 244, 0.5);
  border-radius: 5px;
  width: 100%;
  max-width: 700px;
}
.configure-campaign-content {
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .configure-campaign-content {
    padding: 40px 20px 20px;
  }
}
.configure-campaign-content h2 {
  margin-bottom: 10px;
}
.configure-campaign-content > p {
  max-width: 75%;
  color: #4c6c83;
}
@media only screen and (max-width: 768px) {
  .configure-campaign-content > p {
    max-width: 100%;
  }
}
.configure-campaign-content .field {
  width: 100%;
  margin-top: 10px;
}
.configure-campaign-content .target-list {
  margin-top: 40px;
}
.configure-campaign-content.center {
  align-items: center;
}
.configure-campaign-content.center h2 {
  text-align: center;
}
.configure-campaign-content.center > p {
  text-align: center;
}
.configure-campaign-content.columns {
  flex-direction: row;
}
.configure-campaign-footer {
  border-top: 1px solid #e5e9ed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: #92a0af;
  background: #fff;
  border-radius: 0 0 5px 5px;
}
@media only screen and (max-width: 768px) {
  .configure-campaign-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.content-loading {
  overflow: hidden;
  position: relative;
}
.content-loading label.input-checkbox {
  display: none;
}
.content-loading img {
  background: #e7eaed;
}
.content-loading cbr-svg {
  display: none;
}
.content-loading cbr-google-maps {
  position: relative;
}
.content-loading cbr-google-maps:before {
  content: "";
  display: block;
  width: 100%;
  background: #e7eaed;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.content-loading cbr-button,
.content-loading cbr-arrow-buttons {
  position: relative;
  background: transparent;
  border: transparent;
  color: transparent;
}
.content-loading cbr-button:before,
.content-loading cbr-arrow-buttons:before {
  content: "";
  display: block;
  width: 100%;
  background: #e7eaed;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  z-index: 1000;
}
.content-loading cbr-button .ribbon, .content-loading cbr-button .button-label,
.content-loading cbr-arrow-buttons .ribbon,
cbr-button .content-loading cbr-arrow-buttons .button-label {
  display: none;
}
.content-loading h4 {
  color: transparent;
  position: relative;
  height: 16px;
}
.content-loading h4:before {
  content: "";
  display: block;
  height: 16px;
  width: 60%;
  background: #e7eaed;
  position: absolute;
  top: 0;
}
.content-loading h2,
.content-loading h3 {
  color: transparent;
  height: 24px;
}
.content-loading h2:before,
.content-loading h3:before {
  content: "";
  display: block;
  height: 24px;
  width: 60%;
  background: #e7eaed;
  top: 0;
}
.content-loading .text,
.content-loading label,
.content-loading a,
.content-loading p {
  color: transparent !important;
  height: 42px;
  position: relative;
  border: none !important;
}
.content-loading .text:before,
.content-loading label:before,
.content-loading a:before,
.content-loading p:before {
  content: "";
  display: block;
  height: 14px;
  width: 100%;
  background: #e7eaed;
  top: 0;
  margin: 2px 0 !important;
}
.content-loading .text:after,
.content-loading label:after,
.content-loading a:after,
.content-loading p:after {
  content: "";
  display: block;
  height: 14px;
  width: 80%;
  background: #e7eaed;
  top: 18px;
  margin: 2px 0 !important;
  position: absolute;
}
.content-loading .text.single,
.content-loading label.single,
.content-loading a.single,
.content-loading p.single {
  height: 14px;
  margin: 2px 0 !important;
}
.content-loading .text.single:after,
.content-loading label.single:after,
.content-loading a.single:after,
.content-loading p.single:after {
  display: none;
}
.content-loading span {
  color: transparent !important;
  height: 42px;
  position: relative;
}
.content-loading span:before {
  content: "";
  display: block;
  height: 14px;
  width: 100%;
  background: #e7eaed;
  top: 0;
  margin: 2px 0 !important;
}
.content-loading label,
.content-loading .text {
  height: 14px;
  width: 100px;
}
.content-loading label:after,
.content-loading .text:after {
  display: none;
}
.content-loading label .ad-field-label-char-counter,
.content-loading .text .ad-field-label-char-counter {
  color: transparent !important;
}
.content-loading:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 50000;
  width: 500%;
  margin-left: -250%;
  animation: contentLoadingKeyframes 800ms linear infinite;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
}
.content-loading input,
.content-loading textarea,
.content-loading ng-select {
  background-color: #e7eaed;
  color: transparent !important;
  font-size: 0;
}
.content-loading cbr-input .input-counter,
.content-loading cbr-textarea .input-counter {
  display: none;
}
.content-loading ng-select .ng-value,
.content-loading ng-select .ng-placeholder {
  color: transparent !important;
}
.content-loading ng-select .ng-arrow-wrapper {
  display: none;
}
.content-loading .chips .chip {
  min-width: 100px;
  min-height: 24px;
  background-color: #e7eaed !important;
}
.content-loading .arrows {
  display: none !important;
}
.content-loading .image-container.upload {
  border-color: #e7eaed !important;
}
.content-loading .image-container img {
  background-color: #e7eaed;
  border-color: #e7eaed !important;
}
.content-loading .image-container .tick-container {
  display: none;
}
.content-loading .target-list .target-list-item-map {
  height: 140px;
  background-color: #e7eaed;
}
.content-loading cbr-budget-picker .custom-budget .toggle {
  background-color: #e7eaed;
  color: transparent !important;
}
.content-loading cbr-flow-info-card img,
.content-loading cbr-activity-edit-header img,
.content-loading cbr-billing img {
  background-color: #e7eaed !important;
  -o-object-position: -999999999px;
     object-position: -999999999px;
}
.content-loading cbr-arrow-buttons {
  display: none;
}
.content-loading cbr-ad-status div {
  background: transparent !important;
  border: transparent !important;
  color: transparent !important;
}
.content-loading cbr-ad-status:before {
  content: "";
  display: block;
  height: 24px;
  background: #e7eaed;
  border-radius: 24px;
}
.content-loading cbr-counter {
  position: relative;
  background: transparent;
  border: transparent;
  color: transparent;
}
.content-loading cbr-counter div {
  visibility: hidden;
}
.content-loading cbr-counter:before {
  content: "";
  display: block;
  height: 24px;
  width: 100%;
  background: #e7eaed;
  position: absolute;
  top: 0;
}
.content-loading ng-select.ng-select .ng-select-container {
  border: none;
}
.content-loading .field input {
  border: none;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 400px #27d07b;
  }
}
.custom-checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0 0 0 #27d07b;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.custom-checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #27d07b;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.custom-checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
.flow-step-edit-modal mat-dialog-container {
  box-shadow: none;
  background: transparent;
}
.mat-datepicker-toggle-default-icon {
  display: none;
}
.input-wrap > .mat-datepicker-content .mat-calendar-next-button, .field .input-wrap--overflow-link > .mat-datepicker-content .mat-calendar-next-button,
.input-wrap > .mat-datepicker-content .mat-calendar-previous-button,
.field .input-wrap--overflow-link > .mat-datepicker-content .mat-calendar-previous-button,
.input-wrap > .mat-datepicker-toggle,
.field .input-wrap--overflow-link > .mat-datepicker-toggle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.mat-icon-button.cdk-program-focused {
  background: transparent;
}
.mat-icon-button.cdk-program-focused .mat-button-focus-overlay {
  display: none;
}
.disclaimer, .disclaimer-warning, .disclaimer-alert, .disclaimer-info {
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}
.disclaimer-warning {
  background-color: #ffe0b2;
  color: #e6511c;
  border: 1px solid #ffe0b2;
}
.disclaimer-info {
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  background: #cce5ff;
  color: #004085;
  border: 1px solid #b8daff;
}
.examples {
  flex: 1 0;
  width: 100%;
}
.example-block {
  display: flex;
  margin-bottom: 20px;
}
.example-block.block-100 {
  width: 100%;
}
.example-block cbr-doc-tab {
  width: 100%;
  border-radius: 5px;
  background: #fff;
}
.example-block cbr-doc-tab.fade {
  background: #f5f9fc;
}
.example-block cbr-doc-tab .code .example-wrap {
  width: auto;
  flex: auto;
  margin-bottom: 20px;
}
.example-block .example-wrap {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.example-block .example-wrap.row {
  flex-direction: row;
  margin: 0;
}
.example-block .example-wrap.row > * {
  margin-right: 20px;
  margin-bottom: 20px;
}
.example-block .example-wrap label {
  margin-bottom: 5px;
  color: #92a0af;
}
[class^=flag] {
  width: 16px;
  height: 11px;
  background-image: url("/assets/flags.png");
  display: inline-block;
}
.flag-ad {
  background-position: -16px 0;
}
.flag-ae {
  background-position: -32px 0;
}
.flag-af {
  background-position: -48px 0;
}
.flag-ag {
  background-position: -64px 0;
}
.flag-ai {
  background-position: -80px 0;
}
.flag-al {
  background-position: -96px 0;
}
.flag-am {
  background-position: -112px 0;
}
.flag-ao {
  background-position: -144px 0;
}
.flag-ar {
  background-position: -160px 0;
}
.flag-as {
  background-position: -176px 0;
}
.flag-at {
  background-position: -192px 0;
}
.flag-au {
  background-position: -208px 0;
}
.flag-aw {
  background-position: -224px 0;
}
.flag-az {
  background-position: -240px 0;
}
.flag-ba {
  background-position: 0 -11px;
}
.flag-bb {
  background-position: -16px -11px;
}
.flag-bd {
  background-position: -32px -11px;
}
.flag-be {
  background-position: -48px -11px;
}
.flag-bf {
  background-position: -64px -11px;
}
.flag-bg {
  background-position: -80px -11px;
}
.flag-bh {
  background-position: -96px -11px;
}
.flag-bi {
  background-position: -112px -11px;
}
.flag-bj {
  background-position: -128px -11px;
}
.flag-bm {
  background-position: -144px -11px;
}
.flag-bn {
  background-position: -160px -11px;
}
.flag-bo {
  background-position: -176px -11px;
}
.flag-br {
  background-position: -192px -11px;
}
.flag-bs {
  background-position: -208px -11px;
}
.flag-bt {
  background-position: -224px -11px;
}
.flag-bv {
  background-position: -240px -11px;
}
.flag-bw {
  background-position: 0 -22px;
}
.flag-by {
  background-position: -16px -22px;
}
.flag-bz {
  background-position: -32px -22px;
}
.flag-ca {
  background-position: -48px -22px;
}
.flag-cd {
  background-position: -80px -22px;
}
.flag-cf {
  background-position: -96px -22px;
}
.flag-cg {
  background-position: -112px -22px;
}
.flag-ch {
  background-position: -128px -22px;
}
.flag-ci {
  background-position: -144px -22px;
}
.flag-ck {
  background-position: -160px -22px;
}
.flag-cl {
  background-position: -176px -22px;
}
.flag-cm {
  background-position: -192px -22px;
}
.flag-cn {
  background-position: -208px -22px;
}
.flag-co {
  background-position: -224px -22px;
}
.flag-cr {
  background-position: -240px -22px;
}
.flag-cu {
  background-position: 0 -33px;
}
.flag-cv {
  background-position: -16px -33px;
}
.flag-cw {
  background-position: -32px -33px;
}
.flag-cy {
  background-position: -48px -33px;
}
.flag-cz {
  background-position: -64px -33px;
}
.flag-de {
  background-position: -80px -33px;
}
.flag-dj {
  background-position: -96px -33px;
}
.flag-dk {
  background-position: -112px -33px;
}
.flag-dm {
  background-position: -128px -33px;
}
.flag-do {
  background-position: -144px -33px;
}
.flag-dz {
  background-position: -160px -33px;
}
.flag-ec {
  background-position: -176px -33px;
}
.flag-ee {
  background-position: -192px -33px;
}
.flag-eg {
  background-position: -208px -33px;
}
.flag-eh {
  background-position: -224px -33px;
}
.flag-er {
  background-position: 0 -44px;
}
.flag-es {
  background-position: -16px -44px;
}
.flag-et {
  background-position: -32px -44px;
}
.flag-eu {
  background-position: -48px -44px;
}
.flag-fi {
  background-position: -64px -44px;
}
.flag-fj {
  background-position: -80px -44px;
}
.flag-fk {
  background-position: -96px -44px;
}
.flag-fm {
  background-position: -112px -44px;
}
.flag-fo {
  background-position: -128px -44px;
}
.flag-fr {
  background-position: -144px -44px;
}
.flag-ga {
  background-position: -160px -44px;
}
.flag-gb {
  background-position: -176px -44px;
}
.flag-gd {
  background-position: -192px -44px;
}
.flag-ge {
  background-position: -208px -44px;
}
.flag-gf {
  background-position: -224px -44px;
}
.flag-gg {
  background-position: -240px -44px;
}
.flag-gh {
  background-position: 0 -55px;
}
.flag-gi {
  background-position: -16px -55px;
}
.flag-gl {
  background-position: -32px -55px;
}
.flag-gm {
  background-position: -48px -55px;
}
.flag-gn {
  background-position: -64px -55px;
}
.flag-gp {
  background-position: -80px -55px;
}
.flag-gq {
  background-position: -96px -55px;
}
.flag-gr {
  background-position: -112px -55px;
}
.flag-gs {
  background-position: -128px -55px;
}
.flag-gt {
  background-position: -144px -55px;
}
.flag-gu {
  background-position: -160px -55px;
}
.flag-gw {
  background-position: -176px -55px;
}
.flag-gy {
  background-position: -192px -55px;
}
.flag-hk {
  background-position: -208px -55px;
}
.flag-hm {
  background-position: -224px -55px;
}
.flag-hn {
  background-position: -240px -55px;
}
.flag-hr {
  background-position: 0 -66px;
}
.flag-ht {
  background-position: -16px -66px;
}
.flag-hu {
  background-position: -32px -66px;
}
.flag-id {
  background-position: -64px -66px;
}
.flag-ie {
  background-position: -80px -66px;
}
.flag-il {
  background-position: -96px -66px;
}
.flag-im {
  background-position: -112px -66px;
}
.flag-in {
  background-position: -128px -66px;
}
.flag-io {
  background-position: -144px -66px;
}
.flag-iq {
  background-position: -160px -66px;
}
.flag-ir {
  background-position: -176px -66px;
}
.flag-is {
  background-position: -192px -66px;
}
.flag-it {
  background-position: -208px -66px;
}
.flag-je {
  background-position: -224px -66px;
}
.flag-jm {
  background-position: -240px -66px;
}
.flag-jo {
  background-position: 0 -77px;
}
.flag-jp {
  background-position: -16px -77px;
}
.flag-ke {
  background-position: -32px -77px;
}
.flag-kg {
  background-position: -48px -77px;
}
.flag-kh {
  background-position: -64px -77px;
}
.flag-ki {
  background-position: -80px -77px;
}
.flag-km {
  background-position: -96px -77px;
}
.flag-kn {
  background-position: -112px -77px;
}
.flag-kp {
  background-position: -128px -77px;
}
.flag-kr {
  background-position: -144px -77px;
}
.flag-kw {
  background-position: -176px -77px;
}
.flag-ky {
  background-position: -192px -77px;
}
.flag-kz {
  background-position: -208px -77px;
}
.flag-la {
  background-position: -224px -77px;
}
.flag-lb {
  background-position: -240px -77px;
}
.flag-lc {
  background-position: 0 -88px;
}
.flag-li {
  background-position: -16px -88px;
}
.flag-lk {
  background-position: -32px -88px;
}
.flag-lr {
  background-position: -48px -88px;
}
.flag-ls {
  background-position: -64px -88px;
}
.flag-lt {
  background-position: -80px -88px;
}
.flag-lu {
  background-position: -96px -88px;
}
.flag-lv {
  background-position: -112px -88px;
}
.flag-ly {
  background-position: -128px -88px;
}
.flag-ma {
  background-position: -144px -88px;
}
.flag-mc {
  background-position: -160px -88px;
}
.flag-md {
  background-position: -176px -88px;
}
.flag-me {
  background-position: -192px -88px;
}
.flag-mg {
  background-position: -208px -88px;
}
.flag-mh {
  background-position: -224px -88px;
}
.flag-mk {
  background-position: -240px -88px;
}
.flag-ml {
  background-position: 0 -99px;
}
.flag-mm {
  background-position: -16px -99px;
}
.flag-mn {
  background-position: -32px -99px;
}
.flag-mo {
  background-position: -48px -99px;
}
.flag-mp {
  background-position: -64px -99px;
}
.flag-mq {
  background-position: -80px -99px;
}
.flag-mr {
  background-position: -96px -99px;
}
.flag-ms {
  background-position: -112px -99px;
}
.flag-mt {
  background-position: -128px -99px;
}
.flag-mu {
  background-position: -144px -99px;
}
.flag-mv {
  background-position: -160px -99px;
}
.flag-mw {
  background-position: -176px -99px;
}
.flag-mx {
  background-position: -192px -99px;
}
.flag-my {
  background-position: -208px -99px;
}
.flag-mz {
  background-position: -224px -99px;
}
.flag-na {
  background-position: -240px -99px;
}
.flag-nc {
  background-position: 0 -110px;
}
.flag-ne {
  background-position: -16px -110px;
}
.flag-nf {
  background-position: -32px -110px;
}
.flag-ng {
  background-position: -48px -110px;
}
.flag-ni {
  background-position: -64px -110px;
}
.flag-nl {
  background-position: -80px -110px;
}
.flag-no {
  background-position: -96px -110px;
}
.flag-np {
  background-position: -112px -110px;
}
.flag-nr {
  background-position: -128px -110px;
}
.flag-nu {
  background-position: -144px -110px;
}
.flag-nz {
  background-position: -160px -110px;
}
.flag-om {
  background-position: -176px -110px;
}
.flag-pa {
  background-position: -192px -110px;
}
.flag-pe {
  background-position: -208px -110px;
}
.flag-pf {
  background-position: -224px -110px;
}
.flag-pg {
  background-position: -240px -110px;
}
.flag-ph {
  background-position: 0 -121px;
}
.flag-pk {
  background-position: -16px -121px;
}
.flag-pl {
  background-position: -32px -121px;
}
.flag-pm {
  background-position: -48px -121px;
}
.flag-pn {
  background-position: -64px -121px;
}
.flag-pr {
  background-position: -80px -121px;
}
.flag-ps {
  background-position: -96px -121px;
}
.flag-pt {
  background-position: -112px -121px;
}
.flag-pw {
  background-position: -128px -121px;
}
.flag-py {
  background-position: -144px -121px;
}
.flag-qa {
  background-position: -160px -121px;
}
.flag-re {
  background-position: -176px -121px;
}
.flag-ro {
  background-position: -192px -121px;
}
.flag-rs {
  background-position: -208px -121px;
}
.flag-ru {
  background-position: -224px -121px;
}
.flag-rw {
  background-position: -240px -121px;
}
.flag-sa {
  background-position: 0 -132px;
}
.flag-sb {
  background-position: -16px -132px;
}
.flag-sc {
  background-position: -32px -132px;
}
.flag-sd {
  background-position: -64px -132px;
}
.flag-se {
  background-position: -80px -132px;
}
.flag-sg {
  background-position: -96px -132px;
}
.flag-sh {
  background-position: -112px -132px;
}
.flag-si {
  background-position: -128px -132px;
}
.flag-sk {
  background-position: -144px -132px;
}
.flag-sl {
  background-position: -160px -132px;
}
.flag-sm {
  background-position: -176px -132px;
}
.flag-sn {
  background-position: -192px -132px;
}
.flag-so {
  background-position: -208px -132px;
}
.flag-sr {
  background-position: -240px -132px;
}
.flag-ss {
  background-position: 0 -143px;
}
.flag-st {
  background-position: -16px -143px;
}
.flag-sv {
  background-position: -32px -143px;
}
.flag-sx {
  background-position: -48px -143px;
}
.flag-sy {
  background-position: -64px -143px;
}
.flag-sz {
  background-position: -80px -143px;
}
.flag-tc {
  background-position: -96px -143px;
}
.flag-td {
  background-position: -112px -143px;
}
.flag-tf {
  background-position: -128px -143px;
}
.flag-tg {
  background-position: -144px -143px;
}
.flag-th {
  background-position: -160px -143px;
}
.flag-tj {
  background-position: -176px -143px;
}
.flag-tk {
  background-position: -192px -143px;
}
.flag-tl {
  background-position: -208px -143px;
}
.flag-tm {
  background-position: -224px -143px;
}
.flag-tn {
  background-position: -240px -143px;
}
.flag-to {
  background-position: 0 -154px;
}
.flag-tr {
  background-position: -16px -154px;
}
.flag-tt {
  background-position: -32px -154px;
}
.flag-tv {
  background-position: -48px -154px;
}
.flag-tw {
  background-position: -64px -154px;
}
.flag-tz {
  background-position: -80px -154px;
}
.flag-ua {
  background-position: -96px -154px;
}
.flag-ug {
  background-position: -112px -154px;
}
.flag-um {
  background-position: -128px -154px;
}
.flag-us {
  background-position: -144px -154px;
}
.flag-uy {
  background-position: -160px -154px;
}
.flag-uz {
  background-position: -176px -154px;
}
.flag-va {
  background-position: -192px -154px;
}
.flag-vc {
  background-position: -208px -154px;
}
.flag-ve {
  background-position: -224px -154px;
}
.flag-vg {
  background-position: -240px -154px;
}
.flag-vi {
  background-position: 0 -165px;
}
.flag-vn {
  background-position: -16px -165px;
}
.flag-vu {
  background-position: -32px -165px;
}
.flag-wf {
  background-position: -64px -165px;
}
.flag-ws {
  background-position: -80px -165px;
}
.flag-ye {
  background-position: -96px -165px;
}
.flag-yt {
  background-position: -112px -165px;
}
.flag-za {
  background-position: -128px -165px;
}
.flag-zm {
  background-position: -160px -165px;
}
.flag-zw {
  background-position: -176px -165px;
}
.flag-an {
  background-position: -128px 0;
}
.flag-ic {
  background-position: -48px -66px;
}
ng-select.ng-select .ng-select-container, .field input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e5e9ed;
  color: #000;
  height: 40px;
  line-height: 20px;
  box-shadow: none;
  padding: 0 10px;
  font-size: 14px;
  transition: border-color 150ms ease;
  font-family: "Soleil", sans-serif;
}
ng-select.ng-select .ng-select-container::-webkit-input-placeholder, .field input::-webkit-input-placeholder {
  color: #4c6c83;
}
ng-select.ng-select .ng-select-container::-moz-placeholder, .field input::-moz-placeholder {
  color: #4c6c83;
}
ng-select.ng-select .ng-select-container:-ms-input-placeholder, .field input:-ms-input-placeholder {
  color: #4c6c83;
}
ng-select.ng-select .ng-select-container:-moz-placeholder, .field input:-moz-placeholder {
  color: #4c6c83;
}
ng-select.ng-select .ng-select-container:focus, .field input:focus {
  outline: none;
  box-shadow: none;
  border-color: #b8c3ce;
}
ng-select.ng-select [readonly].ng-select-container, .field input[readonly], ng-select.ng-select .ng-select-container:disabled, .field input:disabled {
  opacity: 0.5;
}
ng-select.ng-select .icon.ng-select-container, .field input.icon {
  padding-right: 40px;
}
.field .input-wrap[phone], .field [phone].input-wrap--overflow-link {
  display: flex;
  border: 1px solid #e5e9ed;
  border-radius: 5px;
  transition: border-color 150ms ease;
}
.field .input-wrap[phone] input, .field [phone].input-wrap--overflow-link input {
  border: 0;
}
.field .input-wrap[phone] cbr-phone-select, .field [phone].input-wrap--overflow-link cbr-phone-select {
  border-right: 1px solid #e5e9ed;
  min-width: 55px;
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-select-container, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-select-container {
  border: 0;
  background: transparent;
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-dropdown-panel, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-dropdown-panel {
  width: 350px;
  background: #fff;
  border: 1px solid #e5e9ed;
  border-radius: 5px;
  overflow: hidden;
  top: calc(100% + 8px);
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-value, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-value {
  display: flex;
  align-items: center;
  justify-content: center;
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-value cbr-svg, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-value cbr-svg {
  display: block;
  position: static;
  height: 10px !important;
  margin-left: 5px;
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-dropdown-panel-items, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-dropdown-panel-items {
  border-width: 1px 0 0 0;
  border-radius: 0;
}
.field .input-wrap[phone] cbr-phone-select ng-select.ng-select .ng-clear-wrapper, .field [phone].input-wrap--overflow-link cbr-phone-select ng-select.ng-select .ng-clear-wrapper {
  text-align: center;
  display: inline-block;
  margin-left: 5px;
}
.field .input-wrap[label], .field [label].input-wrap--overflow-link {
  display: flex;
  border: 1px solid #e5e9ed;
  border-radius: 5px;
  transition: border-color 150ms ease;
}
.field .input-wrap[label]:focus-within, .field [label].input-wrap--overflow-link:focus-within {
  border-color: #b8c3ce;
}
.field .input-wrap[label] input, .field [label].input-wrap--overflow-link input {
  border: 0;
}
.field .input-wrap[label]:before, .field [label].input-wrap--overflow-link:before {
  content: attr(label);
  display: block;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  background: #e7eaed;
  border-right: 1px solid #e5e9ed;
  padding: 0 20px;
  color: #92a0af;
  line-height: 38px;
  white-space: nowrap;
}
.field .input-wrap[label-right], .field [label-right].input-wrap--overflow-link {
  display: flex;
  border: 1px solid #e5e9ed;
  border-radius: 5px;
  transition: border-color 150ms ease;
}
.field .input-wrap[label-right]:focus-within, .field [label-right].input-wrap--overflow-link:focus-within {
  border-color: #b8c3ce;
}
.field .input-wrap[label-right] input, .field [label-right].input-wrap--overflow-link input {
  border: 0;
}
.field .input-wrap[label-right]:after, .field [label-right].input-wrap--overflow-link:after {
  content: attr(label);
  display: block;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  background: #e7eaed;
  border-right: 1px solid #e5e9ed;
  padding: 0 20px;
  color: #92a0af;
  line-height: 38px;
  white-space: nowrap;
}
.field .input-wrap.custom-label, .field .custom-label.input-wrap--overflow-link {
  display: flex;
  border: 1px solid #e5e9ed;
  border-radius: 5px;
  transition: border-color 150ms ease;
}
.field .input-wrap.custom-label:focus-within, .field .custom-label.input-wrap--overflow-link:focus-within {
  border-color: #b8c3ce;
}
.field .input-wrap.custom-label input, .field .custom-label.input-wrap--overflow-link input {
  border: 0;
}
.field .input-wrap.custom-label .label, .field .custom-label.input-wrap--overflow-link .label {
  display: block;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  background: #e7eaed;
  border-right: 1px solid #e5e9ed;
  padding: 0 20px;
  color: #92a0af;
  line-height: 38px;
  white-space: nowrap;
}
.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: #002d4f;
}
.field label {
  margin-bottom: 10px;
  font-weight: 700;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  color: #333333;
  position: relative;
  padding-right: 20px;
}
.field label .optional {
  margin-left: 5px;
}
.field .input-counter {
  color: #92a0af;
  position: absolute;
  font-weight: 400;
  right: 0;
  bottom: 0;
  transition: color 200ms ease;
}
.field .input-counter.is-invalid {
  color: #ef0000;
}
.field .input-wrap, .field .input-wrap--overflow-link {
  position: relative;
  flex: 1 0;
  display: flex;
}
.field .input-wrap textarea::-webkit-input-placeholder, .field .input-wrap--overflow-link textarea::-webkit-input-placeholder,
.field .input-wrap input::-webkit-input-placeholder,
.field .input-wrap--overflow-link input::-webkit-input-placeholder {
  color: #4c6c83;
  opacity: 1;
}
.field .input-wrap textarea::-moz-placeholder, .field .input-wrap--overflow-link textarea::-moz-placeholder,
.field .input-wrap input::-moz-placeholder,
.field .input-wrap--overflow-link input::-moz-placeholder {
  color: #4c6c83;
  opacity: 1;
}
.field .input-wrap textarea:-ms-input-placeholder, .field .input-wrap--overflow-link textarea:-ms-input-placeholder,
.field .input-wrap input:-ms-input-placeholder,
.field .input-wrap--overflow-link input:-ms-input-placeholder {
  color: #4c6c83;
  opacity: 1;
}
.field .input-wrap textarea:-moz-placeholder, .field .input-wrap--overflow-link textarea:-moz-placeholder,
.field .input-wrap input:-moz-placeholder,
.field .input-wrap--overflow-link input:-moz-placeholder {
  color: #4c6c83;
  opacity: 1;
}
.field .input-wrap.z-index-10, .field .z-index-10.input-wrap--overflow-link {
  z-index: 10;
}
.field .input-wrap.z-index-20, .field .z-index-20.input-wrap--overflow-link {
  z-index: 20;
}
.field .input-wrap.z-index-30, .field .z-index-30.input-wrap--overflow-link {
  z-index: 30;
}
.field .input-wrap.z-index-40, .field .z-index-40.input-wrap--overflow-link {
  z-index: 40;
}
.field .input-wrap.z-index-50, .field .z-index-50.input-wrap--overflow-link {
  z-index: 50;
}
.field .input-wrap.z-index-60, .field .z-index-60.input-wrap--overflow-link {
  z-index: 60;
}
.field .input-wrap.z-index-70, .field .z-index-70.input-wrap--overflow-link {
  z-index: 70;
}
.field .input-wrap.z-index-80, .field .z-index-80.input-wrap--overflow-link {
  z-index: 80;
}
.field .input-wrap.z-index-90, .field .z-index-90.input-wrap--overflow-link {
  z-index: 90;
}
.field .input-wrap.z-index-100, .field .z-index-100.input-wrap--overflow-link {
  z-index: 100;
}
.field .input-wrap ng-select, .field .input-wrap--overflow-link ng-select {
  width: 100%;
  position: relative;
}
.field .input-wrap cbr-svg, .field .input-wrap--overflow-link cbr-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  z-index: 1;
}
.field .input-wrap cbr-range-slider, .field .input-wrap--overflow-link cbr-range-slider {
  width: 100%;
}
.field .input-wrap cbr-range-slider .range.no-label .range-custom-wrap, .field .input-wrap--overflow-link cbr-range-slider .range.no-label .range-custom-wrap {
  top: 11px;
}
.field .input-wrap cbr-range-slider .range.no-label .range-wrap input, .field .input-wrap--overflow-link cbr-range-slider .range.no-label .range-wrap input {
  height: 20px;
  line-height: 20px;
}
.field .input-wrap .ng-option cbr-svg, .field .input-wrap--overflow-link .ng-option cbr-svg {
  position: static;
  transform: translate(0, 0);
}
.field .input-wrap[label-right]:after, .field [label-right].input-wrap--overflow-link:after {
  content: attr(label-right);
  border-radius: 0 5px 5px 0;
  border: 0;
  border-left: 1px solid #e5e9ed;
}
.field .input-wrap.is-loading > cbr-svg, .field .is-loading.input-wrap--overflow-link > cbr-svg {
  display: none;
}
.field .input-wrap.is-loading::after, .field .is-loading.input-wrap--overflow-link::after {
  content: "";
  animation: spinAround 0.5s infinite linear;
  border: 2px solid #dbdbdb;
  border-color: transparent transparent #0087ef #0087ef !important;
  border-radius: 290486px;
  display: block;
  height: 1em;
  width: 1em;
  position: absolute;
  right: 15px;
  top: calc(50% - (1em / 1.6));
}
.field .input-wrap--overflow-link input {
  padding-right: 60px;
}
.field .input-wrap--overflow-link a {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.field textarea {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e5e9ed;
  color: #000;
  box-shadow: none;
  padding: 10px;
  font-size: 14px;
  transition: border-color 150ms ease;
  font-family: "Soleil", sans-serif;
  line-height: 24px;
  resize: none;
}
.field textarea:focus {
  outline: none;
  box-shadow: none;
  border-color: #b8c3ce;
}
.field textarea:disabled {
  opacity: 0.5;
}
.field input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  position: fixed;
  top: -5000px;
}
.field .input-wrap--submit {
  display: flex;
  align-items: center;
}
.field .input-wrap--submit cbr-button {
  margin-left: 20px;
}
.input-checkbox {
  display: flex;
  align-items: center;
}
.input-checkbox input {
  display: none;
}
.input-checkbox .label-checkbox {
  font-weight: 400;
  flex: 1 0;
}
@media only screen and (max-width: 768px) {
  .input-checkbox .label-checkbox {
    margin-right: 20px;
  }
}
.input-checkbox .label-checkbox .header {
  font-weight: 700;
}
.input-checkbox .label-checkbox .description {
  color: #92a0af;
}
@media only screen and (max-width: 768px) {
  .input-checkbox .label-checkbox .description {
    margin-top: 10px;
  }
}
.input-checkbox .checkbox-toggle {
  border-radius: 5px;
  position: relative;
  background: #e7eaed;
  width: 42px;
  height: 24px;
  transition: background 200ms ease;
  cursor: pointer;
}
.input-checkbox .checkbox-toggle:after {
  content: "";
  position: absolute;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-90%);
  top: 2px;
  height: calc(100% - 4px);
  width: 50%;
  background: #fff;
  display: block;
  transition: transform 200ms ease;
}
.input-checkbox .checkbox-toggle.active {
  background: #27d07b;
}
.input-checkbox .checkbox-toggle.active:after {
  transform: translateX(-10%);
}
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}
.form-wrap {
  max-width: 600px;
}
.form-wrap fieldset {
  margin-bottom: 40px;
}
.cbr-input-errors,
cbr-input-errors {
  color: #721c24;
  font-size: 12px;
  margin: 5px 0;
}
.cbr-input-errors p,
cbr-input-errors p {
  margin: 0;
}
cbr-store-details ng-select.ng-select input {
  width: 100%;
  line-height: 24px;
  outline: none;
  padding: 5px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #e5e9ed;
}
cbr-store-details ng-select.ng-select .ng-dropdown-panel {
  border: 1px solid #e5e9ed;
  border-radius: 5px;
}
cbr-store-details ng-select.ng-select .ng-dropdown-panel-items {
  border: none;
}
.line-break {
  white-space: pre-line;
}
.progress-completed {
  background: #0087ef;
}
.preboarding-progress {
  position: relative;
  padding-bottom: 7px;
  display: flex;
  flex-direction: column;
}
.preboarding-progress cbr-progress-bar {
  bottom: 0;
  height: 5px;
}
.paper, .stat-item, .stat-item--content-left, cbr-datatable .fluid-table-content-wrap {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 3px 20px rgba(208, 230, 244, 0.5);
  border-radius: 5px;
}
.custom-range-datepicker .quick-select-sec {
  display: none;
}
.custom-range-datepicker .calendar-button-sec {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}
.custom-range-datepicker .mat-calendar-previous-button,
.custom-range-datepicker .mat-calendar-next-button {
  color: #0087ef;
}
.custom-range-datepicker .mat-calendar-body-cell-content {
  border-radius: 10px;
}
.custom-range-datepicker .mat-calendar-cell-semi-selected,
.custom-range-datepicker .mat-calendar-body-begin-range:not(.mat-calendar-body-end-range),
.custom-range-datepicker .mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
  background-color: #e5f3fe;
}
.custom-range-datepicker .mat-raised-button {
  padding: 0 25px;
  background: #0087ef;
  color: #fff;
  border: 1px solid #0087ef;
  border-radius: 5px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  transition: background 200ms ease, border-color 200ms ease !important;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  position: relative;
  box-shadow: none !important;
}
.custom-range-datepicker .mat-ripple {
  transform: none !important;
}
.custom-range-datepicker .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: #0087ef;
}
.mat-datepicker-content {
  height: auto !important;
  box-shadow: 0 3px 20px rgba(208, 230, 244, 0.5) !important;
}
.ribbon, cbr-button .button-label {
  background: #0087ef;
  position: absolute;
  top: 5px;
  color: #fff;
  right: -4px;
  padding: 5px;
  line-height: 10px;
  font-size: 11px;
  border-radius: 2.5px 2.5px 0 2.5px;
}
.ribbon:after, cbr-button .button-label:after {
  content: "";
  border-left: 4px solid #0d47a1;
  border-bottom: 4px solid transparent;
  position: absolute;
  top: 20px;
  right: 0;
}
.snackbar--item {
  border: 1px solid #b8daff;
  background: #cce5ff;
  color: #004085;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px 40px 10px 10px;
  position: relative;
}
.snackbar--item .cbr-close {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  padding: 5px;
  background: none;
  border: 0;
  background: rgba(0, 0, 0, 0);
  transition: background 125ms ease;
  cursor: pointer;
}
.snackbar--item .cbr-close:hover {
  background: rgba(0, 0, 0, 0.15);
}
.snackbar--item .cbr-close:before, .snackbar--item .cbr-close:after {
  background: #004085;
}
.snackbar--item.is-error {
  border: 1px solid #f5c6cb;
  background: #f8d7da;
  color: #721c24;
}
.snackbar--item.is-error .cbr-close:before, .snackbar--item.is-error .cbr-close:after {
  background: #721c24;
}
.snackbar--item.is-warning {
  border: 1px solid #ffe0b2;
  background: #ffe0b2;
  color: #e6511c;
}
.snackbar--item.is-warning .cbr-close:before, .snackbar--item.is-warning .cbr-close:after {
  background: #e6511c;
}
.snackbar--item.is-success {
  border: 1px solid #c3e6cb;
  background: #d4edda;
  color: #155724;
}
.snackbar--item.is-success .cbr-close:before, .snackbar--item.is-success .cbr-close:after {
  background: #155724;
}
@media only screen and (max-width: 768px) {
  .stat-grid {
    width: 100%;
    margin-left: 0;
  }
}
.stat-item, .stat-item--content-left {
  width: calc(((100% / 3) * 1) - 20px);
  margin-left: 20px;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
@media only screen and (max-width: 768px) {
  .stat-item, .stat-item--content-left {
    width: 100%;
    margin-left: 0;
  }
}
.stat-item.cols-4, .cols-4.stat-item--content-left {
  width: calc(((100% / 4) * 1) - 20px);
}
@media only screen and (max-width: 768px) {
  .stat-item.cols-4, .cols-4.stat-item--content-left {
    width: 100%;
    margin-left: 0;
  }
}
.stat-item.span-all, .span-all.stat-item--content-left {
  width: 100%;
}
.stat-item.no-pad, .no-pad.stat-item--content-left {
  padding: 0;
}
.stat-item h1, .stat-item--content-left h1 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 800;
  text-align: center;
  line-height: 32px;
}
.stat-item p, .stat-item--content-left p {
  margin-bottom: 0;
}
.stat-item--content-left {
  flex-direction: column;
  align-items: flex-start;
}
.tabs {
  border-bottom: 1px solid #cfd5db;
  display: flex;
}
.tabs a {
  display: block;
  position: relative;
  text-decoration: none;
  font-size: 18px;
  padding: 5px 20px;
  color: #92a0af;
  cursor: pointer;
  transition: color 225ms ease;
}
.tabs a:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  bottom: -1px;
  left: 50%;
  background: #0087ef;
  transform: translateX(-50%);
  transition: width 75ms linear;
}
.tabs a:hover {
  color: #0087ef;
}
.tabs a.active:after {
  width: 100%;
}
.target-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.target-list > div:first-child {
  margin-top: 0;
}
.target-list-item {
  width: 100%;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #e7eaed;
  display: flex;
  overflow: hidden;
  transition: border-color 200ms linear;
}
.target-list-item.editing {
  border-color: #239fff;
}
@media only screen and (max-width: 768px) {
  .target-list-item {
    margin-top: 10px;
    flex-direction: column;
  }
}
.target-list-item-content {
  flex: 7 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.target-list-item-content h4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 20px;
}
.target-list-item-content h4 > span {
  max-width: 60%;
}
.target-list-item-info {
  display: flex;
  align-items: center;
  margin: 0;
}
.target-list-item-info .text {
  flex: 1 0;
  padding: 0 10px 0 0;
}
.target-list-item-range {
  display: flex;
  flex-direction: column;
}
.target-list-item-range .label {
  display: flex;
  justify-content: space-between;
}
.target-list-item-map {
  flex: 3 0;
}
@media only screen and (max-width: 768px) {
  .target-list-item-map {
    height: 100px;
  }
}
[tooltip], [tooltip-bottom-right], [tooltip-bottom-left], [tooltip-right], [tooltip-left], [tooltip-bottom] {
  position: relative;
  overflow: visible;
  line-height: normal;
}
[tooltip].single-line:before, .single-line[tooltip-bottom-right]:before, .single-line[tooltip-bottom-left]:before, .single-line[tooltip-right]:before, .single-line[tooltip-left]:before, .single-line[tooltip-bottom]:before {
  white-space: nowrap;
}
[tooltip].center:before, .center[tooltip-bottom-right]:before, .center[tooltip-bottom-left]:before, .center[tooltip-right]:before, .center[tooltip-left]:before, .center[tooltip-bottom]:before {
  text-align: center;
}
[tooltip]:hover, [tooltip-bottom-right]:hover, [tooltip-bottom-left]:hover, [tooltip-right]:hover, [tooltip-left]:hover, [tooltip-bottom]:hover {
  z-index: 1000;
}
[tooltip]:hover:before, [tooltip-bottom-right]:hover:before, [tooltip-bottom-left]:hover:before, [tooltip-right]:hover:before, [tooltip-left]:hover:before, [tooltip-bottom]:hover:before {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
  visibility: visible;
}
[tooltip].nowrap:before, .nowrap[tooltip-bottom-right]:before, .nowrap[tooltip-bottom-left]:before, .nowrap[tooltip-right]:before, .nowrap[tooltip-left]:before, .nowrap[tooltip-bottom]:before {
  white-space: nowrap;
}
[tooltip]:before, [tooltip-bottom-right]:before, [tooltip-bottom-left]:before, [tooltip-right]:before, [tooltip-left]:before, [tooltip-bottom]:before {
  box-sizing: border-box;
  visibility: hidden;
  transition: opacity 125ms cubic-bezier(0.64, 0.09, 0.08, 1) 50ms, transform 125ms cubic-bezier(0.64, 0.09, 0.08, 1) 50ms, visibility 125ms cubic-bezier(0.64, 0.09, 0.08, 1);
  color: #fff;
  border-radius: 3px;
  opacity: 0;
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  bottom: calc(100% + 5px + 8px);
  background: #464646;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid #464646;
  display: inline-block;
  font-size: 12px;
  line-height: 1.5;
  transform: translate(-50%, 20px) scale(0);
  padding: 20px;
  max-width: 220px;
  z-index: 1000;
  word-wrap: break-word;
  zoom: 1;
}
[tooltip-bottom]:before, [tooltip-bottom-right]:before, [tooltip-bottom-left]:before {
  bottom: auto;
  content: attr(tooltip-bottom);
  top: calc(100% + 5px + 8px);
}
[tooltip-left]:before, [tooltip-bottom-left]:before {
  content: attr(tooltip-left);
  transform: translate(0, 0) scale(0);
  right: calc(100% + 20px);
  top: calc(50% - (8px * 2));
  bottom: auto;
  left: auto;
}
[tooltip-left]:hover:before, [tooltip-bottom-left]:hover:before {
  opacity: 1;
  transform: translate(0, 0) scale(1);
  visibility: visible;
}
[tooltip-right]:before, [tooltip-bottom-right]:before {
  content: attr(tooltip-right);
  transform: translate(20%, 100%) scale(0);
}
[tooltip-right]:hover:before, [tooltip-bottom-right]:hover:before {
  opacity: 1;
  transform: translate(20%, 100%) scale(1);
  visibility: visible;
}
[tooltip-bottom-left]:before {
  content: attr(tooltip-bottom-left);
}
[tooltip-bottom-right]:before {
  content: attr(tooltip-bottom-right);
}
.tooltip-wrap {
  display: inline-block;
  position: relative;
}
/**
 * Positioning
 */
p-table p-paginator {
  display: none;
}
p-table .ui-table-caption {
  padding: 0 !important;
}
.triangle {
  width: 0;
  height: 0;
  display: inline-block;
}
.triangle.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #0087ef;
}
.triangle.desc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #0087ef;
}
.triangle.both {
  position: relative;
  height: 10px;
  width: 10%;
}
.triangle.both:before, .triangle.both:after {
  content: "";
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #0087ef;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(0);
}
.triangle.both:after {
  border-top: 0;
  border-bottom: 4px solid #0087ef;
  transform: translateY(-6px);
}
.goal-content .goal-selection button.type-content {
  padding: 40px 60px;
}
@media only screen and (min-width: 0px) and (max-width: 900px) {
  .goal-content .goal-selection button.type-content {
    padding: 40px;
  }
}
ng-select.ng-select.has-arrow .ng-arrow-wrapper .ng-arrow {
  height: 38px;
  width: 38px;
  background-image: url("/assets/primitives/icons/svg/sprite.symbol.svg#icon-arrow");
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: 50% 50%;
}
ng-select.ng-select .ng-has-value .ng-placeholder {
  display: none;
}
ng-select.ng-select .ng-placeholder {
  color: #92a0af;
}
ng-select.ng-select .ng-clear-wrapper .ng-clear {
  display: none;
}
ng-select.ng-select .ng-spinner-loader {
  margin-top: auto;
  margin-bottom: auto;
  border: 2px solid #0087ef;
  border-color: transparent transparent #0087ef #0087ef !important;
}
ng-select.ng-select.ng-select-focused .ng-select-container {
  box-shadow: none;
  border-color: #b8c3ce;
}
ng-select.ng-select.ng-select-opened .ng-select-container {
  border-radius: 5px;
  border-color: #b8c3ce;
}
ng-select.ng-select .ng-select-container {
  height: 40px;
  line-height: 40px;
}
ng-select.ng-select .ng-dropdown-panel {
  margin-top: 10px;
}
ng-select.ng-select .ng-dropdown-panel-items {
  padding: 0 10px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #e5e9ed;
}
ng-select.ng-select .ng-dropdown-panel-items div div:first-child {
  border-top: 0;
}
ng-select.ng-select .ng-dropdown-panel-items .ng-option {
  height: 40px;
  padding: 8px 0;
  border-top: 1px solid #e5e9ed;
}
ng-select.ng-select .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #92a0af;
}
ng-select.ng-select .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: none;
  color: #0087ef;
}
ng-select.ng-select .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: none;
}
.location-row {
  display: flex;
  padding: 0 10px;
}
.location-row .location-name {
  flex: 1 0;
}
.location-row .location-reach {
  color: #92a0af;
}
/* The container */
.container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  width: 12px;
  height: 12px;
}
/* Hide the browser's default checkbox */
.container input,
.container .no-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  border: 1px solid #e5e9ed;
  border-radius: 2px;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark,
.container .checked ~ .checkmark {
  background-color: #0087ef;
  border: 1px solid #0087ef;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after,
.container .checked ~ .checkmark:after {
  display: block;
  background-color: #fff;
  -webkit-mask: url("/assets/svg-icons/icon-checkbox-checkmark.svg") no-repeat 50% 50%;
          mask: url("/assets/svg-icons/icon-checkbox-checkmark.svg") no-repeat 50% 50%;
  -webkit-mask-size: cover;
          mask-size: cover;
}
/* Style the checkmark/indicator */
.container .checkmark:after {
  height: 7px;
  width: 8px;
  top: 2px;
  left: 1px;
}
.example-block cbr-doc-tab .code > div {
  flex: 1 0;
  width: 100%;
}
