.triangle {
  width: 0;
  height: 0;
  display: inline-block;

  &.asc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid $c-primary;
  }

  &.desc {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid $c-primary;
  }

  &.both {
    position: relative;
    height: 10px;
    width: 10%;

    &:before,
    &:after {
      content: '';

      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid $c-primary;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(0);
    }

    &:after {
      border-top: 0;
      border-bottom: 4px solid $c-primary;
      transform: translateY(-6px);
    }
  }
}
