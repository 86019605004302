.custom-range-datepicker {
  .quick-select-sec {
    display: none;
  }
  .calendar-button-sec {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  .mat-calendar-previous-button,
  .mat-calendar-next-button {
    color: $c-primary;
  }
  .mat-calendar-body-cell-content {
    border-radius: $radius2;
  }
  .mat-calendar-cell-semi-selected,
  .mat-calendar-body-begin-range:not(.mat-calendar-body-end-range),
  .mat-calendar-body-end-range:not(.mat-calendar-body-begin-range) {
    background-color: #e5f3fe;
  }
  .mat-raised-button {
    padding: 0 25px;
    background: $c-primary;
    color: #fff;
    border: 1px solid $c-primary;
    border-radius: $radius;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    font-size: $f-size-p;
    transition: background 200ms ease, border-color 200ms ease !important;
    cursor: pointer;
    user-select: none;
    position: relative;
    box-shadow: none !important;
  }
  .mat-ripple {
    transform: none !important;
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: $c-primary;
  }
}
.mat-datepicker-content {
  height: auto !important;
  box-shadow: 0 3px 20px rgba(208, 230, 244, 0.5) !important;
}
