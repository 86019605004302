.ribbon {
  background: $c-primary;
  position: absolute;
  top: 5px;
  color: #fff;
  right: -4px;
  padding: 5px;
  line-height: 10px;
  font-size: 11px;
  border-radius: $radius/2 $radius/2 0 $radius/2;

  &:after {
    content: '';
    border-left: 4px solid $c-primary-dark;
    border-bottom: 4px solid transparent;
    position: absolute;
    top: 20px;
    right: 0;
  }
}
