@keyframes infiniteLoader {
  from {
    left: 0;
    width: 0;
  }
  50% {
    right: 0;
    width: 100%;
  }
  to {
    right: 0;
    width: 0;
  }
}
