h1 {
  font-size: $f-size-1;

  @include baseline($f-size-1);
}

h2 {
  font-size: $f-size-2;

  @include baseline($f-size-2);
}

h3 {
  font-size: $f-size-3;

  @include baseline($f-size-3);
}

h4 {
  font-size: $f-size-5;

  @include baseline($f-size-5);
}

h1,
h2,
h3,
h4 {
  color: $c-title;
  font-weight: $f-weight-subtitle;
  line-height: normal;
  margin-top: 0;
}

.link,
a {
  color: $c-primary;
  transition: color 125ms ease;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $c-primary-hover;
  }

  &:focus {
    outline: none;
    color: $c-primary-hover;
  }

  &.button {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

p {
  color: $c-text-paragraph;
  margin-top: 0;
}

.img-responsive {
  display: block;
  width: auto;
  max-width: 100%;
}

.item-is-loading {
  width: 40px;
  height: 40px;
  position: relative;
  color: transparent !important;
  pointer-events: none;

  > * {
    display: none;
  }

  &::after {
    $thickness: 2px;

    content: '';
    animation: spinAround 0.5s infinite linear;
    border: $thickness solid;
    border-color: transparent transparent $c-grey-dark $c-grey-dark;
    border-radius: 290486px;
    display: block;
    height: calc(100% - #{$thickness * 2});
    width: calc(100% - #{$thickness * 2});

    position: absolute;
    left: 0;
    top: 0;
  }
}
