.target-list {
  display: flex;
  flex-direction: column;
  width: 100%;

  > div:first-child {
    margin-top: 0;
  }
}

.target-list-item {
  width: 100%;
  margin-top: $gt;
  border-radius: $radius;
  border: 1px solid $c-grey-light;
  display: flex;
  overflow: hidden;
  transition: border-color 200ms linear;

  &.editing {
    border-color: $c-primary-hover;
  }

  @include breakpoint(-milli) {
    margin-top: $gt-h;
    flex-direction: column;
  }
}

.target-list-item-content {
  flex: 7 0;
  display: flex;
  flex-direction: column;
  padding: $gt;

  h4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    margin-bottom: $gt;

    > span {
      max-width: 60%;
    }
  }
}

.target-list-item-info {
  display: flex;
  align-items: center;
  margin: 0;

  .text {
    flex: 1 0;
    padding: 0 $gt-h 0 0;
  }
}

.target-list-item-range {
  display: flex;
  flex-direction: column;

  .label {
    display: flex;
    justify-content: space-between;
  }
}

.target-list-item-map {
  flex: 3 0;

  @include breakpoint(-milli) {
    height: 100px;
  }
}
