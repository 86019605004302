.tabs {
  border-bottom: 1px solid $c-grey;
  display: flex;

  a {
    display: block;
    position: relative;
    text-decoration: none;
    font-size: $f-size-3;
    padding: $gt-q $gt;
    color: $c-text;
    cursor: pointer;
    transition: color 225ms ease;

    &:after {
      content: '';
      position: absolute;
      height: 3px;
      width: 0;
      bottom: -1px;
      left: 50%;
      background: $c-primary;
      transform: translateX(-50%);
      transition: width 75ms linear;
    }

    &:hover {
      color: $c-primary;
    }

    &.active {
      &:after {
        width: 100%;
      }
    }
  }
}
