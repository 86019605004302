$cubic: cubic-bezier(0.64, 0.09, 0.08, 1);
$width: 200px;
$arrow-size: 8px;
$tooltip-bg: #464646;
$tooltip-color: #fff;

[tooltip] {
  position: relative;
  overflow: visible;
  line-height: normal;

  &.single-line {
    &:before {
      white-space: nowrap;
    }
  }

  &.center {
    &:before {
      text-align: center;
    }
  }

  &:hover {
    z-index: 1000;
    &:before {
      opacity: 1;
      transform: translate(-50%, 0) scale(1);
      visibility: visible;
    }
  }

  &.nowrap:before {
    white-space: nowrap;
  }

  &:before {
    box-sizing: border-box;
    visibility: hidden;
    transition: opacity 125ms $cubic 50ms, transform 125ms $cubic 50ms, visibility 125ms $cubic;
    color: $tooltip-color;
    border-radius: 3px;
    opacity: 0;
    content: attr(tooltip);
    position: absolute;
    left: 50%;
    bottom: calc(100% + 5px + #{$arrow-size});
    background: $tooltip-bg;
    box-shadow: 2px 3px 6px rgba(#000, 0.3);
    border: 1px solid $tooltip-bg;
    display: inline-block;
    font-size: $f-size-6;
    line-height: 1.5;
    transform: translate(-50%, 20px) scale(0);
    padding: $gt;
    max-width: 220px;
    z-index: 1000;
    word-wrap: break-word;
    zoom: 1;
  }
}

[tooltip-bottom] {
  @extend [tooltip];

  &:before {
    bottom: auto;
    content: attr(tooltip-bottom);
    top: calc(100% + 5px + #{$arrow-size});
  }
}

[tooltip-left] {
  @extend [tooltip];

  &:before {
    content: attr(tooltip-left);
    transform: translate(0, 0) scale(0);
    right: calc(100% + #{$gt});
    top: calc(50% - (#{$arrow-size} * 2));
    bottom: auto;
    left: auto;
  }

  &:hover {
    &:before {
      opacity: 1;
      transform: translate(0, 0) scale(1);
      visibility: visible;
    }
  }
}

[tooltip-right] {
  @extend [tooltip];

  &:before {
    content: attr(tooltip-right);
    transform: translate(20%, 100%) scale(0);
  }

  &:hover {
    &:before {
      opacity: 1;
      transform: translate(20%, 100%) scale(1);
      visibility: visible;
    }
  }
}

[tooltip-bottom-left] {
  @extend [tooltip];
  @extend [tooltip-bottom];
  @extend [tooltip-left];

  &:before {
    content: attr(tooltip-bottom-left);
  }
}

[tooltip-bottom-right] {
  @extend [tooltip];
  @extend [tooltip-bottom];
  @extend [tooltip-right];

  &:before {
    content: attr(tooltip-bottom-right);
  }
}

.tooltip-wrap {
  display: inline-block;
  position: relative;
}

/**
 * Positioning
 */
// [tooltip-left] {
//   @extend [tooltip];

//   &:hover {
//     &:before {
//       transform: translateY(-100%) translateY(-10px) translateX(-50%);
//     }
//   }

//   &:after {
//     left: 60px;
//   }

//   &:before {
//     content: attr(tooltip-left);
//     transform: translateY(-100%) translateY(0) translateX(-50%);
//   }
// }

// [tooltip-right] {
//   @extend [tooltip];

//   &:hover {
//     &:before {
//       transform: translateY(-100%) translateY(-10px) translateX(50%);
//     }
//   }

//   &:after {
//     left: 120px;
//   }

//   &:before {
//     content: attr(tooltip-right);
//     transform: translateY(-100%) translateY(0) translateX(50%);
//   }
// }
