.snackbar--item {
  border: 1px solid $c-info-border;
  background: $c-info-bg;
  color: $c-info-text;
  margin-bottom: $gt-h;
  border-radius: $radius;
  padding: $gt-h $gt2 $gt-h $gt-h;
  position: relative;

  .cbr-close {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 40px;
    padding: 5px;
    background: none;
    border: 0;
    background: rgba(#000, 0);
    transition: background 125ms ease;
    cursor: pointer;

    &:hover {
      background: rgba(#000, 0.15);
    }

    &:before,
    &:after {
      background: $c-info-text;
    }
  }

  &.is-error {
    border: 1px solid $c-alert-border;
    background: $c-alert-bg;
    color: $c-alert-text;

    .cbr-close {
      &:before,
      &:after {
        background: $c-alert-text;
      }
    }
  }

  &.is-warning {
    border: 1px solid $c-warning-border;
    background: $c-warning-bg;
    color: $c-warning-text;

    .cbr-close {
      &:before,
      &:after {
        background: $c-warning-text;
      }
    }
  }

  &.is-success {
    border: 1px solid $c-success-border;
    background: $c-success-bg;
    color: $c-success-text;

    .cbr-close {
      &:before,
      &:after {
        background: $c-success-text;
      }
    }
  }
}
