ng-select.ng-select {
  &.has-arrow .ng-arrow-wrapper {
    .ng-arrow {
      height: 38px;
      width: 38px;
      background-image: url('/assets/primitives/icons/svg/sprite.symbol.svg#icon-arrow');
      background-repeat: no-repeat;
      background-size: 12px 12px;
      background-position: 50% 50%;
    }
  }

  .ng-select-container {
    @extend %base-input;
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-placeholder {
    color: $c-grey-dark;
  }

  .ng-clear-wrapper {
    .ng-clear {
      display: none;
    }
  }

  .ng-spinner-loader {
    margin-top: auto;
    margin-bottom: auto;
    border: 2px solid $c-primary;
    border-color: transparent transparent $c-primary $c-primary !important;
  }

  &.ng-select-focused {
    .ng-select-container {
      box-shadow: none;
      border-color: $c-input-border-hover;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      border-radius: $radius;
      border-color: $c-input-border-hover;
    }
  }

  .ng-select-container {
    height: 40px;
    line-height: 40px;
  }

  .ng-dropdown-panel {
    margin-top: $gt-h;
  }

  .ng-dropdown-panel-items {
    padding: 0 $gt-h;
    background: #fff;
    border-radius: $radius;
    border: 1px solid $c-input-border;

    div div:first-child {
      border-top: 0;
    }

    .ng-option {
      height: 40px;
      padding: 8px 0;
      border-top: 1px solid $c-input-border;

      &.ng-option-disabled {
        color: $c-grey-dark;
      }

      &.ng-option-marked {
        background: none;
        color: $c-primary;
      }

      &.ng-option-selected {
        background: none;
      }
    }
  }
}

// Custom ng-select template styling

// Location item styles
.location-row {
  display: flex;
  padding: 0 $gt-h;

  .location-name {
    flex: 1 0;
  }

  .location-reach {
    color: $c-grey-dark;
  }
}
